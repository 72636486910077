// Utilities
@import "../../variables";

.equipment-slider {
    width: 100%;
    border-radius: .25rem;
    overflow: hidden;
}

.equipment-cat-box {
    width: 100%;
    padding: 0;
    border: 0;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    .box-title {
        text-align: center;
        margin: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .events-img {
        padding-bottom: 339px;
        img {
            max-width: 105%;
            max-height: 200%;
        }
    }
    .events-sum {
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        top: 100%;
        height: 100%;
        margin: 0;
        padding: 20px;
        background-color: rgba(#f2f2f2, 0.85);
        transition: all .3s ease-in;
        display: none;
    }
    .anchor-style1, .events-date {
        display: none;
    }
    &:hover {
        .events-sum {
            top: 0;
        }
    }
}

.equipment-subcat {
    // padding: 10px 0 0px;
    // margin-bottom: 30px;
    // border-bottom: 1px solid $color1;
    .nav-tabs {
        font-size: 1.2em;
    }
}

.equipment-box {
    border-bottom: 1px solid $color2;
    margin-bottom: 30px;
    .title {
        color: $color1;
        min-height: 2.8em;
    }
    .equipment-image {
        @include img-container(100%, 'cover');
        margin: 10px 0;
        img {
            max-width: 120%;
            max-height: 140%;
        }
    }
}