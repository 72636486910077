// Utilities
@import "../../variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.news-slider{
  .slider {
    height: 100%;
    .slide {
        height: 100%;
        background-size: cover !important;
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background: -webkit-gradient(linear, left top, left bottom, from(rgba(black,0.4)), to(rgba(0, 0, 0, 0.9)));
          background: linear-gradient(rgba(black,0.4), rgba(0, 0, 0, 0.9));
          bottom: 0;
          left: 0;
        }
    }
    .slider-content {
        text-align: center;
        .inner {
            padding: 20px;
            box-sizing: border-box;
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            .btn {
                // -webkit-transition-delay: 0s;
                // transition-delay: 0s;
                &:hover {
                    color: #fff;
                    border-color: $color1;
                    background: $color1;
                }
            }
            .title {
                font-size: 2em;
                font-weight: 600;
                margin: 0 auto;
                max-width: 840px;
                color: #FFFFFF;
            }
            .article-text {
                color: #FFFFFF;
                font-size: 1em;
                line-height: 1.4;
                margin: 30px auto 30px;
                max-width: 640px;
            }
        }
        section {
            position: absolute;
            bottom: 20px;
            left: 20px;
            span {
                color: #FFFFFF;
                color: rgba(255, 255, 255, 0.5);
                font-size: 12px;
                display: inline-block;
                text-align: left;
                line-height: 1.4;
                vertical-align: middle;
                margin-left: 10px;
                strong {
                    color: #FFFFFF;
                    font-size: 14px;
                    display: block;
                }
            }
            img {
                width: 40px;
                height: 40px;
                border: solid 2px rgba(255, 255, 255, 0.5);
                border-radius: 100%;
                vertical-align: middle;
            }
        }
    }
  }
}
 
// Responsiveness
  @media (max-height: 500px) {
    .slider-wrapper, .slide {
      height: calc(100vh - 75px);
    }    
  }
  
  @media (max-height: 600px) {
    .slider-content .inner h1 {
      font-size: 32px;
    }    
  }
  
  @media (max-width: 640px) {
    .slider-wrapper, .slide {
      height: calc(80vh - 75px);
    }
    .slider-content .inner h1 {
      font-size: 32px;
    }
  }