// Utilities
@import "../../variables";

$feature_icon_wrap: 58px;

.research-areas {
    margin: 30px 0 30px;
    .slick-slider {
        .slick-arrow {
            &:before {
                opacity: 1;
            }
        }
        .slick-prev {
            left: 0;
        }
        .slick-next {
            right: 0;
        }
        .slick-slide {
            & > div {
                display: flex;
            }
            .slide-content {
                padding: 0 30px;
                display: flex!important;
                align-items: stretch;
                justify-content: stretch;
                .slide-img {
                    width: 100%;
                    height: 100%;
                    max-width: 350px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;
                    overflow: hidden;
                }
                .slide-text {
                    padding-left: 30px;
                    .title {
                        color: $color1;
                        margin-bottom: 1rem;
                    }
                    .btn.read-more {
                        float: right;
                    }
                }
            }
        }
    }
    
}

.research-features {
    .icon {
        color: $color1;
        font-size: 20px;
        line-height: $feature_icon_wrap;
        display: inline-block;
        height: $feature_icon_wrap;
        width: $feature_icon_wrap;
        margin-bottom: 20px;
        background-color: #fff;
    }
}

.research-topics {
    .topic-box {
        width: 100%;
        padding: 0;
        border: 0;
        position: relative;
        overflow: hidden;
        background-color: #ddd;
        .box-title {
            text-align: center;
            margin: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .events-img {
            padding-bottom: 80%;
            img {
                max-height: 120%;
                min-height: 105%;
            }
        }
        .events-sum {
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            z-index: 1;
            top: 100%;
            height: 100%;
            margin: 0;
            padding: 20px;
            background-color: rgba(#f2f2f2, 0.85);
            transition: all .3s ease-in;
        }
        .anchor-style1, .events-date {
            display: none;
        }
        &:hover {
            .events-sum {
                top: 0;
            }
        }
    }
}