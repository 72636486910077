// Utilities
@import "../../variables";

.publications {
    table {
        h1, h2, h3, h4, h5, h6, p {
            line-height: 1.4;
            margin: 0;
            margin-bottom: 4px;
        }
        h6 {
            font-size: 1.1em;
        }
        th, td {
            padding: .4em .6em;
            vertical-align: middle;
        }
    }
    .tag {
        opacity: 0.7;
    }
    .title {
        color: #000;
        font-weight: 600;
        a, a:hover {
            color: inherit;
            text-shadow: none;
            border-bottom: 0;
        }
        a:hover {
            text-decoration: underline;
        }
    }
    .meta {
        color: $color2;
    }
    .authors {
        color: inherit;
        a {
            color: $color1;

        }
    }
    &.latest-publications {
        table {
            font-size: 80%;
        }
    }
}

.publication-links {
    // display: flex;
    // align-items: flex-end;
    // justify-content: flex-end;
    .pl-wrap {
        float: right;
        a {
            display: block;
            width: 40px;
            height: 40px;
            margin: 10px 0 0 10px;
            border-radius: 50%;
            overflow: hidden;
            opacity: .8;
            transform: scale(.95);
            transition: all .15s ease-in;
            &:hover {
                transform: scale(1);
                opacity: 1;
            }
            img {

            }
        }
    }
}