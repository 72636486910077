// Variables
$color1: #00539f;
$color2: #43464B;
$color3: #43464B;

$max_width_0: 1100px;
$header_logo_height: 74px;
$header_icon_height: 28px;
$header_bg: rgba(white, 0.7);


//Mixins
@mixin img-container($height, $type) {
    width: 100%;
    height: 0;
    padding-bottom: $height;
    position: relative;
    overflow: hidden;
    img {
        position: absolute;
        margin: auto;
        @if $type == 'cover' {
            top: -999px;
            right: -999px;
            bottom: -999px;
            left: -999px;
            min-width: 100%;
            min-height: 100%;
            max-width: 150%;
            max-height: 150%;
        }
        @if $type == 'contain' {
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            min-width: 10%;
            min-height: 10%;
            max-width: 100%;
            max-height: 100%;
        }
    }
}