// Utilities
@import "../../variables";
// @import "~bootstrap";

.profile-img {
    // width: 148px;
    // height: 190px;
    border: 1px solid #fff;
    border-radius: 5px;
    overflow: hidden;
    @include img-container(130%, 'cover');
    img {
        max-width: 140%;
        max-height: 105%;
    }
}

.team-carousel {
    margin: -12px;
    .slick-slider {
        .slick-slide {
            & > div {
                display: flex;
                padding: 12px;
            }
            .team-img-wrap {
                .team-img {
                    @include img-container(130%, 'cover');
                    display: block;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
    }
    
}

.team-group {
    padding: 10px 0 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #888;
    &:last-child {
        border-bottom: 0;
    }
    .team-box {
        width: 100%;
        padding: 0;
        border: 0;
        overflow: hidden;
        background-color: #f2f2f2;
        .events-wrapper {
            flex-direction: row;
            border: 0;
            .events-img {
                flex-basis: 40%;
                padding-bottom: 50%;
                height: 100%;
                margin: 0;
                // margin: 15px;
                // border-radius: 5px;
                // border: 1px solid #fff;
                background-color: #ccc;
                overflow: hidden;
                img {
                    max-width: 140%;
                    max-height: 105%;
                }
            }
            .events-sum {
                // color: #fff;
                line-height: 1.4;
                flex-basis: 60%;
                flex-direction: column;
                padding: 15px;
                margin: 0;
                // margin-left: 20px;
                // background-color: #ccc;
                // border-style: solid;
                border-width: 1px 1px 1px 0;
                border-color: $color1;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                .team-name {
                    font-weight: 600;
                    line-height: 1.2;
                    min-height: 2.4em;
                    padding-bottom: .5rem;
                    border-bottom: 1px solid #fff;
                    border-bottom-color: $color1;
                    a:hover {
                        text-decoration: underline;
                        text-shadow: none;
                        border-color: transparent;
                    }
                }
            }
            .anchor-style1 {
                display: none;
            }
        }
    }
}