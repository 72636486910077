/* Michael Simon [2016.06.06]: Hack damit das Tippspiel in 100% angezeigt wird. */
#kicktippiframe {
	width:	100% !important;
}

* {
	margin: 0;
	padding: 0;
}
body {
	/* Michael Simon [2017.11.17]: Dank IE kann man hier nicht 62.5% nehmen, da der nur 62% versteht und dann mit Rundungsfehlern arbeitet. Darum hier direkt das Ergebnis 62.5% von 16px = 10px */
	font: 10px arial, helvetica, sans-serif;
	color: #333;
	background: #e5e5e5;
}
img {
	image-orientation: none;
}
iframe {
	border: none;
}
.onlyforprinting {
	display: none;
}
#PIT_Labs_SupportChat_Container {
	left: 30px;
	right: auto !important;
	z-index: 999;
}
/* LINKS
############################ */
a[href] {
	color: #00529F;
	text-decoration: none;
}
a[href]:hover,
a[href]:active,
a[href]:focus {
	text-decoration: underline;
}
a.hiddenmark {
	display: block;
	float: left;
	height: 0;
width: 0; /* Michael Simon A.A. [2014.05.23]: Damit die Hiddenmarks auch im IE11 unsichtbar sind. */
}
/* Michael Simon [2012.11.02]: Links fuer die Gebaerdensprache */
/* Michael Simon C.C. [2015.04.29]: Anpassung fuer Haende in Listenteasern */
a.gebaerdensprache + p {
	display: inline;
}
#main .teaser-row .teaser-list.h-color-1 a.gebaerdensprache,
#main .teaser-row .teaser-list.h-color-2 a.gebaerdensprache,
#main .teaser-row .teaser-list.h-color-2 a.gebaerdensprache:hover,
#main .teaser-row .teaser-list.h-color-2 a.gebaerdensprache:active,
#main .teaser-row .teaser-list.h-color-2 a.gebaerdensprache:focus,
#main .teaser-row .teaser-list.h-color-3 a.gebaerdensprache,
a.gebaerdensprache {
	background-image: url(/custom/rwth/img/hand-button.png);
	background-position: 0 0;
	display: inline-block;
	float: none;
	vertical-align: bottom;
	height: 32px;
	margin-right: 0.4em;
	margin-top: 4px;
	text-indent: -9999px;
	width: 32px;
}
#main .teaser-row .teaser-list.h-color-1 a.gebaerdensprache,
#main .teaser-row .teaser-list.h-color-2 a.gebaerdensprache,
#main .teaser-row .teaser-list.h-color-2 a.gebaerdensprache:hover,
#main .teaser-row .teaser-list.h-color-2 a.gebaerdensprache:active,
#main .teaser-row .teaser-list.h-color-2 a.gebaerdensprache:focus,
#main .teaser-row .teaser-list.h-color-3 a.gebaerdensprache {
	display: block;
	float: left;
	vertical-align: inherit;
}
#main .teaser-row .teaser-list.h-color-2 a.gebaerdensprache {
	background-image: url(../img/hand-button-hover.png);
}
#main .teaser-row .teaser-list.h-color-1 a.gebaerdensprache:hover,
#main .teaser-row .teaser-list.h-color-1 a.gebaerdensprache:active,
#main .teaser-row .teaser-list.h-color-1 a.gebaerdensprache:focus,
#main .teaser-row .teaser-list.h-color-3 a.gebaerdensprache:hover,
#main .teaser-row .teaser-list.h-color-3 a.gebaerdensprache:active,
#main .teaser-row .teaser-list.h-color-3 a.gebaerdensprache:focus,
a.gebaerdensprache:hover,
a.gebaerdensprache:active,
a.gebaerdensprache:focus {
	background-image: url(../img/hand-button-hover.png);
}
a[href] {
	color: #00529F;
	text-decoration: none;
}
a[href]:hover,
a[href]:active,
a[href]:focus {
	text-decoration: underline;
}
/* Michael Simon A.A. [2014.09.03]: Abstand etwas verkleinert. */
a.intranet {
	display: inline;
	background: url(../img/icons/icon-sprites.png) 0 -24800px no-repeat;
	padding-left: 17px;
}

a.internal {
	display: inline;
	background: url(../img/icons/icon-sprites.png) 0 0 no-repeat;
	padding-left: 17px;
}
a.external {
	display: inline;
	background:  url(../img/icons/icon-sprites-1.png) 0 -5200px no-repeat;
	padding-left: 17px;
}
a.download {
	display: inline;
	background:  url(../img/icons/icon-sprites.png) 0 -21000px no-repeat;
	padding-left: 17px;
}
a.download em {
	font-style: normal;
	color: #333333;
	background: none;
}
a.email {
	display: inline;
	background: url(../img/icons/icon-sprites.png) 0 -24400px no-repeat;
	padding-left: 17px;
}
a.navigator {
	display: inline;
	background: url(../img/icons/icon-sprites.png) 0 -14200px no-repeat;
	padding-left: 17px;
}
.construction #main .hint.color-4 a.intranet,
#main div.text-image-wrapper.blue > :not(table) a.intranet,
th a.intranet {
	background: url(../img/icons/icon-sprites.png) 0 -24600px no-repeat;
	color: #fff;
}
.construction #main .hint.color-4 a.internal,
#main div.text-image-wrapper.blue > :not(table) a.internal,
th a.internal {
	background: url(../img/icons/icon-sprites.png) 0 -19800px no-repeat;
	color: #fff;
}
.construction #main .hint.color-4 a.external,
#main div.text-image-wrapper.blue > :not(table) a.external,
th a.external {
	background: url(../img/icons/icon-sprites.png) 0 -20000px no-repeat;
	color: #fff;
}
.construction #main .hint.color-4 a.download,
#main div.text-image-wrapper.blue > :not(table) a.download,
th a.download {
	background: url(../img/icons/icon-sprites.png) 0 -20200px no-repeat;
	color: #fff;
}
.construction #main .hint.color-4 a.email,
#main div.text-image-wrapper.blue > :not(table) a.email,
th a.email {
	background: url(../img/icons/icon-sprites.png) 0 -20800px no-repeat;
	color: #fff;
}
.construction #main .hint.color-4 a.navigator,
#main div.text-image-wrapper.blue > :not(table) a.navigator,
th a.navigator {
	background: url(../img/icons/icon-sprites.png) 0 -14400px no-repeat;
	color: #fff;
}
.construction #main .hint.color-4 a,
#main div.text-image-wrapper.yellow > :not(table) a,
#main div.text-image-wrapper.blue > :not(table) a {
	text-decoration: underline;
}
.listing span a.sortable,
.listing div a.sortable {
	color: #fff;
	background-image: url(../img/tablesorter/bg.gif);
	background-repeat: no-repeat;
	background-position: center right;
	padding-right: 20px;
}
.listing span a.sortasc,
.listing div a.sortasc {
	color: #fff;
	background-image: url(../img/tablesorter/asc.gif);
	background-repeat: no-repeat;
	background-position: center right;
	padding-right: 20px;
}
.listing span a.sortdesc,
.listing div a.sortdesc {
	color: #fff;
	background-image: url(../img/tablesorter/desc.gif);
	background-repeat: no-repeat;
	background-position: center right;
	padding-right: 20px;
}
a img {
	border: none;
}
abbr {
	border: none;
	cursor: help;
}
/* Michael Simon [2016.06.10]: YT-Teaser nicht ueber IFrame definieren, sondern hier im CSS */
iframe.yt-teaser-small {
	height:	149px;
}
iframe.yt-teaser-large {
	height:	273px;
}
/* Michael Simon [2012.01.25]: Gegenmaßnahmen für immer größer werdende Schriften */
td > p,
td ul > li,
td ol > li,
td ul > li > p,
td ol > li > p,
ul > li > p,
ol > li > p,
ul li ul li,
ul li ol li,
ol li ul li,
ol li ol li,
label > p,
#main .check-list li div p,
#main .unordered-list li div p,
#main .ordered-list li div p {
	font-size: 100% !important;
}
td > p.Unterueberschrift {
	font-size: 125% !important;
}
td > p.Zwischenueberschrift {
	font-size: 143% !important;
}
/* Michael Simon A.A. [2014.01.13]: Fix, damit hochgestellt / tiefgestellt keine zu grossen Zeilenabstaende macht */
sup, sub {
	line-height: 10%;
}
#main dd > p,
#main form fieldset div div.form-item p {
	padding-bottom: 0;
}
/* Michael Simon [2012.05.21]: Einrueckung bei Kontaktformularen */
label.ui-checkbox > p {
	padding-left: 2em;
}
/* Michael Simon [2012.05.21]: Einrueckung bei Kontaktformularen */
#main label.ui-checkbox[for='privacy'] > p {
	padding-left: 0;
	padding-bottom: 10px;
}
/* Michael Simon [2012.02.27]: Gegenmaßnahmen für zu kleine Schriften */
div.text > a,
div.text > div > div > a {
	font-size: 140%;
}
/* Michael Simon [2012.02.13]: Gegenmaßnahmen für zu großes padding-bottom bei <p>-Tags in Tabellenzellen */
#main table td {
	line-height: 140%;
}
/* Michael Simon A.A. [2013.10.14]: Zentrierung, Linksbuendig, Rechtsbuendig ueber den JS Editor */
#main table p.tableCenter {
	text-align: center;
}
#main table p.tableRight {
	text-align: right;
}

/* Michael Simon [2019.06.12]: Das CSS zur Jubilaeumsecke */
#anniversary-edge {
	position: fixed;
	bottom: 0;
	right: 0;
	color: #FFF;
	height: 0;
	width: 0;
	border-bottom: 15em solid #FFF;
	border-left: 25em solid transparent;
	z-index: 100000;
	transform-origin: right top;
	transition: right 1500ms ease,
				bottom 1500ms ease;
}
#anniversary-edge.closed {
	right: -25em;
	bottom: -15em;
}
#anniversary-edge .inner {
	position: absolute;
	bottom: -15em;
	right: 0;
	border-bottom: 14.7em solid #A11035;
	width: 0;
	height: 0;
	border-left: 24.5em solid transparent;
}
#anniversary-edge .inner .close {
	position: absolute;
	right: 1em;
	top: 3em;
	width: 17px;
	height: 17px;
	border: 1px solid #FFF;
	border-radius: 100%;
	text-indent: -9999px;
	background: #A11035 url(/custom/rwth/img/icons/icon-sprites.png) 111% -1396px no-repeat;
	cursor: pointer;
}
#anniversary-edge .inner .close:hover {
	background-color: #EA819C;
}
#anniversary-edge .inner .text {
	position: absolute;
	bottom: -13em;
	right: 2em;
	text-align: right;
	white-space: nowrap;
}
#anniversary-edge .inner .text .wrapper,
#anniversary-edge .inner .text a {
	color: white;
	font-size: 140%;
}
#anniversary-edge .inner .text .line-one {
	background-color: #A11035;
	font-size: 215%;
}
#anniversary-edge .inner .text .line-two {
	background-color: #A11035;
	font-size: 129%;
}
#anniversary-edge .inner .text .line-three {
	background-color: #A11035;
	font-size: 79%;
}

/* Editor CSS */
/* Michael Simon [2017.07.25]: Auf der letzten Webteamrunde wurde beschlossen, den Textmarker zu entfernen (angeregt durch Ralf Puetz)
span.Textmarker{
	background-color:yellow;
}
*/
blockquote > p.Quellenangabe {
	display: block;
	font-family: arial,helvetica,sans-serif;
	font-size: 75% !important;
	font-style: normal;
	margin-top: 5px;
}
p.Zwischenueberschrift {
	color: #262626;
	font-size: 200%;
	font-weight: normal;
	padding: 0 0 8px;
}
/* Michael Simon B.B. [2014.11.20]: CSS fuer die No-Float-Trennlinie. */
.trennlinie {
	width: 100%;
	clear: both;
	height: 0px;
}
/* Michael Simon A.A. [2013.10.31]: Weitere Zwischenueberschrift hinzugefuegt. */
#main p.Unterueberschrift {
color: #262626;
font-size: 175%;
font-weight: normal;
padding: 0;
}
table thead th.sortableColumn {
	background-image: url(../img/tablesorter/bg.gif);
	background-repeat: no-repeat;
	background-position: center right;
}
/* Hidden & Skip link  */
.aural,
.text .teaser-wrapper .rack-design .teaser span.aural,
.skip a,
#nav-global h6,
#nav-global h2,
#nav-global em,
#nav h6,
#nav h2.subnav,
#nav em,
#search-box h6,
#search-box label,
#breadcrumb h2,
#navigator label,
#persona h6,
#persona h2,
#persona-search label,
#events .event-search label,
#wrapper .new-search label,
.listing label,
#filter fieldset.date label,
.pagination em,
.pagination h3,
.vcard div h3,
.vcard .type,
#home h1 {
	display: block;
	position: absolute;
	overflow: hidden;
	left: -9999px;
	top: -9999px;
	width: 1px;
	height: 1px;
	font-size: 125%;
	min-height: 0;
	background: none;
}
/* Michael Simon A.A. [2014.10.06]: Damit die Ueberschrift bei Orgainfoboxen nicht verschwindet. */
.orgainfo.vcard div h3,
.orgainfo.vcard div h2.box-heading {
	display: inline;
	position: relative;
	left: 0;
}
/* Michael Simon D.D. [2016.01.11]: Damit der Link in einer Orgainfobox auch schwarz bleibt. */
.orgainfo.vcard div h3 a,
.orgainfo.vcard div h2.box-heading a {
	color: #000;
}
.skip a:active,
.skip a:focus {
	position: absolute;
	z-index: 1000;
	left: 20em;
	top: 3.7em;
	overflow: visible;
	outline: none;
	width: auto;
	height: auto;
	background: #000;
	padding: 7px 12px 8px 12px;
	font-size: 120%;
	font-weight: bold;
	text-decoration: none;
	color: #fff;
	text-transform: uppercase;
}
.faculty .skip a:active,
.faculty .skip a:focus {
	top: 7.7em;
}
/* clear */
#wrapper-2:after,
#breadcrumb:after,
#breadcrumb ol:after,
#article-functions:after,
#article-functions ul:after,
#site-info #site-info-inner:after,
#wrapper .form-button:after,
.article-metadata:after,
.article-functions .section ul:after,
.teaser-row:after,
.teaser:after,
.two-column-box:after,
span.caption-wrapper:after,
#filter label:after,
#faculty-stage:after,
.teaser-row:after,
#branding-inner:after,
#home .teaser-row .teaser .teaser-box:after,
#main .text .more-box ul li:after,
#main .text dl:after,
.contact-person .contact-box:after,
.gallery .multimedia-description:after,
.contact-person #main .vcard .contact-box .tel:after,
.contact-person .contact-box:after,
.text-media-block:after,
#main .text .profile dl:after,
.listing ul:after,
#stage:after,
#persona:after,
#persona .persona-item:after,
#navigator .col-box:after,
#faculty-stage #stage .teaser-wrapper:after,
#main .teaser-row .teaser.h-color-1 .teaser-inner:after,
#main .teaser-row .teaser.h-color-2 .teaser-inner:after,
#main .teaser-row .teaser.h-color-3 .teaser-inner:after,
#home.faculty #main .teaser-row .teaser .teaser-inner:after,
.two-columne #main .box:after,
.teaser-group:after,
.ts-box:after,
#gallery-overview #main .teaser-row .teaser .teaser-inner:first-child:after,
.tw-history .rack-design:after,
.media-controls .media-bar:after,
.text .tw-history  {
	content: " ";
	display: block;
	clear: both;
}
/* GRID / LAYOUT
############################ */
#wrapper {
	background: #fff;
	margin: 0 auto;
	max-width: 128em;
}
#branding-box {
	margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
	#branding-box {
		max-width: 98em;
	}
}
#branding-inner {
	max-width: 100% !important;
	display: table;
	width: 100%;
}
#logo {
	display: table-cell;

	padding-left: 0px;

}
/* Michael Simon C.C. [2015.05.11]: Statt dem Glow haben unsere Logos nun eine Border (ausgenommen RWTH-Hauptseite). */
/* Michael Simon [2017.03.09]: Vorbereitung fuer Logos mit transparentem Hintergrund. */
#logo img {
	position: relative;
	top: -40px;
	display: block;
	margin-left: 0;
	border-width: 0 1px 1px;
	border-style: solid;
	border-color: #ccc;
	background-color: #fff;
	height: 110px;
}
#logo img[src='https://www.rwth-aachen.de/global/show_picture.asp?id=aaaaaaaaaaagazb'],
#logo img[src='http://www.rwth-aachen.de/global/show_picture.asp?id=aaaaaaaaaaagazb'],
#logo img[src='//www.rwth-aachen.de/global/show_picture.asp?id=aaaaaaaaaaagazb'],
#logo img[src='/global/show_picture.asp?id=aaaaaaaaaaagazb'],
#logo img[src='/custom/errors/rwth.png'],
#logo img[src*='aaaaaaaaaaagazb'] {
	border-width: 0;
}
#branding-inner.banderol #logo img {
	border: none;
	background-color: transparent;
	width: 125px;
	height: auto;
}
#branding-inner.banderol.no-main-logo #logo img {
	width: 0;
	height: 0;
}
#branding-inner.banderol.few-navi #logo img {
	margin-bottom: -20px;
}
#branding-inner.banderol.more-navi #logo img {
	margin-bottom: -40px;
}
#branding-inner.banderol.animated-navi #logo a {
	position: absolute;
	left: 0;
	top: -40px;
	display: block;
	max-height: 101px;
	overflow: hidden;
	transition: .5s ease max-height;
	z-index: 101;
}
#branding-inner.banderol.animated-navi #logo a:hover {
	max-height: 350px;
}
#branding-inner.banderol.animated-navi #logo a img {
	top: 0;
}
/* Michael Simon A.A. [2014.08.08]: Damit das Logo ohne JS nicht ueber dem Faknavigator ist. */
.js-off #logo img {
	top: 0;
}
@media only screen and (max-width: 920px) {
	#logo img {
		max-width: 380px;
		height: auto;
	}
}
#logo-extension {
	display: table-cell;
	vertical-align: top;
	padding-left: 20px;
	padding-top: 12px;
	width: 90%;
	padding-right: 10%;
	font-size: 160%;
	color: #464646;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: normal
}
#logo-extension .fallback-link {
	color: #464646;
	padding: 13px 16px;
	margin-left: -16px;
	display: inline-block;
	margin-top: -12px;
}
#logo-extension .fallback-link:hover,
#logo-extension .fallback-link:focus {
	background-color: #E6E6E6;
	text-decoration: none;
}

.banderol.animated-navi #logo-extension {
	display: block;
	padding: 12px 3.5em 0 200px;
	width: calc(100% - 3.5em - 200px);
	min-height: 60px;
}

#main .dse-iframe-wrapper,
#main .dse-js-wrapper,
#main .dse-flash-wrapper {
	width: calc(100% + 2px);
	background-color: #FFF;
	border: 1px solid #333;
	padding: 6px 6px 12px;
	box-sizing: border-box;
	position: relative;
	margin-bottom: 5px;
}
#main .dse-iframe-wrapper a.show-iframe-content,
#main .dse-js-wrapper a.show-js-content,
#main .dse-flash-wrapper a.show-flash-content {
	position: absolute;
	right: 4px;
	bottom: 4px;
	border: none;
	background-color: #00549F;
	color: #FFF;
	padding: 4px 8px;
	font-weight: bold;
	cursor: pointer;
	font-size: 125%;
}
#main .dse-iframe-wrapper a.show-iframe-content:hover,
#main .dse-js-wrapper a.show-js-content:hover,
#main .dse-flash-wrapper a.show-flash-content:hover {
	background-color: #2985DB;
	text-decoration: none;
}

#main .dse-video-wrapper {
	width: 100%;
	/*background: linear-gradient(#FFF, #FCFCFC, #EAEAEA) #FFF no-repeat 50% 50%/cover;*/
	background: url(/custom/rwth/img/video-preview.jpg) #FFF no-repeat 50% 50%/cover;
	border: 1px solid #333;
	box-sizing: border-box;
	position: relative;
}
#main .dse-video-wrapper.module {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
#main .dse-video-wrapper .dse-video-play-button {
	width: 80px;
	height: 56px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -28px 0 0 -40px;
	background: url(../img/icons/yt-play.png) no-repeat 0 0/contain;
	cursor: pointer;
	text-indent: -9999px;
}

#dse-video-background {
	background-color: rgba(0,0,0,0.7);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}
#dse-video-background .dialog-wrapper {
	position: absolute;
	left: 50%;
	top: 50%;
	width: calc(100% - 30px);
	height: auto;
	background-color: #FFF;
	transform: translate(-50%, -50%);
	max-width: 550px;
	max-height: calc(100% - 30px);
	border: 1px solid #333;
	padding: 20px;
	box-sizing: border-box;
	font-size: 130%;
	line-height: 140%;
}
#dse-video-background .dialog-wrapper p {
	padding-bottom: 10px;
}
#dse-video-background .dse-button-wrapper {
	position: absolute;
	right: 5px;
	bottom: 5px;
	width: calc(100% - 10px);
}
#dse-video-background .dse-button-wrapper > div {
	background-color: #00549F;
	color: #FFF;
	display: inline-block;
	padding: 3px 6px;
	cursor: pointer;
	margin-left: 5px;
	float: right;
}
#dse-video-background .dse-button-wrapper > div:hover {
	background-color: #2985DB;
}

#branding-inner.banderol.few-navi.no-main-logo {
	height: 175px;
}

#top-grid {
	display: -ms-grid;
	display: grid;
	position: absolute;
	right: 0;
	top: 0;
	left: 125px;
	-ms-grid-columns: 1fr auto;
	-ms-grid-rows: auto auto;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto;
	padding: 0;
}

#branding-inner.banderol.few-navi.no-main-logo #top-grid {
	left: 3px;
}

#top-grid #addlogo {
	top: 0;
	right: 0;
	width: 100%;
	direction: rtl;
	-ms-grid-row: 1;
	-ms-grid-column: 2;
	grid-row-start: 1;
	grid-column-start: 2;
}
#top-grid #addlogo img {
	background-color: #fff;
	display: inline-block;
	max-width: 100%;
	-ms-flex: 0 1 auto;
	flex-shrink: 1;
	max-height: 91px;
}

#top-grid #logo-extension {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
	-ms-grid-row-span: 2;
	grid-row-start: 1;
	grid-column-start: 1;
	grid-row-end: span 2;
	display: block;
	padding: 12px 20px 0 20px;
	margin-top: 0;
}

#top-grid #nav-meta {
	-ms-grid-row: 2;
	-ms-grid-column: 2;
	grid-row-start: 2;
	grid-column-start: 2;
	position: relative;
	right: 0;
}
#top-grid #nav-meta li {
	text-align: right;
}

#branding-inner,
#site-info-inner,
#navigator-inner,
#persona ol,
.persona-group,
#site-info-inner,
#section-header {
	position: relative;
	margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
	#branding-inner,
	#site-info-inner,
	#navigator-inner,
	.persona-group,
	#site-info-inner,
	#section-header {
		max-width: 98em;
	}
}

.construction #site-info ul {
	list-style: none;
	padding-top: 36px;
	text-align: center;
}
.construction #site-info ul li {
	display: inline-block;
	margin: 0 30px 0 0;
}
.construction #site-info ul li:last-child {
	margin-right: 0;
}

#site-info-inner {
	position: relative;
	max-width: 103em; /* Todo */
	margin: 0 auto;
}

#section-header {

}
#persona ol {
	padding: 0;
}
.site-navigation-box {
	max-width: 98em;
	margin: 0 auto;
	padding: 0 20px;
}
#breadcrumb {
	position: relative;
	z-index: 1;
	width: 85%;
	float: left;
}
#breadcrumb ol {
	position: relative;
}
#stage,
#persona {
	clear: both;
	position: relative;
	max-width: 128em;
	margin: 0 auto;
}
#navigator {
	max-width: 128em;
	position: absolute;
	top: 0;
	width: 100%;
}
#search-box {
	position: absolute;
	top: -32px;
	right: 0;
}
#nav-meta {
	position: absolute;
	top: 12px;
	right: 0;
	z-index: 1;
}
.em-increased-3 #nav-meta {
	top: 3.6em;
}
#wrapper-2 {
	max-width: 98em;
	margin: 0 auto 0 auto;
	border-top: 4px solid #000;
	padding: 20px 0 0 0;
}
.construction #wrapper-2 {
	border-top: 0px solid #000;
}
#wrapper-2-outer {
	max-width: 102em;
	padding: 0 20px;
	margin: 0 auto;
}
#wrapper-3 {
	display: table;
	width: 100%;
}
#nav,
#search-results #nav {
	/*display:table-cell;
	vertical-align: top;
	min-width: 22.3em;*/
	width: 22.3em;
	padding: 0 1.4em 0 0;
}
.col #main {
	padding: 0;
	display: block;
	background: none;
}
#main {
	padding: 0 0 0 1.3em;
	display: table-cell;
	vertical-align: top;
	width: 100%;
	background: url(../img/bgs/border-vertical.gif) repeat-y 0 100%;
}
.single-page #main {
	float: right;
	max-width: 72.9em;
	width: 100%;
	padding-left: 0;
	background: none;
	display: block;
}
#search-results #main {
	background: none;
	padding: 0;background: none;
}
#gallery-overview #main {
	background: none;
}
.col #main {
	margin-left: 0;
	padding: 0;
}
#article-functions .af-box {
	position: relative;
	width: 98em;
	margin: 0 auto;
}
/* ***** BRANDING ****** */
#branding {
	border-top: 40px solid #000;
	background: #F7F7F7;
}
.faculty #branding {
	border-top: 40px solid #000;
	background: #F7F7F7;
}
.division #branding {
	background: #F7F7F7;
}

#logo span {
	display: block;
}
#logo span {
	display: block;
	width: 22.4em;
}
/* Formulare */
/* Michael Simon A.A. [2014.07.30]: Die "todo"s von Aperto erledigt, damit dynamische Formulare nun auch auf Tablets besser aussehen. */
.form-wrapper div.size0 {
	width: 59.5em;
}
.form-wrapper div.size1 {
	clear: none;
	float: left;
	margin-right: 2.2em;
	width: 43.8em;
}
.form-wrapper div.size2 {
	clear: none;
	float: left;
	margin-right: 2.2em;
	width: 28.5em;
}
.form-wrapper div.size3 {
	clear: none;
	float: left;
	margin-right: 2.2em;
	width: 13.2em;
}
.form-wrapper .form-item h4 {
	font-size: 120%;
}
/* Search */
#branding #search-box {
	z-index: 2;
}
#branding #search-box input {
	background: #666 url(../img/icons/icon-sprites.png) 4px -1596px no-repeat;
	border: 0;
	padding: 4px 20px 4px 30px;
	font-size: 130%;
	font-family: Arial, Helvetica, sans-serif;
	color: #fff;
	border: none;
}
#branding #search-box input:focus {
	background-color: #777;
}
#branding #search-box input.button {
	background: transparent url(../img/icons/icon-sprites.png) 4px -2191px no-repeat;
	width: 10px;
	position: absolute;
	right: 5px;
	top: 0;
	padding: 3px 0;
	text-indent: -9999em;
	cursor: pointer;
	border:  none;
}
#branding #search-box input.button:hover,
#branding #search-box input.button:focus {
	background-position: 4px -2391px;
}
/* Auto Complete */
/* Michael Simon A.A. [2014.07.10]: CSS fuer Suchvorschlaege */
#search-results ul.ui-autocomplete.main-search {
background-color: #fff;
border: 1px solid #ccc;
}
#search-results ul.ac-default.main-search {
min-height: 1px;
}
#search-results ul.ui-autocomplete.main-search li {
background-color: #fff;
background-image: none;
}
#search-results ul.ac-default.main-search a {
padding: 0 0.5em;
background-image: none;
color: #000;
}
#search-results ul.ui-autocomplete.main-search li:hover {
background-color: #f3f3f3;
}
#search-results ul.ui-autocomplete.main-search a span {
background-color: transparent;
font-weight: bold;
}

ul.ui-autocomplete {
	position: absolute;
	margin: 0;
	cursor: default;
	list-style: none;
	border: 4px solid rgb(61,123,180);
	background: rgb(26, 101, 169);
	font-size: 130%;
	z-index: 100 !important;
}
ul.ac-default {
	width: 500px;
	min-height: 142px;
	max-height: 450px;
	overflow-y: auto;
}
ul.ac-extended {
	border-color: #777777;
	width: 470px;
	background-color: #fff;
}
ul.ac-extended.main-search {
	width: 56.805em;
	border: 1px solid #ccc;
	padding-top: 20px;
	border-top: none;
	border-radius: 0px 0px 8px 8px;
	-moz-border-radius: 0px 0px 8px 8px;
	-webkit-border-radius: 0px 0px 8px 8px;
	padding-bottom: 10px;
}
ul.ui-autocomplete li {
	background: rgb(26, 101, 169) url(../img/bgs/dotted-line-navigator.png) 0 0 repeat-x;
}
ul.ac-extended li {
	background-color: transparent;
	background-image: none;
}
ul.ac-extended h3 {
	padding: 8px;
}
ul.ac-extended ul {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	background-color: #f0f0f0;
	list-style: none;
}
ul.ac-extended ul li {
	border-top: 1px dotted #ccc;
	background: none;
	padding: 5px 0;
}
ul.ac-extended ul li:first-child {
	border-top: none;
}
ul.ui-autocomplete li.ui-state-hover {
	background-color: #0D5DA4;
}
ul.ui-autocomplete li li.ui-state-hover {
	background-color: transparent;
}
ul.ui-autocomplete li:first-child {
	background-image: none;
}
ul.ui-autocomplete a {
	display: block;
	background: url(../img/icons/icon-sprites.png) no-repeat scroll 20px 8px transparent;
	padding: 7px 20px 7px 45px;
}
ul.ui-autocomplete .ac-date a {
	padding-top: 3px;
	padding-bottom: 3px; /* Michael Simon A.A. [2014.08.21]: Anpassungen, da wir das Datum bei Best Bets nicht mehr anzeigen. */
	background-position: 20px 4px;
}
ul.ac-default a {
	color: white;
	background: url(../img/icons/icon-sprites.png) 6px -987px no-repeat;
	padding: 12px 20px 12px 30px;
}
ul.ui-autocomplete a span {
	background-color: rgb(255,242,190);
	color: black;
}
ul.ui-autocomplete span.date {
	padding-left: 20px;
	display: block;
	padding-top: 4px;
	color: #666;
}
ul.ui-autocomplete .ac-date_extended li {
	overflow: hidden;
}
ul.ui-autocomplete .ac-date_extended a {
	background-image: none;
}
ul.ui-autocomplete .ac-date_extended span.date {
	float: left;
	width: 54px;
	height: 44px;
	border: 2px solid #ccc;
	color: black;
	text-transform: uppercase;
	padding: 10px 0 0;
	text-align: center;
	margin-left: 20px;
	margin-right: 15px;
}
ul.ac-extended a.submit {
	background: url(../img/icons/icon-sprites.png) no-repeat scroll 94% -2993px #E1E1E1;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	color: black;
	cursor: pointer;
	display: block;
	float: right;
	font-size: 85%;
	font-weight: bold;
	margin: 7px;
	min-width: 200px;
	padding: 6px 25px 6px 10px;
	text-transform: uppercase;
}
ul.ac-extended a.submit:hover,
ul.ac-extended a.submit:focus {
	text-decoration: none;
}
ul.ui-autocomplete .ui-state-hover a {
	cursor: pointer;
	text-decoration: none;
}
/* ALL NAVIGATIONS incl. Flyouts
############################ */
/* ********** Main Navigation ********** */
#home #nav-global {
	padding-top: 5px;
	margin-top: 0;
}
#nav-global {
	margin-top: 0;
	padding-top: 5px;
	background: #F7F7F7;
}
#nav-global.has-subnav {

}
#nav-global .nav-global-subnav {
	border-bottom: 1px solid #E5E5E5;
}
#nav-global.has-subnav .nav-global-item.js-expanded,
#home.faculty .nav-global-item.js-expanded {
	border-bottom: 1px solid #E5E5E5;
}
.division #nav-global.has-subnav {
	border-bottom: 0 hidden #FFFFFF;
}

.nav-global-inner {
	position: relative;
	background: #FFFFFF;
	/*
	AW Case 23357 auskommentiert, wg. CE Buttons in 2 und 3 Naviebene.
	Michael Simon A.A. [2014.08.21]: Die Klasse wird einzig für die ausklappbare Navigation im Header (außer bei Institutsseiten, da dort nichts ausklappt) verwendet.
									 Nach einigen Tests unterhalb der RWTH Startseite, exemplarischen Fakultätsstartseiten und Fachgruppenstartseiten, sehe ich keine
									 negativen Auswirkungen und lasse es nun auskommentiert.
	z-index: 9;
	*/
}
#home .nav-global-inner .menu {
	padding: 15px 20px 20px 20px;
	max-width: 98em;
}
#home .nav-global-group {
	margin: 0 auto;
}
#nav-global .menu,
.nav-global-subnav ul  {
	overflow: hidden;
	position: relative;
	max-width: 98em;
	margin: 0 auto;
}
.nav-global-toc  {
	overflow: hidden;
	position: relative;
	max-width: 98em;
	width: 100%;
	margin: 0 auto;
}
#branding-inner.banderol #section-header {
	display: none;
}
#branding-inner.banderol.few-navi #nav-global {
	position: absolute;
	right: 0;
	bottom: 0;
	width: calc(100% - 200px);
}
#branding-inner.banderol.few-navi #nav-global .nav-global-toc {
	width: 100%;
	margin: 0 0 0 auto;
}
.banderol.few-navi #nav-global .nav-global-toc li a {
	font-size: 80%;
	padding: 15px 20px;
}
.nav-global-subnav {
	background: #fff;
}
.nav-global-subnav {
	width: 100%;
}
.nav-global-wrapper {
	width: 100%;
}
/* Toc */
.nav-global-toc ol {
	position: relative;
	float: right;
}
.nav-global-toc li {
	float: left;
	margin: 0 0 0 2px;
	list-style: none;
	font-size: 180%;
	font-weight: bold;
	text-transform: uppercase;
}
#nav-global.banderol.few-navi .nav-global-toc li {
	float: right;
}
.nav-global-toc a,
.nav-global-toc strong {
	display: block;
	background: url(../img/icons/icon-sprites.png) 100% -3164px no-repeat;
	padding: 28px 30px 30px 20px;
	color: #333;
	word-wrap: break-word;
}
.division .nav-global-toc a,
.division .nav-global-toc li a.on,
.division .nav-global-toc strong {
	padding: 28px 20px 30px;
	background-image: none;
	color: #333333;
}
.division .nav-global-toc li a.on {
	color: #FFFFFF;
}
.division .nav-global-toc li a.js-selected,
.division .nav-global-toc li a.on.js-selected,
.division .nav-global-toc li a.open.js-selected,
.division .nav-global-toc li a.open {
	background-image: none;
}
.nav-global-toc a:hover,
.nav-global-toc a:active,
.nav-global-toc a:focus {
	background-color: #e6e6e6;
	text-decoration: none;
	cursor: pointer;
}
.nav-global-toc a.js-selected,
.nav-global-toc li a.on.js-selected,
.nav-global-toc li a.open.js-selected,
.nav-global-toc a.open {
	background-color: #fff;
	background-image: url(../img/icons/icon-sprites.png);
	background-position: 100% -3364px;
	text-decoration: none;
	color: #333;
}
.nav-global-toc a.open {
	background-position: 100% -3164px;
}
.nav-global-toc li a.on {
	background: #00549F url(../img/icons/icon-sprites-3.png) 86% -5164px no-repeat;
	color: #fff;
}
/* menu */
#nav-global .nav-global-item {
	width: 100%;
	/*	background-color: #fff;
		z-index: 1;*/
}
#nav-global.has-subnav .nav-global-item,
#home.faculty .nav-global-item {
	/*border-bottom: 1px solid #E5E5E5;*/
	top: 0;
}

#nav-global .menu {
	padding: 15px 0 0 0;
	margin-bottom: -3em; /* todo */
	min-height: 13em;/* todo */


}
#nav-global .menu-box {
	overflow: hidden;
	width: 100%;
}
#nav-global .menu h3 {
	border-bottom: 4px solid #000;
	margin: 0 10px 25px 10px;
	padding: 0 0 10px 0;
	font-size: 300%;
	font-weight: normal;
	text-transform: none;
}
#nav-global .menu .overview {
	display: block;
	margin-bottom: 15px;
}
#nav-global .menu .overview p {
	font-size: 140%;
	font-weight: normal;
	text-transform: none;
	line-height: 1.4em;
}
#nav-global .menu .overview .more {
	position: static;
	height: auto;
	width: auto;
	font-size: 100%;
	margin: 25px 0 0 0;
	font-style: normal;
}
#nav-global .menu .overview .more a {
	display: inline-block;
	color: #fff;
	background: #000 url(../img/icons/icon-sprites.png) 94% -2791px no-repeat;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	font-size: 86%;
	font-weight: bold;
	padding: 6px 25px 6px 8px;
	text-transform: uppercase;
}
#nav-global .menu .overview .more a:hover,
#nav-global .menu .overview .more a:active,
#nav-global .menu .overview .more a:focus {
	background-color: #00549f;
	text-decoration: none;
}
#nav-global .menu .menu-list-box {
	display: table;
	word-spacing: -1em;
	padding: 0;
	width: 100%;
}
#nav-global .menu .menu-list * {
	word-spacing: 0;
}
#nav-global .menu .menu-list {
	display: inline-block;
	width: 20%;
	margin: 0 0 30px 0;
	padding: 0;
	vertical-align: top;
}
#nav-global .menu .menu-list h4 {
	padding: 0 10px 14px 10px;
	font-size: 160%;
	text-transform: none;
}
#nav-global .menu .menu-list h4 a {
	padding: 0;
	font-size: 100%;
	margin: 0;
	background: none;
	color: #00549F;
}
#nav-global .menu .menu-list h4 a:hover,
#nav-global .menu .menu-list h4 a:focus,
#nav-global .menu .menu-list h4 a:active {
	text-decoration: underline;
}
#nav-global .menu .menu-list ul {
	margin: 0 10px 20px 10px;
	background: url(../img/bgs/dotted-line-persona.png) repeat-x 0 0;
}
#nav-global .menu .menu-list ul li {
	display: block;
	float: none;
	margin: 0;
	padding: 1px 0 2px 0;
	font-size: 130%;
	line-height: 1.6em;
	background: url(../img/bgs/dotted-line-persona.png) repeat-x 0 100%;
}
#nav-global .menu .menu-list ul li a {
	display: block;
	padding: 7px 0 7px 25px;
	color: #00529F;
	background: url(../img/icons/icon-sprites.png) no-repeat 5px 10px;
}
#nav-global .menu .menu-list ul li a.intranet {
	background-position: 5px -24790px;
}
#nav-global .menu .menu-list ul li a:hover,
#nav-global .menu .menu-list ul li a:active,
#nav-global .menu .menu-list ul li a:focus {
	background-color: #E5EEF5;
	text-decoration: none;
}
.js-off #nav-global .menu .close {
	display: none;
}
#nav-global .menu .close {
	position: absolute;
	top: 25px;
	right: 20px;
	background: #f2f2f2 url(../img/icons/icon-sprites.png) 87% -1192px no-repeat;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	padding: 6px 25px 6px 8px;
	font-size: 110%;
	font-weight: bold;
	text-transform: uppercase;
	color: #333;
	display: block;
}
#nav-global .menu .close:hover,
#nav-global .menu .close:active,
#nav-global .menu .close:focus {
	color: #fff;
	background: #00529F url(../img/icons/icon-sprites.png) 87% -1392px no-repeat;
	text-decoration: none;
}
/* 2nd level */
.nav-global-subnav li {
	float: left;
/*	margin: 0 0 0 2px; Michael Simon B.B. [2014.10.13]: Das bisschen mehr Abstand sorgte in der Mathe dazu, dass die 2. Navileiste umbrach. */
	padding: 0;
	font-weight: normal;
	list-style: none;
	font-size: 140%;
}
.nav-global-subnav li a,
.nav-global-subnav li.on a,
.nav-global-subnav li strong {
	display: block;
	background: #fff;
	color: #000;
	padding: 1.2em;
}
.nav-global-subnav li a:hover,
.nav-global-subnav li a:active,
.nav-global-subnav li a:focus {
	background: #e6e6e6;
	text-decoration: none;
}
.nav-global-subnav li.on a,
.nav-global-subnav li strong {
	background: #00549F;
	color: #fff;
}
.nav-global-subnav li.open a {
	background: #e6e6e6;
	font-weight: bold;
}
/* ********* Sub Navigation ******** */
#nav .nav-back {
	margin-bottom: 20px;
}
#nav .nav-back a {
	background: #ccc url(../img/icons/icon-sprites.png) 8px -5187px no-repeat;
	padding: 11px 10px 11px 28px;
	color: #333333;
	font-size: 120%;
	display: block;
	font-weight: bold;
}
#nav ul {
	list-style: none;
}
#nav ul li {
	padding: 1px 0 2px 0;
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
}
#nav ul li a,
#nav ul li strong {
	display: block;
	font-size: 140%;
	color: #333333;
	padding: 13px 10px 13px 22px;
}
#nav ul li.has-childs a,
#nav ul li.has-childs strong {
	background: url(../img/icons/icon-sprites-1.png) no-repeat 5px -782px;
}
#nav ul li strong,
#nav ul li.open a:hover,
#nav ul li.open a:active,
#nav ul li.open a:focus,
#nav ul li a:hover,
#nav ul li a:active,
#nav ul li a:focus {
	color: #fff;
	text-decoration: none;
	background: #00549F;
}
#nav ul li.open a {
	font-size: 140%;
	color: #333333;
	font-weight: bold;
	padding-left: 17px;
	background: #eee url(../img/icons/icon-sprites-1.png) no-repeat 5px -982px;
}
#nav ul li.open ul {
	padding-top: 1px;
	background: url(../img/bgs/border-50.png) repeat-x 0 0;
}
#nav ul li.open ul li {
	margin-left: 20px;
}
#nav ul li.open ul ul {
	background: none repeat scroll 0 0 transparent;
}

#nav ul li.open ul li.last-child {
	background: none;
}
#nav ul li.open ul li:last-child {
	background: none;
}
#nav ul li.open li a {
	display: block;
	font-weight: normal;
	font-size: 140%;
	color: #333333;
	background: #fff;
}
#nav ul li a.intranet {
	background-image: none;
}
#nav ul li a.intranet::before {
	content: "";
	display: inline-block;
	background: #FFF url(../img/icons/icon-sprites.png) no-repeat -4px -25200px;
	width: 0.7em;
	height: 0.95em;
}
#nav ul li.open > a.intranet::before {
	filter: brightness(0.92);
}
#nav ul li.open li a:hover,
#nav ul li.open li strong {
	padding: 13px 10px 13px 22px;
	display: block;
	font-size: 140%;
	padding-left: 17px;
	color: #fff;
	text-decoration: none;
	background: #00549F;
}
#nav ul li a.intranet:focus::before, 
#nav ul li a.intranet:hover::before, 
#nav ul li strong.intranet::before {
	content: "";
	display: inline-block;
	background: #00549F url(../img/icons/icon-sprites.png) no-repeat -4px -24800px;
	width: 0.5em;
	height: 0.95em;
}
#nav ul li a.intranet:focus::before,
#nav ul li a.intranet:hover::before {
	width: 0.7em;
	filter: none;
}
#nav ul li.open strong {
	display: block;
	font-weight: bold;
	font-size: 140%;
	color: #fff;
	background: #00549F url(../img/icons/icon-sprites-1.png) no-repeat 5px -1382px;
}
/*  ********* Navigator  ********* */
#navigator {
	background: #00539f;
	color: #fff;
}
#navigator h2 {
	font-size: 100%;
	position: absolute;
	bottom: -40px;
	height: 40px;
	font-size: 100%;
	padding: 0;
}

.js #navigator h2 {
	display: none;
}

#navigator h2 a {
	display: block;
	font-size: 100%;
	text-transform: uppercase;
	background: url(../img/bgs/fakultaeten-bg.png) 100% 0 no-repeat;
	padding: 0;
	font-weight: bold;
	background-color: black;
}
#navigator h2 a span {
	display: block;
	padding: 12px 45px 12px 20px;
	font-size: 120%;
	text-transform: uppercase;
}
.js-on #navigator h2 a span {
	display: block;
	padding: 12px 35px 12px 20px;
	font-size: 120%;
	text-transform: uppercase;
	color: #fff;
	font-weight: bold;
}
#navigator h2 a:hover,
#navigator h2 a:active,
#navigator h2 a:focus {
	cursor: pointer;
	text-decoration: none;
	background-position: 100% -200px;
}
#navigator h2 a.js-selected {
	background-position: 100% -400px;
}
#navigator h2 a.js-selected:hover,
#navigator h2 a.js-selected:active,
#navigator h2 a.js-selected:focus {
	background-position: 100% -600px;
}
.navigator-content {
	display: none;
	overflow: hidden;
	padding: 0 2em;
}
.navigator-breadcrumb {
	margin: 0 0 20px 0;
	border-bottom: 4px solid #276ba8;
	padding: 30px 0;
	font-size: 140%;
	background-color: #00549F;
}
#navigator .close {
	position: absolute;
	top: 26px;
	right: 0;
	background: #1a65a9 url(../img/icons/icon-sprites.png) 87% -1392px no-repeat;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	padding: 6px 25px 6px 8px;
	font-size: 110%; /* 11px */
	font-weight: bold;
	text-transform: uppercase;
	color: #fff;
}
#navigator .close:hover,
#navigator .close:active,
#navigator .close:focus {
	background-color: #000;
	color: #fff;
	text-decoration: none;
}

#navigator .col-1,
#navigator .col-2,
#navigator .col-3 {
	float: left;
	width: 21.83%;
	background-color: #00549F;
}
#navigator .col-2 {
	width: 56.33%;
}
#navigator .col-2 .col-box {
	border-left: 1px solid #276ba8;
	border-right: 1px solid #276ba8;
	margin: 0 12px 20px;
	padding: 0 12px;
}

#navigator h3 {
	padding: 0 0 18px 0;
	font-size: 160%;
	text-transform: uppercase;
}
#navigator ul {
	background: url(../img/bgs/dotted-line-navigator.png) 0 100% repeat-x;
}
#navigator li {
	background: url(../img/bgs/dotted-line-navigator.png) 0 0 repeat-x;
	list-style: none;
	font-size: 140%;
	font-weight: bold;
	padding: 2px 0 1px 0;
}
#navigator li a {
	display: block;
	background: url(../img/icons/icon-sprites.png) 6px -991px  no-repeat;
	padding: 8px 0 7px 26px;
	color: #fff;
}
#navigator li a.intranet {
	background-position: 6px -24591px;
}
#navigator li a:hover,
#navigator li a:active
#navigator li a:focus {
	background-color: #0D5DA4;
	text-decoration: none;
}
#navigator span {
	display: block;
	padding: 0 0 10px 20px;
	font-weight: normal;
	font-size: 85.7%; /* 12px */
	color: #c8ddee;
}
#navigator .col-2 ul {
	float: left;
	width: 46%;
	margin: 0 0 0 30px;
}
#navigator .col-2 ul li {
	margin-bottom: 1px;
	padding-top: 2px;
}
#navigator .col-2 ul.first {
	margin-left: 0;
}
#navigator .col-2 li:nth-child(even) {
	margin: 0;
}
#navigator .col-2 li a {
	background-position: 6px -990px;
	padding-top: 10px;
}
#navigator .col-2 li a span {
	display: block;
	margin-top: 0;
	padding-left: 0;
}
#navigator .insitut-search {
	float: left;
	width: 100%;
	clear: both;
	margin-top: 15px;
	border-top: 4px solid #276ba8;
	padding-top: 20px;
	padding-bottom: 20px;
}
#navigator .insitut-search h3 {
	float: left;
	padding: 5px 0 10px 0;
}
#navigator .insitut-search form {
	float: right;
	position: relative;
}
#navigator .insitut-search input {
	background: #3d7bb4 url(../img/icons/icon-sprites.png) 4px -1794px no-repeat;
	border: 0 !important;
	padding: 6px 20px 6px 30px;
	font-size: 130%;
	font-family: Arial, Helvetica, sans-serif;
	color: #fff;
}
#navigator .insitut-search input.button {
	position: absolute;
	top: 1px;
	right: 3px;
	background: transparent url(../img/icons/icon-sprites.png) 4px -2191px no-repeat;
	width: 20px;
	padding: 0;
	text-indent: -9999em;
	cursor: pointer;
}
#navigator .insitut-search input.button:hover,
#navigator .insitut-search input.button:focus {
	background-position: 4px -2391px;
}
/*  ********* Persona  ********* */
#persona {
	position: relative;
	margin-top: -3px;
	padding: 0 0 40px 0;
}
#persona h3 {
	display: none;
}
.persona-toc {
	overflow-x: auto;
	overflow-y: hidden;
	background: #fff059;
}
#persona ol {
	list-style: none;
	width: 100%; /* Michael Simon [2016.11.07]: 100% damit der Scrollbalken nur kommt, wenn es zu viel wird. */
	white-space: nowrap;
}
#persona ol li {
	display: inline-block; /* Michael Simon [2018.02.14]: Erweiterung der Slidervariante. */
	margin: 0 0 0 2px;
	font-size: 150%;
	vertical-align: top;
}
#persona ol li:first-child {
	margin: 0;
}

/* Michael Simon [2018.04.11]: Code explizit fuer die JS-Variante der Zielgruppennavigation */
#persona .persona-toc.jsn {
	position:   relative;
	height:     115px;
	overflow:   hidden;
}
#persona .persona-toc.jsn ol.toc {
	position:   absolute;
	top:        0;
	left:       0;
	width:      auto;
}
#persona .persona-toc.jsn ol.toc.animation {
	transition: left 0.5s ease;
}
#persona .persona-toc .navigation-arrow {
	position: absolute;
	top: 50%;
	width: 40px;
	height: 40px;
	transform: translate(0, -50%);
	background: rgba(0,0,0,0.6) url(../img/icons/teaser-wrapper-sprite.png) 6px 2px/23px no-repeat;
	cursor: pointer;
}
#persona .persona-toc .navigation-arrow.prev {
	left: 0;
}
#persona .persona-toc .navigation-arrow.next {
	right: 0;
	background-position: 10px -107px;
}
#persona .persona-toc .navigation-arrow:hover {
	background-color: #00549F;
}
#persona .persona-toc .navigation-arrow.inactive {
	display: none;
}
/* ENDE JS-Variante: Zielgruppennavigation */

#persona ol li#persona-search {
	float: right;
}
#persona ol li a { /* item-1 */
	display: block;
	background: url(../img/icons/persona.png) 50% 25px no-repeat;
	padding: 60px 15px 20px 15px;
	color: #000;
	text-align: center;
	box-sizing: border-box;
	height: 115px;
}
.no-svg #persona ol li a { /* item-1 */
	background: url(../img/icons/persona.png) 50% 25px no-repeat;
}
#persona ol li.item-2 a {
	background-position: 50% -1475px;
}
#persona ol li.item-3 a {
	background-position: 50% -125px;
}
#persona ol li.item-4 a {
	background-position: 50% -1325px;
}
#persona ol li.item-5 a {
	background-position: 50% -275px;
}
#persona ol li.item-6 a {
	background-position: 50% -1175px;
}
/* Michael Simon [2016.11.07]: Neue Icons fuer Zielgruppen. */
#persona ol li.item-7 a {
	background-position: 50% -575px;
}
#persona ol li.item-8 a {
	background-position: 50% -875px;
}
#persona ol li.item-9 a {
	background-position: 50% -425px;
}
#persona ol li.item-10 a {
	background-position: 50% -725px;
}
/* Altes Icon: Eltern und Lehrkräfte
#persona ol li.item-11 a {
	background-position: 50% -1025px;
}
*/
#persona ol li a:hover,
#persona ol li a:active,
#persona ol li a:focus,
#persona ol li a.js-selected {
	background-color: #fffbd5;
	cursor: pointer;
	text-decoration: none;
}
#persona ol li a.ui-disabled {
	cursor: default;
}
.persona-inner {
	background: #fffbd5;
}
.persona-group {
	clear: both;
	overflow: hidden;
	/*height: auto !important; in JS*/
}
.persona-item {
	float: left;
	max-width: 98em;
	padding: 30px 0 30px 20px;
}
.persona-item .overview {
	display: table-cell;
	min-width: 23em;
	padding-bottom: 20px;
}
.persona-item .overview p {
	font-size: 140%;
	line-height: 1.3em;
	color: #333;
}
.persona-item .overview .more {
	display: block;
	margin: 25px 0 0 0;
	font-style: normal;
}
.persona-item .overview .more a {
	display: block;
	background: url(../img/icons/icon-white.png) no-repeat scroll 94% 50% #000;
	color: #fff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	font-weight: bold;
	line-height: 1.2em;
	font-size: 120%;
	padding: 6px 25px 6px 10px;
}
.persona-item .overview p .more a {
	font-size: 86%;
}

.persona-item .overview .more a:hover,
.persona-item .overview .more a:active,
.persona-item .overview .more a:focus {
	background-color: #00539F;
	text-decoration: none;
}
.persona-item ul {
	display: table-cell;
	word-spacing: -1em;
	width: 100%;
	margin: 0;
	padding: 0 0 25px 10px;
	list-style: none;
}
.persona-item li * {
	word-spacing: 0;
}
.persona-item li {
	display: inline-block;
	width: 32%;
	margin-left: 2%;
	background: url(../img/bgs/dotted-line-persona.png) 0 100% repeat-x;

}
.persona-item li:nth-child(3n+1) {
	margin-left: 0;
}
.persona-item li a {
	display: block;
	background: url(../img/icons/icon-sprites.png) 5px -788px no-repeat;
	padding: 12px 0 12px 27px;
	margin: 1px 0 2px 0;
	font-size: 140%;
	color: #333;
}
.persona-item li a:hover,
.persona-item li a:focus,
.persona-item li a:active {
	text-decoration: none;
	background: #E3DFBC url(../img/icons/icon-sprites.png) 5px -788px no-repeat;
}
.persona-item .close {
	clear: both;
	float: right;
	background: #d8d5b6 url(../img/icons/icon-sprites.png) 87% -1192px no-repeat;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	padding: 6px 25px 6px 8px;
	font-size: 110%;
	font-weight: bold;
	text-transform: uppercase;
	color: #000;
}
.persona-item .close:hover,
.persona-item .close:active,
.persona-item .close:focus {
	background: #000 url(../img/icons/icon-sprites.png) 87% -1392px no-repeat;
	color: #fff;
	text-decoration: none;
}
/*  ********* Persona-Search ********* */
#persona-search form {
	margin-top: 35px;

}
.js-on #persona-search .button {
	display: none;
}
#persona-search select {
	min-width: 224px;
	padding: 3px;
}
.contact select {
	min-width: 224px;
}
#persona-search select.a11y-hidden,
.contact select.a11y-hidden {
	visibility: hidden;
	height: 0;
}
/* Michael Simon A.A. [2014.09.04]: DropDown Pfeil wurde nicht angezeigt. */
#persona-search .combobox,
.contact .combobox {
	display: inline;
	padding: 0;
	cursor: pointer;
	outline: none;
}
#persona-search .combobox {
	position: relative;
}
#persona-search .combobox input,
.contact .combobox input {
	background: #fff;
	border-right: 1px solid #D9CB4B;
	border-bottom: 1px solid #D9CB4B;
	padding: 4px 30px 4px 8px;
	cursor: pointer;
	font-size: 150%;
	font-style: italic;
	color: #333;
}
#persona-search .combobox input {
	font-size: 100%;
}
.contact .combobox input {
	font-style: normal;
}
/* Michael Simon A.A. [2014.09.04]: DropDown Pfeil wurde nicht angezeigt. */
#persona-search .combobox span,
.contact .combobox span {
	position: absolute;
	right: 4px;
	top: -5px;
	width: 16px;
	height: 25px;
	background: url(../img/icons/icon-sprites.png) 85% -3187px no-repeat;
	font-size: 180%;
	line-height: 1.7em;
	text-indent: -9999em;
}
.contact .combobox span {
	top: 20px;
	right: 18px;
}
.contact .combobox span {
	line-height: 1.1em;
}
.datalist-select-persona,
.datalist-contact {
	position: absolute;
	z-index: 1000;
	left: 0;
	top: 0;
	cursor: default;
}
.datalist-select-persona .datalist-box,
.datalist-contact .datalist-box {
	background: #fff;
	border-right: 1px solid #D9CB4B;
	border-bottom: 1px solid #D9CB4B;
	padding: 4px;
	color: #333;
}
.datalist-contact .datalist-box {
	border: 1px solid #CDCDCD;
}
.datalist-select-persona ul,
.datalist-contact ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.datalist-select-persona li,
.datalist-contact li {
	padding: 2px 4px;
	outline: none;
	cursor: pointer;
	font-size: 150%;
	font-style: italic;
}
.datalist-contact li {
	margin: 0 0 2px 0;
	font-style: normal;
}
.datalist-select-persona li.js-selected,
.datalist-select-persona li.js-checked {
	background: #FFF059;
}
.datalist-contact li.js-selected,
.datalist-contact li.js-checked {
	background: #e1e1e1;
}
/*  *********  Meta Navigation ********* */
#nav-meta li {
	list-style: none;
	font-size: 110%;
}
#nav-meta li a {
	color: #666;
}
/*  ********* Breadcrumb ********* */
#breadcrumb ol {
	margin-top: 25px;
	margin-bottom: 10px;
}
#breadcrumb li {
	float: left;
	list-style: none;
}
#breadcrumb li.home a {
	display: block;
	background: url(../img/icons/icon-sprites.png) 0 -3998px no-repeat;
	height: 15px;
	width: 15px;
	padding-left: 0;
	margin: 0 4px 0 0;
	text-indent: -4000px;
}
#breadcrumb li a,
#breadcrumb li strong {
	font-size: 120%;
	font-weight: normal;
	padding-left: 10px;
	margin-right: 10px;
	background: url(../img/icons/icon-sprites.png) 0 -4197px no-repeat;
}
/*  ********* Filter ********* */
#filter {
	border: 4px solid #fc0;
	overflow: hidden;
}
#filter h2 {
	background: #fc0;
	padding: 12px 0;
	font-size: 140%;
	text-align: center;
	text-transform: uppercase;
	color: #000;
}
#filter .filter-box {
	border-top: 1px solid #fc0;
	padding: 5px;
}
#filter h3 {
	background: url(../img/icons/icon-sprites.png) 0 -3795px no-repeat;
	margin: 0 0 15px 0;
	padding-left: 16px;
	font-size: 140%;
}
#filter h3.collapsed {
	background: url(../img/icons/icon-sprites-3.png) 0 -4996px no-repeat;
	margin-bottom: 0;
}
.js-on #filter h3 {
	cursor: pointer;
}
#filter .entries {
	overflow: hidden;
}
#filter .entries .more,
#filter .entries .less {
	display: block;
	background: url(../img/icons/icon-sprites-3.png) 0 -4400px no-repeat;
	margin: 3px 0 0 0;
	padding: 0 0 0 22px;
/* Michael Simon A.A. [2014.04.11]: Fix damit im IE11 die Plus-Zeichen nicht unten abgeschnitten sind. */
font-size: 130%;
line-height: 130%;
	color: #00529F;
	font-weight: bold;
}
#filter .entries .less {
	background-position: 0 -4600px;
}
#filter .entries .more:hover,
#filter .entries .more:active,
#filter .entries .more:focus,
#filter .entries .less:hover,
#filter .entries .less:active,
#filter .entries .less:focus {
	text-decoration: underline;
	cursor: pointer;
}
#filter .filter-box ul {
	margin: -5px 0 0 0;
	overflow: hidden;
}
#filter .filter-box li,
#filter .filter-box fieldset.date {
	background: url(../img/bgs/border-50.png) 0 0 repeat-x;
	padding: 1px 0 0 0;
}
#filter .filter-box fieldset.date {
	padding-top: 12px;
}
#filter .filter-box li:first-child {
	background: none;
}
#filter .filter-box li a {
	background: url(../img/icons/icon-sprites.png) 0 7px no-repeat;
	padding: 7px 10px 6px 21px;
	color: #00529F;
}
#filter .filter-box li a:hover,
#filter .filter-box li a:active,
#filter .filter-box li a:focus {
	background: #fff url(../img/icons/icon-sprites.png) 0 7px no-repeat;
	text-decoration: underline;
	color: #00529F;
}
#filter .select-filter {
	float: left;
	margin: 0 6px 0 0;
	min-width: 5.1em;
}
.js-on #filter .select-filter {
	min-width: 6.1em;
}
.js-on #filter input[type="submit"] {
	display: none
}
#filter select.select-filter.a11y-hidden{
	position: absolute;
	left: -9999px;
}
#wrapper #filter .date .button {
	background: #e1e1e1 url(../img/icons/icon-sprites-2.png) 6px -144px no-repeat;
	width: 18px;
	border: none;
	margin: 0;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding: 3px 9px;
	text-indent: -999em;
	cursor: pointer;
}
#wrapper #filter .date .button:hover,
#wrapper #filter .date .button:active,
#wrapper #filter .date .button:focus {
	background-color: #BFBFBF;
}
#filter .combobox {
	position: relative;
	display: inline;
	padding: 0;
	cursor: pointer;
	outline: none;
}
#wrapper #filter .combobox input {
	min-width: 44px;
	background: #fff url(../img/icons/icon-sprites-3.png) 100% -3791px no-repeat;
	border: 1px solid #d7d7d7;
	margin: 0 12px 0 0;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding: 4px 24px 4px 6px;
	cursor: pointer;
	font-size: 110%;
	font-weight: bold;
	color: #000;
	text-transform: uppercase;
}
.datalist-select-filter {
	position: absolute;
	z-index: 1000;
	left: 0;
	top: 0;
	margin-top:1px;
	cursor: default;
}
.datalist-select-filter .datalist-box {
	background: #fff;
	border: 1px solid #d7d7d7;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding: 4px;
	color: #000;
}
.datalist-select-filter ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.datalist-select-filter li {
	padding: 2px 4px;
	outline: none;
	cursor: pointer;
	font-size: 110%;
	font-weight: bold;
	text-transform: uppercase;
}
.datalist-select-filter li.js-selected,
.datalist-select-filter li.js-checked {
	background: #FC0;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}
#wrapper #filter input {
	float: left;
	margin: 1px 6px 0 0;
	padding: 0;
}
#wrapper #filter label,
#wrapper #filter strong,
#wrapper #filter a {
	margin-bottom: 5px;
	font-size: 140%;
}
#wrapper #filter label {
	font-weight: normal;
	cursor: pointer;
	padding-left: 21px;
}
#wrapper #filter label.hidden-label {
	display: none;
}
#wrapper #filter a {
	display: block;
}
#wrapper #filter a {
	padding-left: 22px;
	background: url(../img/icons/icon-sprites.png) no-repeat scroll 0 1px transparent;
}
#wrapper #filter label:hover,
#wrapper #filter label:active,
#wrapper #filter label:focus {
	text-decoration: underline;
}
#wrapper #filter a.reset {
	display: block;
	background: url(../img/icons/icon-sprites-3.png) 0 -4800px no-repeat;
	margin: 0 0 10px 0;
	padding: 0 0 0 22px;
	font-size: 140%;
	color: #00529F;
	font-weight: normal;
}
#wrapper #filter .entries strong {
	font-weight: normal;
	background: url(../img/icons/icon_radio.png) no-repeat scroll 0 -100px transparent;
	padding-left: 23px;
}
.ui-helper-hidden-accessible {
	position: absolute;
	left: -999em;
}
span.ui-checkbox {
	display: block;
	background: #fff;
	float: left;
	width: 15px;
	height: 15px;
	margin: 0 6px 0 0;
	padding: 0 !important;
	border: 1px solid #ccc;
	cursor: pointer;
}
/* Michael Simon [2012.12.10]: Beim Hover soll eine Checkbox nicht ausgewaehlt werden, da dies den User verwirrt */
span.ui-checkbox-state-checked,
span.ui-checkbox-state-checked-hover/*,
span.ui-checkbox-hover*/ {
	background: url(../img/icons/icon_checkbox.png) -1px 0 no-repeat;
}
.ui-radio-state-hover,
.ui-checkbox-state-hover,
.ui-radio-state-checked-hover,
.ui-checkbox-state-checked-hover{
	text-decoration: underline;
}
span.ui-radio {
	display: block;
	background: #fff;
	float: left;
	width: 15px;
	height: 15px;
	margin: 0 6px 0 0;
	padding: 0 !important;
	cursor: pointer;
}
span.ui-radio {
	background: url(../img/icons/icon_radio.png) 0 0 no-repeat;
}
span.ui-radio-hover,
span.ui-radio-state-checked {
	background: url(../img/icons/icon_radio.png) 0 -100px no-repeat;
}
label.ui-radio-hover {
	text-decoration: none;
}
span.ui-radio-label {
	display: block;
	font-size: 140%;
	margin-bottom: 10px;
}

/* SECTION-HEADER
############################ */
#section-header h1 {
	display: block;
	margin: 0;
	padding: 30px 0;
	font-size: 480%;
}
p.overview-intro {
	font-size: 140%;
	line-height: 1.4em;
	padding-bottom: 30px;
}
/* MAIN
############################ */
/* MAIN Text-Elements */
h1 {
	color: #262626;
	font-size: 400%;
	font-weight: normal;
	margin-bottom: 38px;
}
.text h2 {
	color: #262626;
	font-weight: normal;
	font-size: 300%;
	padding: 0 0 10px 0;
}
/* Michael Simon A.A. [2014.09.01]: Da die dyn. Formulare wegen 100% Breite nicht floaten, sollte die Überschrift es auch nicht tun. */
.text h2.formheadline {
	clear: both;
}
.text h3,
.text h2.box-heading {
	color: #262626;
	font-weight: normal;
	font-size: 200%;
	padding: 0 0 8px 0;
}
/* Michael Simon [2016.12.15]: In der Webteamrunde entschieden, dass wir kursiv zulassen (Bsp. Namen der biologischen Organismen)
.text em {
	font-style: normal;
}
*/
#main p {
	font-size: 140%;
	color: #333;
	line-height: 140%;
	padding-bottom: 20px;
	/* Michael Simon [2012.11.28]: Fix fuer den Chrome, damit p-Tags hinter blockquote-Tags richtig einruecken und nicht aus der Seite heraus fliessen. */
	margin-top: 1px;
}
#main p.after-iframe {
	padding-top: 6px;
}

#main .text .more-box img.degree-portrait {
	width: 100%;
	border: 1px solid #E6E6E6;
	padding: 1px;
	box-sizing: border-box;
	margin-bottom: 3px;
	float: none;
}
#main .text .more-box .staffmember-image-wrapper img.degree-portrait {
	margin: 0;
}
#main .text .more-box .staffmember-image-wrapper {
	position: relative;
	line-height: 0;
	width: 100%;
	margin: 0 10px 3px 0;
}
#main .text .mb-color-3 .copyright,
#main .text .mb-color-3 .hidden-copyright,
#main .text .mb-color-3 .copyright a,
#main .text .mb-color-3 .hidden-copyright a {
	color: #FFF;
}
#main .text .mb-color-3 .staffmember-image-wrapper span.copyright.symbol {
	font-size: 85.75%;
}
#main .text .mb-color-3 .staffmember-image-wrapper span.hidden-copyright {
	font-size: 80%;
}
/* Michael Simon [2016.11.23]: Fix damit P-Tags in LI-Tags nicht die Liste aufblaehen. */
#main li p {
	padding-bottom: 0;
}

#main td p:last-child {
	padding-bottom: 0;
}
#main p.Zwischenueberschrift {
	color: #262626;
	font-size: 200%;
	font-weight: normal;
	padding: 0 0 8px;
}

#main p.intro {
	font-weight: bold;
	clear: left; /* Michael Simon A.A. [2014.08.21]: Wird benoetigt damit Meldungen mit Bildern und Ansprechpartnern sich fluide verhalten. Da Introtexte eh immer am Anfang sind oder nach einem 100%-breiten Bild kommen, sollte diese Aenderung keine negativen Auswirkungen haben. */
}
#main .article-metadata {
	font-size: 140%;
}

.show-anchor-name {
	display: inline-block;
	width: 16px;
	height: 16px;
	text-indent: -9999px;
	overflow: hidden;
	background: url(../img/icons/icon-sprites.png) 0 -21600px no-repeat;
	vertical-align: middle;
	margin-left: 4px;
	cursor: help;
}

/* Page-Intro */
#main #page-intro {
	padding: 0 0 15px 0;
}
#main #page-intro img {
	width: 100%;
	height: auto;
	padding: 17px 0 13px 0;
}
#main #page-intro .staffmember-image-wrapper img {
	padding: 0;
}
#main #page-intro .staffmember-image-wrapper {
	width: 100%;
	height: auto;
	margin: 17px 0 13px 0;
	position: relative;
	line-height: 0;
}
#main #page-intro p {
	font-size: 160%;
}

/* Hinweistexte */
.hint  {
	padding:  15px 10px;
	color: #fff;
	font-weight: bold;
	text-align: center;
	margin-bottom: 35px;
}
.hint h4 {
	font-size: 140%;
	line-height: 140%;
}
#main .hint h4 > p {
	font-size: 100%;
}
#main .hint h4 > p:last-child {
	padding-bottom: 0;
}
#main .hint.color-1 h4 > p {
	color: #fff;
}
#main .hint.color-1 h4 > p a {
	color: #fff;
}
.color-1 {
	background: url(../img/bgs/pattern-red.gif) repeat 0 0;
}
.color-2 {
	background: url(../img/bgs/pattern-yellow.gif) repeat 100% 0;
	color: #333;
}
.color-3 {
	background: url(../img/bgs/pattern-orange.gif) repeat 0 0;
	color: #000;
}
.color-4 {
	background: #00549F url(../img/icons/under-construction.png) no-repeat 0 50% / 96px 96px;
	padding-left: 98px;
}
.construction #main .hint p {
	color: #FFF;
	font-weight: normal;
	padding-bottom: 0.5em;
}
.construction #main .hint p:last-child {
	padding-bottom: 0;
}
/* Article Meta Data */
.article-metadata {
	margin-top: 60px;
	padding-top: 8px;
	background: url(../img/bgs/border-50.png) repeat-x 0 0;
}
p.to-top-link,
.article-metadata p {
	font-size: 140%;
	color: #333;
	line-height: 140%;
}
.article-metadata .update {
	float: left;
	max-width: 30em;
}
.to-top-link,
.article-metadata .top {
	position: fixed;
	bottom: -48px;
	left: calc(50% - 50px);
	background-color: #FFF;
	border-radius: 5px 5px 0 0;
	padding: 10px 20px;
	border-left: 1px solid #333;
	border-right: 1px solid #333;
	border-top: 1px solid #333;
	z-index: 9999;
	transition: bottom .4s;
}
.to-top-link.show,
.article-metadata .top.show {
	bottom: 0px;
}
.to-top-link a,
.article-metadata .top a {
	padding-left: 20px;
	background: url(../img/icons/icon-sprites.png) no-repeat 0 -600px;
}
/* Article Functions */
.site-navigation-box {
	overflow: hidden;
}
.article-functions {
	float: right;
	max-width: 98em;
	margin-top: -38px;
	margin-bottom: 10px;
	width: 100%;
}
.article-functions .article-functions-box {
	max-width: 98em;
	position: relative;
	overflow: hidden;

}
.article-functions ol {
	width: 66px;
	float: right;
	margin-bottom: 5px;
}
.article-functions ol li {
	float: left;
	list-style: none;
}
.article-functions ol li {
	float: left;
	list-style: none;
}
.article-functions ol li a {
	display: block;
	width: 33px;
	height: 38px;
	background: #f2f2f2;
	text-indent: -4000px;
	cursor: pointer;
}
.article-functions ol a.ui-disabled {
	cursor: default;
	text-decoration: none;
}
.article-functions div.print,
.article-functions div.show-vcard,
.article-functions div.rss {
	float: right;
	margin-left: 4px;
}
.js-off .article-functions div.print {
	display: none;
}
.article-functions div.print a {
	display: block;
	overflow: hidden;
	width: 33px;
	height: 38px;
	background: #f2f2f2;
	text-indent: -4000px;
	font-size: 200%;
	cursor: pointer;
	background: #f2f2f2 url(../img/icons/icon-sprites-1.png) 10px -1587px no-repeat;
}
.article-functions div.print a:hover,
.article-functions div.print a:active,
.article-functions div.print a:focus {
	display: block;
	background: #00549F url(../img/icons/icon-sprites-1.png) 10px -1787px no-repeat;
}
.article-functions div.print a:hover span,
.article-functions div.print a:active span,
.article-functions div.print a:focus span {
	display: block;
	background: none;
}
.article-functions div.rss a {
	display: block;
	overflow: hidden;
	width: 33px;
	height: 38px;
	background: #f2f2f2;
	text-indent: -4000px;
	cursor: pointer;
	background: #f2f2f2 url(../img/icons/icon-sprites.png) 9px -12989px no-repeat;
}
.article-functions div.rss a:hover,
.article-functions div.rss a:active,
.article-functions div.rss a:focus {
	display: block;
	background: #00549F url(../img/icons/icon-sprites.png) 9px -13189px no-repeat;
}
.article-functions div.rss a:hover span,
.article-functions div.rss a:active span,
.article-functions div.rss a:focus span {
	display: block;
	background: none;
}
.article-functions div.show-vcard a {
	display: block;
	overflow: hidden;
	width: 34px;
	height: 38px;
	background: #f2f2f2;
	text-indent: -4000px;
	cursor: pointer;
	background: #f2f2f2 url(../img/icons/icon-sprites.png) 5px -15388px no-repeat;
}
.article-functions div.show-vcard a:hover,
.article-functions div.show-vcard a:active,
.article-functions div.show-vcard a:focus {
	display: block;
	background: #00549F url(../img/icons/icon-sprites.png) 5px -15588px no-repeat;
}
.article-functions div.show-vcard a:hover span,
.article-functions div.show-vcard a:active span,
.article-functions div.show-vcard a:focus span {
	display: block;
	background: none;
}
.article-functions ol li a:hover span,
.article-functions ol li a:active span,
.article-functions ol li a:focus span,
.article-functions ol li a.js-selected span {
	display: block;
	width: 33px;
	height: 42px;
}
.article-functions ol li a:active span,
.article-functions ol li a.js-selected span {
	background: url(../img/bgs/arrow-article-functions.png) 50% 100% no-repeat;
}
.article-functions ol li.send a {
	display: block;
	background: #f2f2f2 url(../img/icons/icon-sprites-1.png) 10px -1987px no-repeat;
}
.article-functions ol li.send a:hover,
.article-functions ol li.send a:active,
.article-functions ol li.send a:focus {
	display: block;
	background: #00549F url(../img/icons/icon-sprites-1.png) 10px -2187px no-repeat;
}
.article-functions ol li.send a:hover span,
.article-functions ol li.send a:active span,
.article-functions ol li.send a:focus span {
	display: block;
	background: none;
}
.article-functions ol li.send a.js-selected,
.article-functions ol li.send a.js-selected:hover {
	display: block;
	background: #000 url(../img/icons/icon-sprites-1.png) 10px -2187px no-repeat;
}
.article-functions ol li.share a {
	display: block;
	background: #f2f2f2 url(../img/icons/icon-sprites-1.png) 10px -2387px no-repeat;
}
.article-functions ol li.share a:hover,
.article-functions ol li.share a:active,
.article-functions ol li.share a:focus {
	display: block;
	background: #00549F url(../img/icons/icon-sprites-1.png) 10px -2587px no-repeat;
}
.article-functions ol li.share a:hover span {
	display: block;
	background: none;
}
.article-functions ol li.share a.js-selected,
.article-functions ol li.share a.js-selected:hover{
	display: block;
	background: #000 url(../img/icons/icon-sprites-1.png) 10px -2587px no-repeat;
}
.article-functions a.close {
	position: absolute;
	top: 25px;
	right: 28px;
	overflow: hidden;
	background: url(../img/icons/icon-sprites-3.png) 0 -2600px no-repeat;
	height: 21px;
	width: 21px;
	text-indent: -4000px;
}
.article-functions a.close:hover {
	text-decoration: none;
	background: url(../img/icons/icon-sprites-3.png) 0 -2800px no-repeat;
}
.js-off .article-functions a.close {
	display: none;
}
.article-functions h3 {
	font-size: 200%;
	color: #2D2D2D;
	margin-bottom: 21px;
	font-weight: normal;
}
.article-functions fieldset {
	border:  none;
}
.article-functions label {
	color: #333;
	font-size: 120%;
	font-weight: bold;
	display: block;
}
.article-functions .form-column-1 {
	float: left;
	width: 260px;
	margin-right: 30px;
	margin-bottom: 30px;
}
.article-functions .form-column-2 {
	float: left;
	width: 635px;
	margin-bottom: 30px;
}
.article-functions input {
	width: 96%;
}
.article-functions textarea {
	width: 98.6%;
	height: 5.7em;
}
.article-functions .section-group {
	overflow: hidden;
	clear: both;
}
.js-off .article-functions .section-group {
	height: 27em;
	overflow: auto;
}
.article-functions .section-wrapper {
	overflow: hidden;
}
.article-functions .section {
	position: relative;
	background: #f2f2f2; /* IE opacity-Bug */
	padding: 20px 27px;
	margin: 0 0 30px;
	width: 92.6em;
	float: left;
}
.article-functions .section ul {
	overflow: hidden;
	background: #fff;
	width: 100%;
	margin-bottom: 20px;
}
.article-functions .section ul li {
	list-style: none;
	border-left: 1px solid #F2F2F2;
	float: left;
	text-align: center;
}
.article-functions .section ul li a {
	display: block;
	padding-top: 75px;
	height: 2.5em;
	margin-top: 18px;
	width: 184px;
	overflow:  hidden;
	color: #333;
	font-size: 140%;
	text-align: center;
	font-weight: bold;
}
.article-functions .section ul li.facebook a {
	background:  url(../img/icons/share-sprites.png) 50% -1000px no-repeat;
}
.article-functions .section ul li.twitter a {
	background:  url(../img/icons/share-sprites.png) 50% -1200px no-repeat;
}
.article-functions .section ul li.xing a {
	background:  url(../img/icons/share-sprites.png) 50% -1400px no-repeat;
}
.article-functions .section ul li.linkedin a {
	background:  url(../img/icons/share-sprites.png) 50% -1600px no-repeat;
}
.article-functions .section ul li.plus a {
	background:  url(../img/icons/share-sprites.png) 50% -1800px no-repeat;
}
.article-functions .section ul li a:hover,
.article-functions .section ul li a:active,
.article-functions .section ul li a:focus {
	text-decoration: none;
	color: #00549F;
}
/* *********
BOXEN IM FLIESSTEXT
********************** */
#main .text .box ul,
#main .text .box ol {
	padding-left: 0;
}

#main .box {
	float: right;
	width: 18.9em;
	clear: right;
	border: 4px solid #BFBFBF;
	margin-left: 20px;
	margin-bottom: 15px;
	padding: 1.2em 1.3em;
}
#main .box-wrapper.dir-2 .box {
	margin-left: 0;
	margin-right: 20px;
}
.two-columne #main .box-wrapper,
.two-columne #main .box-wrapper-mod {
	width: auto;
	padding: 0;
	margin: 0;
}
.two-columne #main .box-wrapper {
	float: right;
	clear: right;
}

/* Michael Simon [2016.10.25]: Mittlere Post-its sind im zweispaltigen Modus nicht gefloatet. */
.two-columne #main .text-block .box-wrapper .box,
.two-columne #main .text-block .box-wrapper-mod .box {
	max-width: 31.7em;
	padding: 12px 13px;
	margin-left: 0;

	width: 100%;
	float: none;
}
/* Michael Simon [2016.10.25]: Mittlere Post-its sind im zweispaltigen Modus nicht gefloatet. */
.csscolumns .two-columne #main .text .mb-middle.more-box {
	max-width: 31.7em;
	padding: 12px 13px;
	margin-left: 0;
	width: 100%;
}
.two-columne #main .box-wrapper .box {
	width: 31.7em;
	float: right;
	max-width: 100%;
	margin-left: 20px;
	margin-bottom: 15px;
}


#main .box h3,
#main .box h2.box-heading {
	color: #000;
	font-size: 150%;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 8px;
	padding-top: 5px;
	margin-top: 0;
}
#main .box-wrapper {
	float: right;
	clear: right;
	width: auto;
}
#main .box-wrapper.dir-2 {
	float: left;
}
#main .box-wrapper.full-size {
	float: none;
}
#main .box-wrapper .box {
	clear: both;
}
#main .box-wrapper.link-next-contact + .box-wrapper .box.contact {
	border-top: 0px;
	margin-top: -26px;
	background-color: white;
}
#main .box-wrapper.link-next-contact + .box-wrapper .awce_edit {
	margin-top: -26px !important;
}
#main .box-wrapper.link-next-contact + .box-wrapper .box.contact .box-heading {
	display: none;
}
*+html #main .box-wrapper {
	float: none;
}
#main .box-wrapper-mod {
	float: right;
}
#main .box-wrapper-mod.dir-2 {
	float: left;
}
#main .box-wrapper.bw-landscape {
	float: none;
}
#main .box-wrapper-mod .box {
	clear: left;
	float: left;
}
#main .box-wrapper-mod .box {
	border: 4px solid #BFBFBF;
	margin-left: 20px;
	margin-right: 0;
}
#main .box-wrapper-mod.dir-2 .box {
	margin-left: 0;
	margin-right: 20px;
}
#main .ordered-list, #main .unordered-list {
	width: 44em;
}
#main .ol-small, #main .ul-small {
	width: 31.7em;
}
#main .ol-large, #main .cl-large, #main .ul-large {
	width: 100%;
}
/* contact */
#main .box .vcard {
	text-align: center;
}
#main .box .vcard,
#main .box .vcard div p.fn,
#main .box .vcard div p.title {
	text-align: center;
}
#main .box .vcard .staffmember-image-wrapper {
	display: inline-block;
	line-height: 0;
	margin: 0 0 15px 0;
	position: relative;
}
#main .box .vcard img {
	padding: 1px;
	border: 1px solid #BFBFBF;
	max-height: 129px;/* todo */
	max-width: 98%; /* todo */

}
#main .box .vcard div.adr {
	padding: 0 0 15px 0;
}
#main .box .vcard div p {
	padding: 0;
	text-align: left;
}
#main .box .vcard .fn span {
	margin: 0 0 3px 0;
	font-weight: bold;
	font-size: 110%; /* 16px */
}
#main .box .vcard .title {
	font-style: italic;
}
/* Michael Simon [2017.02.14]: Damit bei Ansprechpartnerboxen der Abstand auch da ist, wenn keine Telefonnummer da ist. */
#main .box .vcard > div:nth-child(2) > dl.email2,
#main .box .vcard .vcard-inner > div:nth-child(2) > dl.email2,
#main .box .vcard dl.tel,
#main .box .vcard div.extrapad {
	padding-top: 15px;
}

#main .box .vcard dl {
	clear: both;
	overflow: hidden;
	margin: 0 0 8px 0;
	text-align: left;
	width: auto;
	padding: 0;
	background:  none;
}
#main .box .vcard dt {
	float: left;
	width: 23px;
	height: 23px;
	margin: 0 8px 0 0;
	padding: 0;
	text-indent: -9999em;
}
#main .box .vcard dd {
	float: left;
	font-size: 140%;
	line-height: 1.3em;
	padding-right: 0;
	padding-top: 2px;
	width: auto;
}
#main .box .vcard .tel dt {
	background: url(../img/icons/icon-sprites.png) 0 -4400px no-repeat;
}
#main .box .vcard .email dt {
	background: url(../img/icons/icon-sprites.png) 0 -4600px no-repeat;
}
/* Michael Simon A.A. [2014.08.08]: VCARD Kontaktbox email-Klasse am dl-Tag umbenannt, damit Outlook, etc... die richtige E-Mail-Adresse finden. */
#main .box .vcard .email2 dt {
	background: url(../img/icons/icon-sprites.png) 0 -4600px no-repeat;
}
#main .box .vcard dl a {
	color: #00549F;
}
#main .box .vcard dl a.tap-tel {
	color: #000;
}
/* Michael Simon A.A. [2014.08.08]: VCARD Kontaktbox email-Klasse an den a-Tag heran gemacht, damit Outlook, etc... die richtige E-Mail-Adresse finden. */
#main .box .vcard dl a.email {
	background-image: none;
	padding-left: 0;
}
.csscolumns .two-columne #wrapper #main .box .vcard * {
	text-align: left;
}
.csscolumns .two-columne #main .box .vcard .staffmember-image-wrapper {
	float: left;
	margin: 0 15px 0 0;
	min-width: auto;
}
.csscolumns .two-columne #main .box .vcard img {
	padding: 1px;
}
.csscolumns .two-columne #main .box .vcard .vcard-inner {
	float: left;
	width: 100%; /* Michael Simon A.A. [2014.08.21]: Ansprechpartnerboxen in einer zweispaltigen Seite haben sich nicht richtig verhalten. */
}
/* Michael Simon A.A. [2014.08.21]: Ansprechpartnerboxen in einer zweispaltigen Seite haben sich nicht richtig verhalten. */
.csscolumns .two-columne #main .box .vcard div {
	float: left;
	min-width: 55%;
}
.dynamic-table-row .dynamic-table-image-wrapper {
	display: inline-block;
	max-width: 100%;
	line-height: 0;
}
.dynamic-table-row .staffmember-image-wrapper {
	position: relative;
	display: inline-block;
	line-height: 0px;
}
#main .listing .location .staffmember-image-wrapper span.copyright.symbol,
#main .dynamic-table-row .staffmember-image-wrapper span.copyright.symbol {
	right: 0;
	bottom: 0;
	font-size: 85.75%;
}
#main .listing .location .staffmember-image-wrapper .hidden-copyright,
.dynamic-table-row .staffmember-image-wrapper .hidden-copyright {
	bottom: -5px;
	font-size: 78.55%;
}
/* ordered Linklist */
#main .ordered-list,
#main .unordered-list {
	max-width: 44em;
}
#main .ol-small,
#main .ul-small {
	max-width: 31.7em;
}
.two-columne #main .box-wrapper .box.ol-large,
.two-columne #main .box-wrapper .box.ul-large,
.two-columne #main .box-wrapper .box.cl-large,
#main .ol-large,
#main .cl-large,
#main .ul-large {
	width: auto;
	max-width: 100%;
	float: none;
	margin-right: 0;
	margin-left: 0;
	box-sizing: border-box;
}
#main .ordered-list h3,
#main .unordered-list h3 {
	margin-bottom: 15px;
}
#main .ordered-list ol,
#main .unordered-list ul {

	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
}
#main .text .ordered-list ol li,
#main .text .unordered-list ul li {
	list-style:  none;
	padding-bottom: 12px;
	padding-top: 12px;
	background: url(../img/bgs/border-50.png) repeat-x 0 0;
}
#main .text .ordered-list ol li:last-child,
#main .text .unordered-list ul li:last-child {
	list-style:  none;
	padding-bottom: 12px;
	padding-top: 12px;
	background: url(../img/bgs/border-50.png) repeat-x 0 0;
}
#main .text .unordered-list ul li {
	list-style-image: none !important;
}
#main .ordered-list ol li div {
	padding-left: 50px;
	min-height: 2.3em;
	/* background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -2100px; Michael Simon [2018.11.15]: Die Sprites wurden durch SPANs ersetzt und sind somit nicht bis 10 begrenzt. */
}
#main .ordered-list ol li span.list-label {
	display: block;
	float: left;
	width: 30px;
	height: 30px;
	color: white;
	background-color: #333;
	border-radius: 50%;
	font-weight: bold;
	text-align: center;
	line-height: 210%;
	font-size: 105%;
}
#main .unordered-list ul li div {
	padding-left: 18px;
	min-height: 2.1em;
	background: url(../img/icons/list-image.gif) 0 5px no-repeat;
}
#main .ordered-list ol li a,
#main .unordered-list ul li a {
	display: block;
	margin-top: 4px;
	font-size: 100%;
}
/* profile box */
#main .text .profile {
	width: 31.7em !important;
	padding-bottom: 0 !important;
}

/* yellow small more-box */
#main .text .more-box {
	border: none;
	width: 19.7em;
	margin-left: 1.6em;
	background: #FFE57F url(../img/bgs/corner-download.png) 100% 0 no-repeat;
}
.two-columne #main .text .more-box {
	max-width: 100%; /*31.7em;*/
}
#main .text .more-box h3,
#main .text .more-box h2.box-heading {
	text-align: left;
}
#main .text .more-box img {
	float: left;
	margin: 0 10px 0 0;
}
#main .text .more-box ul {
	margin-bottom: 10px;
	float: right;
	width: 100%;
	clear: both;
	padding-left: 0;
	background: url(../img/bgs/border-50.png) repeat-x scroll 0 0 transparent;

}
#main .text .more-box ul li {
	padding: 8px 0 !important;
	list-style: none ;
	list-style-image: none;
	width: 100%;
	clear: both;
	background: url(../img/bgs/border-50.png) repeat-x scroll 0 100% transparent;

}
#main .text .more-box.profile ul li {
	width: 96%;
	padding: 2px 6px !important;
}
.js-off #main .text .more-box ul li {
	float: none;
	width: auto;
}
/* Michael Simon [2012.02.13]: Damit der Link im Post-it auf 2-spaltigen Inhaltsseiten nicht nur 99px breit ist. */
.two-columne #main .text .more-box.mb-middle ul li a {
	width: 220px;
}
.two-columne #main .text .more-box.mb-middle ul li a.external,
.two-columne #main .text .more-box.mb-middle ul li a.intranet,
.two-columne #main .text .more-box.mb-middle ul li a.internal {
	width: 295px;
}
/* Michael Simon [2012.02.13]: Damit der Link im schmalen Post-it nur 99px breit ist. */
#main .text .more-box ul li a.external,
#main .text .more-box ul li a.intranet,
#main .text .more-box ul li a.internal {
	width: 175px; /* todo */
}
#main .text .more-box ul li a,
#main .text .more-box.mb-middle ul li a,
#main .text .more-box.mb-big ul li a {
	color: #000;
	display: inline-block;
}
#main .text .more-box ul li a {
	color: #000;
	display: inline;
}
#main .text .profile ul li a {
	width: auto !important;
}

#main .text .more-box.mb-middle ul li a,
#main .text .more-box.mb-big ul li a {
	display: block;
	width: 100%;
	box-sizing: border-box;
}
#main .text .more-box ul li a em {
	color: #000;
}
#main div.text-image-wrapper.yellow > :not(table) a {
	color: #333;
}
#main .hint.color-1 a {
	color: #fff;
	text-decoration: underline;
}
#main div.text-image-wrapper.yellow > :not(table) a.navigator {
	background: url(../img/icons/icon-sprites.png) no-repeat 0 -15799px;
}
#main div.text-image-wrapper.yellow > :not(table) a.intranet,
#main .hint.color-1 .intranet,
#main .text .more-box ul li a.intranet {
	background: url(../img/icons/icon-sprites-3.png) no-repeat 0 -6399px;
}

#main div.text-image-wrapper.yellow > :not(table) a.internal,
#main .hint.color-1 .internal,
#main .text .more-box dd a.internal,
#main .text .more-box ul li a.internal {
	background: url(../img/icons/icon-sprites-3.png) no-repeat 0 0;
}
#main div.text-image-wrapper.yellow > :not(table) a.external,
#main .hint.color-1 .external,
#main .text .more-box ul li a.external {
	background: url(../img/icons/icon-sprites-3.png) no-repeat 0 -399px;
}
#main div.text-image-wrapper.yellow > :not(table) a.download,
#main .hint.color-1 .download,
#main .text .more-box ul li a.download {
	background: url(../img/icons/icon-sprites-3.png) no-repeat 0 -199px;
}
#main div.text-image-wrapper.yellow > :not(table) a.email,
#main .hint.color-1 .email,
#main .text .more-box ul li a.email {
	background: url(../img/icons/icon-sprites-3.png) no-repeat 0 -999px;
}
/* red small more-box */
#main .text .mb-color-1 {
	background: #D9455B url(../img/bgs/corner-download-color-1.png) 100% 0 no-repeat;
}
#main .text .mb-color-1 * {
	color: #fff;
}
#main .text .mb-color-1 ul {
	background: url(../img/bgs/border-50-w.png) repeat-x 0 100%;
}
#main .text .mb-color-1 ul li a {
	color: #fff;
}
#main .text .mb-color-1 ul li a em {
	color: #fff;
}
#main .text .mb-color-1 ul li {
	background: url(../img/bgs/border-50-w.png) repeat-x 0 0;
}
/* blue download-box */
#main .text .mb-color-2 {
	background: #407FB7 url(../img/bgs/corner-download-color-2.png) 100% 0 no-repeat;
}
#main .text .mb-color-2 * {
	color: #fff;
}
#main .text .mb-color-2 ul {
	background: url(../img/bgs/border-50-w.png) repeat-x 0 100%;
}
#main .text .mb-color-2 ul li a {
	color: #fff;
}
#main .text .mb-color-2 ul li a em {
	color: #fff;
}
#main .text .mb-color-2 ul li {
	background: url(../img/bgs/border-50-w.png) repeat-x 0 0;
}

/* blue download-box */
#main .text .mb-color-3 {
	background: #f2f2f2 url(../img/bgs/corner-download-color-3.png) 100% 0 no-repeat;
}
#main .text .mb-color-3 * {
	color: #000;
}
#main .text .mb-color-3 ul li a {
	color: #000;
}
#main .text .mb-color-3 ul li a em {
	color: #000;
}
#main .text .mb-color-3 ul li {
}
/* yellow big more-information-box */
#main .text .more-box.mb-big {
	float: none;
	overflow: hidden;
	clear: both;
	border: none;
	margin: 0 0 15px;
	width: 100%;
	display: block;
	max-width: 100%;
	background: #FFE57F url(../img/bgs/corner-download.png) 100% 0 no-repeat;
	box-sizing: border-box;
}
#main .text .more-box.mb-big h3 {
	text-align: left;
	padding: 10px 24px 10px 0;
}
#main .text .more-box.mb-big p {
	padding-right: 40px;
}
#main .text .more-box.mb-big p a {
	color: #333;
	border-bottom: 1px dotted;
}
#main .text .more-box.mb-big p a:hover,
#main .text .more-box.mb-big p a:active,
#main .text .more-box.mb-big p a:focus {
	border-bottom: 1px solid;
	text-decoration: none;
}
/* more-box big */
/* blue big more-information-box */
#main .text .mb-big.mb-color-1 {
	background: #407FB7 url(../img/bgs/corner-download-color-2.png) 100% 0 no-repeat;
}
#main .text .mb-big.mb-color-1 * {
	color:#fff;
}
#main .text .mb-big.mb-color-1 p a {
	color: #fff;
	border-bottom: 1px dotted;
}
#main .text .mb-big.mb-color-1 p a:hover,
#main .text .mb-big.mb-color-1 p a:active,
#main .text .mb-big.mb-color-1 p a:focus {
	border-bottom: 1px solid;
	text-decoration: none;
}
/* red big more-information-box */
#main .text .mb-big.mb-color-2 {
	background: url(../img/bgs/corner-download-color-1.png) no-repeat 100% 0 #D9455B;
}
#main .text .mb-big.mb-color-2 * {
	color:#fff;
}
#main .text .mb-big.mb-color-2 p a {
	color: #fff;
	border-bottom: 1px dotted;
}
#main .text .mb-big.mb-color-2 p a:hover,
#main .text .mb-big.mb-color-2 p a:active,
#main .text .mb-big.mb-color-2 p a:focus {
	border-bottom: 1px solid;
	text-decoration: none;
}
/* gray big more-information-box */
#main .text .mb-big.mb-color-3 {
	background: url(../img/bgs/corner-download-color-3.png) no-repeat 100% 0 #f2f2f2;
}
#main .text .mb-big.mb-color-3 * {
	color:#000;
}
#main .text .mb-big.mb-color-3 p a {
	color: #000;
	border-bottom: 1px dotted;
}
/* gray medium more-information-box */
#main .text .mb-medium.mb-color-3 {
	width: 350px;
}
#main .text .mb-medium.mb-color-3 * {
	color:#000;
}
/* Steckbrief */
#main .text .profile {
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
#main .text .profile dl.dl-group {
	background: #fff;
	padding: 2px 6px;
	margin-bottom: 20px;
}
#main .text .profile dl {
	background: #fff;
	border-bottom: 1px solid #f2f2f2;
	padding: 2px 6px;
}

#main .text .profile dl dt {
	float: left;
	width: 48%;
	padding: 4px 2% 4px 0;
	font-weight: bold;
}
#main .text .profile.jobsingleview dl dt {
	width: 32%;
}
#main .text .profile.lpm dl dt {
	width: 25%;
}
#main .text .profile dl dd {
	float: left;
	width: 48%;
	padding: 4px 2% 4px 0;
}
#main .text .profile.jobsingleview dl dd {
	width: 64%;
}
#main .text .profile.lpm dl dd {
	width: 71%;
}
#main .text .profile.jobsingleview dl dd a.email {
	background: url(../img/icons/icon-sprites-3.png) 0 -1000px no-repeat;
}
#main .text .profile h4 {
	font-size: 160%;
	font-weight: normal;
	margin-bottom: 4px;
}
#main .text .profile h4 {
	font-size: 160%;
	font-weight: normal;
	margin-bottom: 4px;
	clear: both;
}
#main .text .profile ul {
	background: #fff;
	padding: 2px 0;
	margin-bottom: 20px;
	float: none;
}
#wrapper #main .text .profile li {
	background: #fff;
	border-bottom: 1px solid #f2f2f2;
	padding: 2px 6px;
	list-style-image: none;
	list-style-position: outside;
	float: none;
}
#wrapper #main .text .profile li:last-child {
	border-bottom: none;
}

#wrapper #main .text .profile li ol,
#wrapper #main .text .profile li ul {
	margin-bottom: 0;
}
#wrapper #main .text .profile li ol li,
#wrapper #main .text .profile li ul li {
	list-style-image: none;
	list-style-position: outside;
	list-style-type: disc;
	margin-left: 16px;
	width: calc(96% - 16px);
	border-bottom: none;
}
#wrapper #main .text .profile li ol li {
	list-style-type: decimal;
}

#wrapper #main .text .profile li span {
	float: left;
	max-width: 18.7em; /* todo */
}
#wrapper #main .text .profile li .more-info,
#wrapper #main .text .profile dd .more-info {
	float: right;
	margin-left: 10px;
	display: block;
	width: 15px;
	height: 18px;
	cursor: pointer;
	text-indent: -4000px;
	background: url(../img/icons/icon-sprites-3.png) 0 -2998px;
}

#wrapper #main .text .profile dd .more-info {
	margin-right: -6px;
}
.js-off #wrapper #main .text .profile li .more-info,
.js-off #wrapper #main .text .profile dd .more-info {
	display: none;
}
#wrapper #main .text .profile li .details {
	display: none;
}
.tooltip-wrapper {
	position: absolute;
	width: 350px;
	padding-top: 10px;
	background: transparent url(../img/icons/tooltip.gif) center top no-repeat;
	top: 0;
}
@media only screen and (max-width: 1290px){
	.tooltip-wrapper {
		background: transparent url(../img/icons/tooltip.gif) right top no-repeat;
	}
	.tooltip-wrapper span.close-button {
		top: 18px;
		right: 10px;
	}
}
.tooltip-wrapper.positionaround-1-0 {
	padding-top: 0px;
	padding-bottom: 10px;
	background-position: center bottom;
}
.tooltip-wrapper span.close-button {
	position: absolute;
	right: 0;
	top: 10px;
	height: 21px;
	width: 21px;
	background: black url(../img/icons/icon-sprites.png) -35px -1395px no-repeat;
	color: white;
	text-indent: -9999em;
	overflow: hidden;
	cursor: pointer;
	display: block;
	font-size: 200%;
}
.tooltip-wrapper span.close-button:hover,
.tooltip-wrapper span.close-button:active,
.tooltip-wrapper span.close-button:focus {
	background-color: #00529f;
}
.tooltip-wrapper.positionaround-1-0 span.close-button {
	top: 0;
}
.tooltip {
	width: 330px;
	background-color: #333;
	color: #fff;
	padding: 10px;
}
.tooltip h3 {
	font-size: 160%;
	margin-bottom: 5px;
}
.tooltip p {
	font-size: 130%;
}
.tooltip p a {
	color: #fff;
	text-decoration: underline;
}
#main .text .profile div.textapply a {
	display: block;
	background: #000;
	margin-bottom: 20px;
	text-align: center;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	color: #fff;
	padding: 5px 0;
	font-size: 120%;
	display: block;
}
#main .text .profile div.textapply a:active,
#main .text .profile div.textapply a:focus,
#main .text .profile div.textapply a:hover {
	background: #00529F;
	text-decoration: none;
}
#main .text .profile div.textapply a span {
	display: block;
	color: #fff;
	cursor: pointer;
	text-transform: uppercase;
	padding-left: 25px;
	font-weight: bold;
}
#main .text .profile div.apply a {
	display: block;
	background: #000;
	margin-bottom: 20px;
	text-align: center;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	color: #fff;
	padding: 5px 0;
	font-size: 120%;
	display: block;
}
#main .text .profile div.apply a:active,
#main .text .profile div.apply a:focus,
#main .text .profile div.apply a:hover {
	background: #00529F;
	text-decoration: none;
}
#main .text .profile div.apply a span {
	display: block;
	text-indent: -9999px;
	color: #fff;
	cursor: pointer;
	text-transform: uppercase;
	padding-left: 25px;
	background: url(../img/icons/icon-sprites-3.png) 0 -3198px no-repeat;;
	font-weight: bold;
}
#wrapper #main .text .profile li .details,
#wrapper #main .text .profile dd .details {
	display: none;
}
.js-off #wrapper #main .text .profile li .details,
.js-off #wrapper #main .text .profile dd .details {
	display: block;
	clear: both;
	padding-top: 10px;
}
.js-off #wrapper #main .text .profile li .details h3,
.js-off #wrapper #main .text .profile dd .details h3 {
	display: none;
}
.js-off #wrapper #main .text .profile li .details p,
.js-off #wrapper #main .text .profile dd .details p {
	font-size: 79%; /* 11px */
	font-style: italic;
}
.js-off #wrapper #main .text .profile dd .details p {
	margin-left: -13.6em; /* todo */
}


/* More Box middle */
/* yellow middle more-box */
#main .text .mb-middle.more-box {
	border: none;
	width: 45em;
	margin-left: 20px;
	background: #FFE57F url(../img/bgs/corner-download.png) 100% 0 no-repeat;
	padding: 12px 13px;
}
#main .text .box-wrapper-mod .mb-middle.more-box  {
	margin-left: 0;
}
#main .text .mb-middle.more-box h3 {
	text-align: left;
	padding: 10px 20px 10px 0;
	text-align: left;
	color: #000000;
	font-size: 160%;
	font-weight: bold;
	margin-bottom: 8px;
	margin-top: 0;
	text-transform: uppercase;
}
#main .text .mb-middle.more-box img {
	float: left;
	margin: 0 10px 0 0;
}
#main .text  .mb-middle.more-box ul,
#main .text .mb-big.more-box ul {
	margin-bottom: 10px;
	background: none;
}
#main .text .mb-middle.more-box ul li,
#main .text .mb-big.more-box ul li {
	padding: 0 !important;
	margin-bottom: 8px;
	list-style: none;
	font-size: 140%;
	line-height: 140%;
	background: none;
}
#main .text .mb-middle.more-box ul li a {
	display: block;
	color: #000;
}
#main .text .mb-middle.more-box p {
	padding-bottom: 15px;
}
/* Michael Simon B.B. [2014.10.09]: Hier den Unterordner img ergaenzt. */
#main .text .mb-middle.more-box ul li a.intranet {
	background: url(../img/icons/icon-sprites-3.png) no-repeat scroll 0 -6398px;
}

#main .text .mb-middle.more-box ul li a.internal {
	background: url(../img/icons/icon-sprites-3.png) no-repeat scroll 0 2px;
}
#main .text .mb-middle.more-box ul li a.download {
	background: url(../img/icons/icon-sprites-3.png) no-repeat scroll 0 -198px;
}
#main .text .mb-middle.more-box ul li a.external {
	background: url(../img/icons/icon-sprites-3.png) no-repeat scroll 0 -398px;
}
#main .text .mb-middle.more-box ul li a.external em {
	background: none;
}

/* red middle more-box */
#main .text .mb-middle.mb-color-1 {
	background: #D9455B url(../img/bgs/corner-download-color-1.png) 100% 0 no-repeat;
}
#main .text  .mb-middle.mb-color-1 * {
	color: #fff;
}
#main .text  .mb-middle.mb-color-1 h3 {
	color: #fff;
}
#main .text  .mb-middle.mb-color-1 ul li a {
	display: block;
	color: #fff;
}

/* blue middle more-box */
#main .text  .mb-middle.mb-color-2 {
	background: #407FB7 url(../img/bgs/corner-download-color-2.png) 100% 0 no-repeat;
}
#main .text  .mb-middle.mb-color-2 * {
	color: #fff;
}
#main .text  .mb-middle.mb-color-2 h3 {
	color: #fff;
}
#main .text  .mb-middle.mb-color-2 ul li a {
	display: block;
	color: #fff;
}

/* gray middle more-box */
#main .text  .mb-middle.mb-color-3 {
	background: #f2f2f2 url(../img/bgs/corner-download-color-3.png) 100% 0 no-repeat;
}
#main .text  .mb-middle.mb-color-3 * {
	color: #000;
}
#main .text  .mb-middle.mb-color-3 h3 {
	color: #000;
}
#main .text  .mb-middle.mb-color-3 ul li a {
	display: block;
	color: #000;
}
/*
middle Box in two-columne template
*/
/* More Box middle */
/* yellow middle more-box */
.csscolumns .two-columne #main .text .mb-middle.more-box {
	max-width: 32.4em;
	margin-left: 20px;
	margin-bottom: 15px;
}

/* Checkliste */
#main .check-list {
	width: 44em;
	padding-bottom: 0;
}
#main .check-list.cl-large {
	width: auto;
}
#main .cl-small {
	max-width: 31.7em
}
#main .check-list h3 {
	margin-bottom: 12px;
}
#main .check-list ol {
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
}
#main .text .check-list ol li {

	list-style:  none;
	padding-bottom: 12px;
	padding-top: 12px;
	background: url(../img/bgs/border-50.png) repeat-x 0 0;
}
#main .check-list ol li a {
	display: block;
	margin-top: 4px;
	font-size: 100%;
}
#main .check-list ol li div {
	padding-left: 50px;
	min-height: 33px;
	background:  url(../img/icons/icon-sprites-1.png) no-repeat 0 -3400px;
}

/* Linkliste in box */
#main .text .linklist ul {
	padding: 0 0 1px 0;
}
#main .text .linklist ul li {
	list-style: none;
	list-style-image: none;
	padding: 2px 0 1px 0;
}
#main .text .linklist {
	padding-bottom: 0;
}

/* two Columne Box */
.further-informations {
	clear: right;
	float: right;
	width: 100%;
	display: block;

	border-top: 4px solid #BFBFBF;
	padding-top: 25px;
	margin-top: 30px;
}
#main .further-informations div ul li p {
	font-size: 100%;
	padding: 0 0 10px 26px;
	margin: 0;
}
.two-column-box {
	margin: 0 0 25px 0;
	overflow: hidden;
}
.two-column-box div {
	width: 43.28%;
	float: left;
	margin: 0 0 0 3.98%;
	padding: 0 1.37%;
	background: #F2F2F2;
}
.two-column-box div.tcb-first {
	margin: 0;
	width: 47.26%;
}
.two-column-box div h3 {
	font-size: 150%;
	font-weight: bold;
	text-transform: uppercase;
	padding: 18px 0 15px 0;
}
#main .two-column-box div ul {
	background: url(../img/bgs/border-50.png) 0 0 repeat-x;
	margin-bottom: 0;
	padding-bottom: 20px;
	padding-left: 0;
	padding-top: 1px;
}
#main .two-column-box div ul li {
	list-style: none;
	list-style-image: none;
	line-height: 1.3em;
	background-position: 0 100%;
	padding: 1px 0 2px 0;
	background: url(../img/bgs/border-50.png) repeat-x scroll 0 100% transparent;
}
#main .two-column-box div ul li a {
	display: block;
	background-position: 5px 8px;
	padding: 8px 2px 8px 26px;
}
#main .two-column-box div ul li a:hover,
#main .two-column-box div ul li a:active,
#main .two-column-box div ul li a:focus {
	background-color: #dcdcdc;
	text-decoration: none;
}

/* one Columne Box */
.one-column-box {
	margin: 0 0 25px 0;
	width: 100%;/*72.9em*/
}
.one-column-box div {
	float: none;
	width: auto;
	margin: 0;
	padding: 0 10px;
	background: #F2F2F2;
}
.one-column-box div h3 {
	font-size: 150%;
	font-weight: bold;
	text-transform: uppercase;
	padding: 18px 0 15px 0;
}
#main .one-column-box div ul {
	background: url(../img/bgs/border-50.png) repeat-x scroll 0 0 transparent;
	margin-bottom: 0;
	padding-bottom: 20px;
	padding-top: 1px;
	padding-left: 0;
}
#main .one-column-box div ul li {
	list-style: none;
	list-style-image: none;
	line-height: 1.3em;
	background-position: 0 100%;
	padding: 1px 0 2px 0;
	background: url(../img/bgs/border-50.png) repeat-x 0 100%

}
#main .one-column-box div ul li a {
	display: block;
	background-position: 5px 9px;
	padding: 8px 2px 8px 26px;
}
#main .one-column-box div ul li a.intranet,
#main .two-column-box div ul li a.intranet {
	background-position: 5px -24792px;
}

#main .one-column-box div ul li a.external,
#main .two-column-box div ul li a.external {
	background-position: 5px -5192px;
}
#main .one-column-box div ul li a.download,
#main .two-column-box div ul li a.download {
	background-position: 5px -20992px;
}

#main .one-column-box div ul li a:hover,
#main .one-column-box div ul li a:active,
#main .one-column-box div ul li a:focus {
	background-color: #dcdcdc;
	text-decoration: none;
}

/* einfache Listen im Fließtext */
#main .text .simple-ct-list ul,
#main .text .simple-ct-list ol {
	margin-left: 20px;
	background: none;
}
.location ol,
#main .text ol {
	padding-left: 23px;
	margin-bottom: 20px;
}
#main .text ol li {
	line-height: 140%;
	list-style-type: decimal;
	font-size: 140%;
	list-style-position: outside;
	padding-bottom: 12px;
	padding-top: 12px;
}
#main .text .simple-ct-list ul li,
#main .text .simple-ct-list ol li {
	line-height: 140%;
	list-style-type: decimal;
	font-size: 140%;
	list-style-position: outside;
	padding-bottom: 10px;
	padding-top: 10px;
	background: none
}
#main .text ul li {
	line-height: 140%;
	list-style: none;
	list-style-image: url(../img/icons/list-image.gif);
	font-size: 140%;
	list-style-position: outside;
	padding-bottom: 12px;
	padding-top: 12px;
}
/* Michael Simon [2016.08.23]: Damit die Suchergebnisse der MBI wie die der GSA aussehen. */
#main .listing.searchpage em {
	font-weight: bold;
	font-style: normal;
}
/* Michael Simon E.E. [2016.02.25]: Damit Zusatzinfoboxen unter Listen die richtige Schriftgroesse haben. */
#main .further-informations.under_listing ul li {
	font-size: 140%;
}
.location ul,
#main .text ul {
	padding-left: 23px;
	margin-bottom: 20px;
}
/* Damit Listen in Listen keinen Abstand innerhalb haben */
#main .text ul ul,
#main .text ul ol,
#main .text ol ul,
#main .text ol ol {
	margin-bottom: 0;
}
#main .text .ordered-list ol,
#main .text .unordered-list ul {
	padding-left: 0;
	margin-bottom: 20px;
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
}
#main .text ul li,
#main .text ol li {
	padding: 4px 0;
}
/* Damit Listen in Listen keinen Abstand innerhalb haben */
#main .text ul li:last-child,
#main .text ol li:last-child {
	padding: 4px 0 0;
}
#main .text .check-list ol li:last-child {
	padding: 12px 0;
}

#main .text ul li a,
#main .text ol li a {
	display: inline;
	font-size: 100%;
}
#main .text .linklist ul li a,
#main .text .linklist ol li a {
	background-position: 5px 9px;
	padding: 8px 2px 8px 26px;
}
#main .text .ct-list ul li a,
#main .text .ct-list ol li a {
	color: #00529F;
}
#main .text .linklist ul li a:hover,
#main .text .linklist ul li a:active,
#main .text .linklist ul li a:focus,
#main .text .linklist ol li a:hover,
#main .text .linklist ol li a:active,
#main .text .linklist ol li a:focus {
	background-color: #EAF1F7;
	text-decoration: none;
}
#main .ct-list ol {
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
}
#main .text .ct-list ol li {
	list-style:  none;
	padding-bottom: 12px;
	padding-top: 12px;
	background: url(../img/bgs/border-50.png) repeat-x 0 0;
}
.two-columne #main .text .ct-list ol li {
	list-style: decimal;
	list-style-position: inside;
}
#main .ct-list ol li div {
	padding-left: 50px;
	min-height: 30px;
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -2100px;
}
#main .ct-list ol li a {
	display: inline;
	margin-top: 4px;
	font-size: 100%;
}
#main .ct-list ol li.item-1 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 0;
}
#main .ct-list ol li.item-2 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -300px;
}
#main .ct-list ol li.item-3 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -600px;
}
#main .ct-list ol li.item-4 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -900px;
}
#main .ct-list ol li.item-5 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -1200px;
}
#main .ct-list ol li.item-6 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -1500px;
}
#main .ct-list ol li.item-7 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -1800px;
}
#main .ct-list ol li.item-8 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -2100px;
}
#main .ct-list ol li.item-9 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -2400px;
}
#main .ct-list ol li.item-10 div {
	background:  url(../img/icons/sprites-zahlen.png) no-repeat 0 -2700px;
}
#main .text .dl-group {
	background: url(../img/bgs/border-50.png) 0 0 repeat-x;
	padding-bottom: 20px;
}
#main .text dl {
	padding: 5px 0;
	overflow: hidden;
	background: url(../img/bgs/border-50.png) 0 100% repeat-x;
}
#main .text dl.www {
	background: url(/custom/rwth/img/bgs/border-50.png) 0 0 repeat-x;
}

#main .text dl dt {
	font-size: 140%;
	line-height: 1.3em;
	max-width: 21.2em;
	padding-right: 1em;
	float: left;
}
#main .text dl dd {
	font-size: 140%;
	line-height: 1.3em;
	max-width: 28em;
	padding-right: 1em;
	float: left;
}

/* Michael Simon [2012.11.28]: Ein genereller Fix fuer alle Elemente, die "float: left" und "clear: left" haben, damit diese nicht mehr unter die Navi rutschen. */
#main div.text {
	display: block;
	/* float: left;
	width: 73em; *//* todo */
}

/* Zitate */
#main blockquote {
	clear: right;
	float: left;
	width: 90.4%; /* Michael Simon C.C. [2015.07.23]: Im IE verursachten die dynamisch breiten Quotes mit Floatelementen ein Problem. */
	margin-right: 0;
	display: block;
	background: url(../img/icons/blockquote.png) 0 40% no-repeat;
	margin-bottom: 20px;
	border-top: 1px dotted #bbb;
	border-bottom: 1px dotted #bbb;
	padding: 10px 0 10px 70px;
	font-family: Georgia, "Times New Roman", serif;
	font-style: italic;
	font-size: 160%;
	line-height: 1.4em;
	color: #191919;
	min-height: 2.2em;
}
/* Michael Simon [2017.02.14]: Damit der Zitatblock oben erscheint und durch zweispaltigkeit nicht durchschnitten wird. */
#main .text-block blockquote {
	background-position: 0 15px;
	width: 80%;
}
/* Michael Simon C.C. [2015.07.02]: Klassen, damit Zitate auch zwangsweise auf verschiedene Groessen gebracht werden koennen. */
#main blockquote.Schmal {
	width: 38%;
	margin-right: 20px;
}
/* Michael Simon [2017.02.14]: Damit man Blockquotes neben 467px Bilder platzieren kann. */
#main blockquote.NebenGrossemBild {
	width: 22%;
	margin-right: 0;
	clear: none;
}
/* Michael Simon [2017.02.14]: Damit man Blockquotes neben 233px Bilder platzieren kann. */
#main blockquote.NebenBild {
	width: 57%;
	margin-right: 0;
	clear: none;
}
#main blockquote p {
	font-style: italic;
}
#main blockquote cite {
	font-size: 75%;
	font-family: arial, helvetica, sans-serif;
	font-style: normal;
	display: block;
	margin-top: 5px;
}
#main blockquote p {
	font-size: 100%;
	padding-bottom: 0;
}

/* video im Fließtext - continuous text Video */
/* video im Fließtext - continuous text Video */
.js-off .videowithjs {
	display: none;
}
.js-on .videonojs {
	display: none;
}

.ct-video {
	max-width:476px;
	margin: 5px 20px 20px 0; /* Michael Simon [2017.07.19]: Anpassung, damit Videos auch unten herum einen guten Abstand haben. */
	float: left;
	width: 100%;
}
/* Michael Simon [2018.04.18]: Variante, damit Videos auch rechtsbuendig sein koennen. */
.ct-video.dir-2 {
	margin: 5px 0 20px 20px;
	float: right;
}
.ct-video.large {
	float: none;
	clear: both;
	max-width: 100%;
	margin: 5px 0 20px 0;
}
/* Michael Simon A.A. [2014.09.03]: Im CE-Modus brach das DIV zusammen, also verzichten wir im CE-Modus auf RD und zeigen es immer 476px breit an. */
.aw_ce_wrapper .ct-video {
	width: 476px;
}
.aw_ce_wrapper .ct-video.large {
	width: 100%;
}
/* Michael Simon A.A. [2013.10.01]: Im Contextedit wird der Topabstand vergroessert, sodass der CE-Button einfacher zu klicken ist. */
.aw_ce .ct-video {
	margin-top: 17px;
}
/*.video-showbox video,
	/*.video-showbox .media-player,*/
.ct-video .media-player {
	max-width: 476px;
}
.ct-video.large .media-player {
	max-width: 100%;
}
.ct-video iframe,
.image-wrapper iframe {
	width: 100% !important;
}
.media-element-box,
.mm-video-box {
	width: 100% !important;
}

.video-showbox.video-showbox .showbox-box {
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	left: 50%;
	right: 50%;
	width: 90%; /* related value in init-basic.js! */
}

.has-media-fullscreen .video-showbox.video-showbox .showbox-box {
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
}


.video-showbox .media-player {
	display: block;
	margin: auto;
}
.video-showbox video,
.video-showbox .media-player {
	height: 320px;
	width: 700px;
}
.showbox-light video {
	height: 100%;
	width: 100%;
}
.showbox-light .media-state {
	left: 0;
	top: 0;
}
.showbox-light .media-state.idle {
	left: 45%;
	top: 40%;
}

.media-player .idle {
	height: 94px;
	width: 94px;
	background: url(../img/bgs/play-video.png) 0 0 no-repeat;
}
.showbox-box .media-player .idle {
	position: absolute;
}
.media-player .fallback-text * {
	color: #fff !important;
	font-size: 12px !important;
}
.media-player .fallback-text a {
	text-decoration: underline !important;
	background: none !important;
	padding: 0 !important;
}
.media-player .fallback-text li {
	list-style-image: none !important;
}
.media-player .fallback-text li a {
	display: block !important;
	background: url(../img/icons/icon-sprites-3.png) no-repeat scroll 0 2px transparent !important;
	padding-left: 20px !important;
	list-style-image: none !important;
}

/* article-date */
.article-data {
	font-size: 140%;
	margin-bottom: 8px;
}
.article-data .social {
	display: inline-block;
	width: 250px;
	font-size: 71.5%;
	margin-left: 16.3em;
}
.article-data .social .fb-like {
	float: left;
}
/*
PHOTOS
---------------------- *
/* photos / images */
img.logolist {
	float: right;
	margin-left: 1.8em;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	max-width: 100%;
}
.photo {
	/* max-width: 476px; */
	float: left;
	/* Michael Simon [2018.04.12]: Clear hinzugefuegt, damit Bilder nicht neben andere Elemente floaten. */
	clear: left;
	display: block;
	margin: 5px 20px 5px 0;
	overflow: hidden;
	display: block;
	padding: 0;
}
/* Michael Simon B.B. [2014.10.13]: Die kleinen Bilder floateten nebeneinander */
.photo.smallimage {
	clear: left;
}
.photo.smallimage.dir-2 {
	clear: right;
}
.csscolumns .two-columne .text-block .photo {
	max-width: 35em;
	margin: 5px 0;
	float: none;
	clear: both;
}

.photo-mod {  /* wenn das Bild am Anfang  der Seite steht */
	margin-right: 0;
	margin-bottom: 13px;
}
.csscolumns .two-columne .landscape {
	max-width: 100%
}

.photo.dir-2 {
	float: right;
	/* Michael Simon [2018.04.12]: Clear hinzugefuegt, damit Bilder nicht neben andere Elemente floaten. */
	clear: right;
	margin: 5px 0 5px 20px;
}
.photo img {
	display: block;
	max-width: 100%;
}
.csscolumns .two-columne .photo img {
	display: block;
	width: 100%;
}
.p-mod {
	float: right;
	margin: 3px 0 10px 10px;
}
.p-block {
	float: none;
	margin: 3px 0 10px 0;
}
span.caption-wrapper {
	display: block;
	background: #fff;
	border:  1px solid #e6e6e6;
	font-size: 120%;
	color: #333;
	padding: 6px 5px;
}
span.image-wrapper {
	display: block;
	position: relative;
}
.image-wrapper .copyright {
	background-color: rgba(0, 0, 0, 0.75);
	bottom: 0;
	color: #FFF;
	font-size: 110%;
	padding: 4px;
	position: absolute;
	right: 0;
}
span.caption-wrapper .caption {
	display: block;
}
span.caption-wrapper .copyright {
	display: block;
	float: right;
	width: 25%;
	padding-left: 5%;
	text-align: right;
	color: #666;
}
/* landscape formats */
span.landscape {
	display: block;
	float: none;
	/* max-width: 74.5em;*/
	overflow: hidden;
	margin: 0 0 20px 0;
}
span.landscape img {
	max-width: 100%;
	width: 100%;
}
img.landscape {
	float: none;
	width: 100%; /*730px*/
	margin-bottom: 20px;
	margin-right: 0;
}

span.landscape-middle { /* landscape  */
	display: block;
	width: 250px;
	overflow: hidden;
}
span.landscape-middle img {
	width: 250px;
}
.landscape-middle img {
	width: 250px;
}
img.landscape-middle {
	width: 250px;
}

span.landscape-small { /* landscape  */
	display: block;
	width: 130px;
}
span.landscape-small img {
	width: 130px;
	overflow: hidden;
}
.landscape-small img {
	width: 130px;
}
img.landscape-small {
	width: 130px;
}
/* portrait format */
span.portrait-big {
	display: block;
	width: 275px;
}
span.portrait-big img {
	width: 275px;
}
.portrait-big img {
	width: 275px;
}
img.portrait-big {
	width: 275px;
}
span.portrait-middle { /* landscape  */
	display: block;
	width: 155px;
}
span.portrait-middle img {
	width: 155px;
}
.portrait-middle img {
	width: 155px;
}
img.portrait-middle {
	width: 155px;
}
span.portrait-small { /* landscape  */
	display: block;
	width: 98px;
}
span.portrait-small img {
	width: 98px;
}
.portrait-small img {
	width: 98px;
}
img.portrait-small {
	width: 98px;
}
#main span.caption,
#main span.copyright,
.showbox .multimedia-box .copyright {
	font-size: 110%;
}
#main p span.caption,
#main p span.copyright {
	font-size: 100%;
}
#main span.copyright a,
#main span.hidden-copyright a {
	color: #fff;
}
/* Tabellen */
/* Michael Simon A.A. [2013.11.05]: Fix, weil ploetzlich - kA warum! - b/strong in Tabellenkoepfen zu doppelt-Bold (900) fuehrte aber nur 700 (normal bold) erwuenscht war. */
#main table th strong,
#main table th b {
font-weight: 700;
}
#main table {
	float: left;
	margin: 0 20px 18px 0;
	padding: 0;
	font-weight: normal;
	empty-cells: show;
	border-collapse: collapse;
/*	width: 100% !important; Michael Simon A.A. [2014.10.06]: Tabellen sollten nicht immer 100% breit sein. Weniger responsive / mehr Freiheit für Redakteur */
}

/*
#main table + a.hiddenmark + table {
	margin-top: 20px;
}
*/

#main table caption {
	text-align: left;
	font-size: 200%;
	padding: 0 0 8px 0;
}
#main th,
#main td {
	font-size: 140%;
	background-color: #fff;
	padding: 12px 10px;
	vertical-align: top;
	text-align: left;
	word-wrap: break-word;
}

/* Michael Simon [2016.11.28]: Damit die Termintabellen nicht so drastisch in die Hoehe gehen. */
#main .antrago th,
#main .antrago td {
	padding: 8px;
}

#main th p {
	color: #fff;
	font-size: 100%;
	padding-bottom: 0;
}
/* Michael Simon [2012.05.07]: Entfernt, da dadurch SPANs in THs unsichtbar werden. Da der Editor aber manchmal SPANs dort plaziert, ist das doof.
#main th span {
float: right;
margin: 5px 0 0 0;
display: block;
width: 11px;
height: 7px;
text-indent: -4000px;
} */
#main th em {
	float: left;
	margin: 5px 0 0 0;
}
#main th span.sort-up {
	background: url(../img/icons/icon-sprites-1.png) 0 -3200px;
}
#main th span.sort-down {
	background: url(../img/icons/icon-sprites-1.png) 0 -3000px;
}
#main th {
	/* Michael Simon [2016.04.19]: Damit auch Kopfspalten schwarze Trennlinien (horizontal) haben. */
	border: 1px solid #303030;
	background-color: #595959;
	color: #fff;
}
#main td {
	border: 1px solid #E5E5E5;
}

/* Michael Simon [2018.04.19]: Da wir IE8 nicht mehr unterstuetzen muessen, koennen wir die odd Kruecke beenden */
#main .text tr:nth-child(2n + 1) td {
	background: #FFF;
}
#main .text tr:nth-child(2n) td {
	background: #F5F5F5;
}
#main .text td p {
	font-size: 100%;
}
#main .text tr:hover td {
	background: #EAF1F7;
}
#main table th a.sort {
	float: left;
	padding-right: 10px;
}

/* Two Column Layout */
.csscolumns .two-columne .text-block {
	max-width: 72.9em;
	-moz-column-count:2;
	-moz-column-gap: 29px;
	-webkit-column-count:2;
	-webkit-column-gap: 29px;
	column-count: 2;
	column-gap: 29px;
	clear: both;
	padding: 0 0 20px 0;
	margin-bottom: 20px;
	background:  url(../img/bgs/border-50.png) repeat-x 0 100%;
}

.csscolumns .two-columne .text-block:last-child {
	background: none;
}
.text-block .more {
	font-style: normal;
	background: none;
}
.text-block .more a {
	background: url(../img/icons/icon-sprites.png) 0 0 no-repeat;
	padding-left: 20px;
}
.csscolumns .two-columne #main h2 {
	clear: none;
}
.csscolumns .two-columne .text-block {
	overflow: hidden;
}

/* Teaser
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* Latest (Veranstaltungen und News) */
.nav-col #main .teaser-row .latest:first-child {
	width: 50%;
	padding-left: 0;
}
.nav-col #main .teaser-row .latest {
	/*	width: 47.8%;
		display: inline-block;
		padding-left: 2%*/
}
.latest {
	float: left;
	width: 25.6%;
	padding: 0;
	min-height: 27em;
	padding-bottom: 3.5em;
	position: relative;
}
.col #main .teaser-row.t-col-3 .latest,
.col #main .teaser-row.t-col-3 .teaser {

}
/*.col #main .teaser-row.t-col-3 .latest:first-child,
.col #main .teaser-row.t-col-3 .teaser:first-child {
	width: 22.6%;
}*/
.latest h3,
.latest ul {
	/*margin-right: 5.49%;*/
}
.faculty .latest {
	min-height: 25em;

}

#home .latest {
	float: left;
	display: block;
}
.latest.news {
	width: 25.6%;
	/*	border-right: 1px solid #d9d9d9;
		border-left: 1px solid #d9d9d9;*/

}
.col .t-col-3 .latest.news {
	border-right: 0;
}
.latest.events {
	margin-right: 0;
	/*border-left: 1px solid #d9d9d9;
	padding-left: 1.3em;  todo */
}
.latest.events .teaser-inner {
	background-image: url(../img/bgs/line-color-teaser.png);
	background-position: 0 0;
	background-repeat: repeat-y;
	padding: 0 0 0 27px;
}

#home .latest.news h3 {
	/*	margin-right: 5.52%;
		margin-left: 5.9%;*/
}
#home .latest.news ul {
	/*	margin-right: 5.52%;
		margin-left: 5.9%;*/

}
#home .latest.news {
	margin-right: 0;
	float: left;
	display: block;
}

.latest:first-child {
	margin: 0;
	border-left: 0 hidden #d9d9d9;
	padding-left: 0;
}
.latest h3 {
	padding: 0 0 20px 0;
	font-size: 160%;
	font-weight: bold;
	text-transform: uppercase;
}
.latest h3 a {
	color: #333;
}
.latest ul {
	overflow: hidden;
}
.latest li {
	background: url(../img/bgs/dotted-line-persona.png) 0 0 repeat-x;
	overflow: hidden;
	padding: 12px 0;
	list-style: none;
	font-size: 100%;
	min-height: 6.5em;
}
.latest li.all {
	/*position: absolute;*/
	/*left: 15px;
	right: 5.49%;
	bottom: 0;*/
	padding: 10px 0 0 0;
	min-height: 0;
}
.latest.news li.all {
	position: static;
	padding: 10px 0 0 0;
	min-height: 0;
}
/* Michael Simon B.B. [2014.11.21]: Die Orgainfobox auch auf Fakultaets-Startseiten gestylt. */
.division .orgainfo,
.faculty .orgainfo {
	min-height: 16em;
	/*width: 47.2em;*/
}
.division #main .teaser-row .orgainfo .teaser-inner,
.faculty #main .teaser-row .orgainfo .teaser-inner {
	border: 4px solid lightgray;
	padding: 0.5em;
	background-image: none;
	margin-left: 27px;
}
.division #main .teaser-row .orgainfo:first-child .teaser-inner,
.faculty #main .teaser-row .orgainfo:first-child .teaser-inner {
	margin-left: 0;
}
.division .orgainfo h3,
.division .orgainfo h2.box-heading,
.faculty .orgainfo h3,
.faculty .orgainfo h2.box-heading {
	font-size: 160%;
	padding-bottom: 6px;
	font-weight: bold;
	text-transform: uppercase;
}
.division .orgainfo img,
.faculty .orgainfo img {
	margin: 0;
	padding: 0;
	width: 100%;
}
.division .orgainfo .staffmember-image-wrapper,
.faculty .orgainfo .staffmember-image-wrapper {
	position: relative;
	margin-right: 1.5em;
	float: left;
	width: 100%;
	line-height: 0;
}
.division .orgainfo.smaller .staffmember-image-wrapper,
.faculty .orgainfo.smaller .staffmember-image-wrapper {
	float: none;
	margin-bottom: 0.3em;
	margin-top: 0.5em;
}
.division .orgainfo .staffmember-image-wrapper .copyright,
.faculty .orgainfo .staffmember-image-wrapper .copyright {
	right: 0px;
	bottom: 0px;
}
.division .orgainfo .staffmember-image-wrapper .hidden-copyright,
.faculty .orgainfo .staffmember-image-wrapper .hidden-copyright {
	right: 0px;
	bottom: -5px;
}
.orga-gps-link {
	width: 16px;
	height: 16px;
	display: inline-block;
	overflow: hidden;
}
.division .orgainfo img.gps,
.faculty .orgainfo img.gps,
.division .orgainfo.smaller img.gps,
.faculty .orgainfo.smaller img.gps,
.orgainfo .icon.gps {
	background: url(/custom/rwth/img/table/gps16.png) 0 0 no-repeat;
	width: 16px;
	height: 16px;
	margin: -4px 0 0 0;
	padding: 0;
	vertical-align: middle;
	float: none;
	display: inline-block;
	text-indent: -9999px;
}
.division .orgainfo p,
.faculty .orgainfo p {
	display: block;
	font-size: 140%;
	line-height: 140%;
}
.division .orgainfo .leader,
.faculty .orgainfo .leader {
	margin-top: -0.2em;
}
.division .orgainfo .leader,
.faculty .orgainfo .leader,
.division .orgainfo .place,
.faculty .orgainfo .place {
	padding-bottom: 0.3em !important;
}
.division .orgainfo.smaller .leader,
.faculty .orgainfo.smaller .leader,
.division .orgainfo.smaller .place,
.faculty .orgainfo.smaller .place {
	padding-bottom: 0.7em !important;
}
.latest.events span.date {
	display: inline-block;
	float: left;
	width: 3.9em; /* 55px */
	min-height: 45px;
	border: 2px solid #ccc;
	padding: 0 0 0 0;
	text-align: center;
	vertical-align: middle;
}
.latest.events span.date em {
	font-weight: normal;
	font-size: 100%;
}
.latest.events h2 {
	float: right;
}
#main .latest li h2 {
	font-size: 140%;
	font-weight: normal;
	line-height: 1.3em;
	padding-bottom: 0;
	text-transform: none;
	border-top: 0;
	padding-top: 0;
}
/* Michael Simon B.B. [2014.10.12]: Damit bei den Teasern auch Eintraege moeglich sind >200px ohne dass das naechste Icon mit angezeigt wird. */
#main .latest li h2:not(.no-icon):before {
	background: url("/custom/rwth/img/icons/icon-sprites.png") no-repeat scroll 0 3px rgba(0, 0, 0, 0);
	content: "";
	float: left;
	height: 20px;
	width: 20px;
}
#main .latest li h2 a {
	display: block;
/*	background: url(../img/icons/icon-sprites.png) 0 3px no-repeat; Michael Simon B.B. [2014.10.12]: Damit bei den Teasern auch Eintraege moeglich sind >200px ohne dass das naechste Icon mit angezeigt wird. */
	padding-left: 20px;
	line-height: 1.5em;
	font-size: 100%;
	color: #00529F;
}
.latest.events h2 {
	float: left;
	max-width: 71%;
}
.latest.events h2 a {
	word-wrap: break-word;
}
.latest.events span.date {
	display: inline-block;
	float: left;
	min-height: 45px;
	margin-right: 10px;
	border: 2px solid #ccc;
	padding: 0;
	text-align: center;
	vertical-align: middle;
	width: 3.9em;
}
.latest.events span.date em {
	font-size: 100%;
}
.latest li span.date {
	font-size: 120%;
	line-height: 1.3em;
}
.latest li em {
	font-size: 120%;
	text-transform: uppercase;
	font-style: normal;
	font-weight: normal;
}
.latest li em a {
	display: block;
	background: #e1e1e1 url(../img/icons/icon-sprites.png) 94% -2993px no-repeat;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 6px 25px 6px 10px;
	font-weight: bold;
	color: #000;
}
.latest li em a:hover,
.latest li em a:active,
.latest li em a:focus {
	text-decoration: none;
	color: #fff;
	background:#00549F url(../img/icons/icon-sprites.png) 94% -2793px no-repeat;
}
.latest.news span.date {
	display: block;
	padding: 0 0 5px 0;
}
.latest.news h2 a {
	display: block;
	background: url(../img/icons/icon-sprites.png) 0 3px no-repeat;
	padding-left: 20px;
	line-height: 1.5em;
}
/* List-Teaser Faculty Home */
/* todo Änderungen RWTH */

#home #main .list-teaser-group h3 {
	font-size: 160%;
	font-weight: bold;
	padding: 0 0 20px;
	text-transform: uppercase;
}
#home #main .list-teaser-group h3 a {
	color: #333;
}
#home #main .list-teaser-group ul {
	list-style: none;
	background: url(../img/bgs/dotted-line-persona.png) repeat-x 0 0;
	padding: 1px 0 0 0;
}
#home #main .list-teaser-group ul li {
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
	padding: 1px 0 2px 0;
}
#home #main .list-teaser-group ul li.all {
	background: none;
	padding: 0;
}
#home #main .list-teaser-group h2 {
	font-size: 140%;
	text-transform: none;
	padding: 0;
	margin: 0;
}
#home #main .list-teaser-group h2 {
	border-top: none;
}
#home #main .list-teaser-group h2 a {
	background: url(../img/icons/icon-sprites.png) no-repeat scroll 5px 8px;
	display: block;
	padding-left: 25px;
	padding-top: 5px;
	padding-bottom: 5px;
	color: #00529F;
	font-weight: normal;
	line-height: 1.5em;
}
#home #main .list-teaser-group h2 a:hover,
#home #main .list-teaser-group h2 a:focus,
#home #main .list-teaser-group h2 a:active {
	background-color: #EAF1F7;
	text-decoration: none;
}

#main .list-teaser-group li.all {
	float: none;
	margin-top: 10px;
	font-size: 110%;
	text-transform: uppercase;
	font-style: normal;
	font-weight: bold;
	display: block;
	background: none;

}
#main .list-teaser-group li.all a {
	display: block;
	background: #e1e1e1 url(../img/icons/icon-sprites.png) 94% -2993px no-repeat;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 6px 25px 6px 10px;
	font-weight: bold;
	color: #000;
	font-size: 110%;
}
#main .list-teaser-group li.all a:hover,
#main .list-teaser-group li.all a:active,
#main .list-teaser-group li.all a:focus {
	background:#00549F url(../img/icons/icon-sprites.png) 94% -2793px no-repeat;
	color: #fff;
	text-decoration: none;
}

/* Teasers (on Homepage) */
.startpage #main .teaser-row .teaser.grid-2.h-color-1,
.startpage #main .teaser-row .teaser.grid-2.h-color-2,
.startpage #main .teaser-row .teaser.grid-2.h-color-3 {
	float: right !important;
	display: block;
	margin: 0;
	padding: 0 0 10px;
}

/* Teasers in Teaser-Row */
.teaser-row {
	display: table;
	width: 100%;
	word-spacing: -1em;
	/* overflow: hidden; */
	margin: 0 0 40px 0;
}
.teaser-row::after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	clear: both;
}
.teaser-row * {
	word-spacing: 0;
}
.col .teaser-row.mod,
.teaser-row.mod,
.teaser-row .teaser-group,
.teaser-row .teaser-wrapper {
	border-top: 4px solid #bfbfbf;
	padding-top: 25px;
}
.teaser-row.first-child.mod,
.teaser-row.first-child .teaser-group,
.teaser-row.first-child .teaser-wrapper{
	border-top: none;
	padding-top: 7px;
}

.teaser-row .teaser-group {
	position: relative;
	display: table;
	margin-bottom: 20px;
	width: 100%;
	word-spacing: -1em;
}

.teaser-row .teaser-group * {
	word-spacing: 0;
}
.teaser-row .teaser-wrapper {
	max-width: 98em;
	/* width: 100%;*/
}
.teaser-row .teaser-wrapper .rack-design{
	width: 10000% !important /* Michael Simon C.C. [2015.07.01]: Damit auch Teasergruppen mit mehr als 40 Teasern moeglich sind (Sinn wird nicht hinterfragt...) */
}
.teaser-row .teaser-group h5,
.teaser-row .teaser-wrapper h5,
.teaser-row .teaser-wrapper .group-headline {
	font-size: 160%;
	margin: -10px 0 15px 0;
	text-transform: uppercase;
	padding-top: 2px;
	font-weight: bold;
	border-top: 0px hidden transparent;
}
.teaser-row .teaser-group h5 a,
.teaser-row .teaser-wrapper h5 a,
.teaser-row .teaser-wrapper .group-headline a {
	color: #333;
}

.col #main .teaser-row.t-col-3 .teaser:first-child,
.col #main .teaser-row.t-col-3.orgainfo:first-child,
.col #main .teaser-row.t-col-3 .latest:first-child,
.col #main .teaser-row.t-col-3 .events:first-child,
/*.col #main .teaser-row.t-col-3 .list-teaser-group:first-child,*/
#home.col #main .teaser-row.t-col-3 .teaser:first-child,
#home.col #main .teaser-row.t-col-3.orgainfo:first-child,
#home.col #main .teaser-row.t-col-3 .latest:first-child,
#home.col #main .teaser-row.t-col-3 .events:first-child/*,
#home.col #main .teaser-row.t-col-3 .list-teaser-group:first-child*/ {
	display: inline-block;
	width: 22.858%;
	float: none;
	vertical-align: top;
}
.col #main .teaser-row.t-col-3 .teaser,
.col #main .teaser-row.t-col-3.orgainfo,
.col #main .teaser-row.t-col-3 .latest,
.col #main .teaser-row.t-col-3 .events/*,
.col #main .teaser-row.t-col-3 .list-teaser-group*/ {
	display: inline-block;
	width: 25.511%;
	float: none;
	vertical-align: top;
}

#main .teaser-row .teaser,
#main .teaser-row .orgainfo,
#main .teaser-row .latest,
#main .teaser-row .events,

#home.faculty #main .teaser-row .teaser,
#home.faculty #main .teaser-row .orgainfo,
#home.faculty #main .teaser-row .latest,
#home.faculty #main .teaser-row .events {
	display: inline-block;
	width: 25.613%;
	float: none;
	vertical-align: top;
}
#home #main .teaser-row .latest,
#home #main .teaser-row .events {
	float: left;
	display: block;
}


#main .teaser-row .teaser:first-child,
#main .teaser-row .orgainfo:first-child,
#main .teaser-row .latest:first-child,
#main .teaser-row .events:first-child,



#main .teaser-row .teaser.first,
#main .teaser-row .orgainfo.first,
#main .teaser-row .latest.first,
#main .teaser-row .events.first,
#main .teaser-row .list-teaser-group.first,

#home.faculty #main .teaser-row .teaser.first,
#home.faculty #main .teaser-row .orgainfo.first,
#home.faculty #main .teaser-row .latest.first,
#home.faculty #main .teaser-row .events.first,
#home.faculty #main .teaser-row .list-teaser-group.first,

/* Michael Simon D.D. [2015.12.17]: Damit auf Startseiten auch Zeilen mit nur 2 Teasern korrekt gerendert werden. */
#home.faculty #main .teaser-row .teaser:first-child,
#home.faculty #main .teaser-row .orgainfo:first-child,
#home.faculty #main .teaser-row .latest:first-child,
#home.faculty #main .teaser-row .events:first-child {
	width: 22.858%;
}
/* Michael Simon C.C. [2015.03.31]: Weitere Teaser hatten an bestimmten Positionen eine nicht korrekte Breite.
#main .teaser-row.t-col-3 .list-teaser-group,
#home.faculty #main .teaser-row.t-col-3 .list-teaser-group {
	width: 48.47%;
}
*/
#main .teaser-row.t-col-3 .list-teaser-group:first-child,
#home.faculty #main .teaser-row.t-col-3 .list-teaser-group:first-child {
	width: 48.47%;
}
.nav-col #main .teaser-row .teaser,
.nav-col #main .teaser-row .orgainfo,
.nav-col #main .teaser-row .latest,
.nav-col #main .teaser-row .events,

.single-page #main .teaser-row .teaser,
.single-page #main .teaser-row .orgainfo,
.single-page #main .teaser-row .latest,
.single-page #main .teaser-row .events {
	display: inline-block;
	width: 34.384%;
	float: none;
	vertical-align: top;
}
.nav-col #main .teaser-row .teaser:first-child,
.nav-col #main .teaser-row .orgainfo:first-child,
.nav-col #main .teaser-row .latest:first-child,
.nav-col #main .teaser-row .events:first-child,


.single-page #main .teaser-row .teaser:first-child,
.single-page #main .teaser-row .orgainfo:first-child,
.single-page #main .teaser-row .latest:first-child,
.single-page #main .teaser-row .events:first-child,

.nav-col #main .teaser-row .teaser.first,
.nav-col #main .teaser-row .orgainfo.first,
.nav-col #main .teaser-row .latest.first,
.nav-col #main .teaser-row .events.first,


.single-page #main .teaser-row .teaser.first,
.single-page #main .teaser-row .orgainfo.first,
.single-page #main .teaser-row .latest.first,
.single-page #main .teaser-row .events.first {
	display: inline-block;
	width: 30.685%;
	float: none;
	vertical-align: top;
}

#main .teaser-row .teaser.grid-3:first-child,
#home.faculty #main .teaser-row .teaser.grid-3:first-child {
	width: 74.387%;
}
#main .teaser-row .teaser.grid-3,
#home.faculty #main .teaser-row .teaser.grid-3 {
	width: 77.142%;
}
.nav-col #main .teaser-row .teaser.grid-3,
.single-page #main .teaser-row .teaser.grid-3 {
	width: 99.453%;
}
#main .teaser-row .teaser.grid-2:first-child,
#home.faculty #main .teaser-row .teaser.grid-2:first-child {
	width: 48.368%;
}
#main .teaser-row .teaser.grid-2,
#home.faculty #main .teaser-row .teaser.grid-2,
#home.faculty #main .teaser-row .list-teaser-group {
	width: 51.327%;
}
.col #main .teaser-row.t-col-3 .teaser.grid-2,
#home.col #main .teaser-row.t-col-3 .teaser.grid-2 {
	width: 51.225%;
}
#home.faculty.col #main .teaser-row.t-col-3 .teaser.grid-2:first-child, /* Michael Simon C.C. [2015.03.31]: Weitere Teaser hatten an bestimmten Positionen eine nicht korrekte Breite. */
.col #main .teaser-row.t-col-3 .teaser.grid-2:first-child,
#home.col #main .teaser-row.t-col-3 .teaser.grid-2:first-child { /* Michael Simon B.B. [2015.03.04]: Ein breiter Bild-Text-Teaser an erster Position wurde zu breit angezeigt. */
	width: 48.368%;	/* Michael Simon A.A. [2014.08.19]: Ein zweispaltiger Teaser auf einer Fakultätsstartseite wurde zu breit dargestellt. */
}
#main .teaser-row .list-teaser-group {
	width: 51.327%;
	display: inline-block;
	float: none;
	vertical-align: top;
}
.singel-page #main .teaser-row .list-teaser-group,
.nav-col #main .teaser-row .list-teaser-group {
	width: 69.18%;
}
.singel-page #main .teaser-row .list-teaser-group:first-child,
.nav-col #main .teaser-row .list-teaser-group:first-child {
	width: 65.48%;
}
#main .teaser-row .list-teaser-group:first-child {
	width: 48.368%;
}
#main .list-teaser-group h3 {
	font-size: 160%;
	font-weight: bold;
	padding: 0 0 20px;
	text-transform: uppercase;
}
#main .teaser-row .list-teaser-group p {
	padding-bottom: 10px;
}
#main .list-teaser-group ul {
	list-style: none;
	background: url(../img/bgs/dotted-line-persona.png) repeat-x 0 0;
	padding: 1px 0 0 0;
}
#main .list-teaser-group ul li {
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
	padding: 1px 0 2px 0;
}
#main .list-teaser-group h2 {
	border-top: none;
}
#main .list-teaser-group h2 {
	font-size: 140%;
	text-transform: none;
	padding: 0;
	margin: 0;
}
#main .list-teaser-group h2 a {
	background: url(../img/icons/icon-sprites.png) no-repeat scroll 5px 8px;
	display: block;
	padding-left: 25px;
	padding-top: 5px;
	padding-bottom: 5px;
	color: #00529F;
	font-weight: normal;
	line-height: 1.5em;
}



.nav-col #main .teaser-row .teaser.grid-2,
.single-page #main .teaser-row .teaser.grid-2 {
	width: 69.18%;
}
.nav-col #main .teaser-row .teaser.grid-2:first-child,
.single-page #main .teaser-row .teaser.grid-2:first-child {
	width: 65.48%;
}


#main .teaser-row .teaser.grid-2 img {
	width: auto;
}
#main .teaser-row .rack-design .teaser,
#main .teaser-row .rack-design .teaser:first-child {
	display: block;
	float: left;
	min-width: 22.4em !important;
	width: 22.4em !important;
	/*width: 22.8em;*/
	/*min-width: 0;*/
	/*margin-left: 28px;*/
	margin-right: 28px;
	padding-left: 0;
	padding-right: 0;
	border-left: none;
}
#main .teaser-row .rack-design .teaser img {
	width: 100%;
}
#main .teaser-row .rack-design .teaser:first-child {
	margin-left: 0;
}

.js-off .teaser-row .teaser-wrapper .rack-design .teaser {
	margin-bottom: 30px;
}
.teaser-row .teaser-wrapper .rack-design .teaser:first-child {
	margin-left: 0;
}
.teaser-row .teaser-wrapper .rack-design .teaser p {
	margin-top: 5px;
}
.teaser-row .teaser-wrapper .rack-design .mod p {
	margin-top: 0;
}
.teaser-row .teaser-wrapper .rack-design .teaser.last-teaser {
	margin-right: 0;
	border-left: none;
	padding-right: 0;
}
.teaser-row .teaser .col-1 {
	display: table-cell;
	width: 100%;
	padding-right: 20px;
	vertical-align: top;
}
.teaser-row .teaser.teaser-list .col-1 img {
	float: none;
}
#main .teaser-row .teaser .col-1 p {
	padding-right: 20px;
}
.teaser-row .teaser .col-2 {
	display: table-cell;
	vertical-align: top;
	width: 100%;
	padding-top: 3px;
}
.teaser-row .teaser .col-1 p,
.teaser-row .teaser .col-2 p {
	margin-top: 5px;
}
#main .teaser-row .ts-box {

	padding: 0 0 20px 0;
}
#main .teaser-row .h-color-3 .ts-box {

}
#main .teaser-row .ts-box * {
	word-spacing: 0;
}
#main .teaser-row .teaser-list .links {
	width: 21em;
}
/* Michael Simon B.B. [2015.01.09]: Auch Teaser Meldungen und Veranstaltungen sollten in der Zeile 2+ einen grauen Balken oben kriegen. */
.teaser-row.first-child h2,
.teaser-row.first-child .latest h3,
.teaser-row.first-child .h-color-1 h2,
.teaser-row.first-child .h-color-2 h2,
.teaser-row.first-child .h-color-3 h2 {
	border-top: 0;
	padding-top: 0;
}
#search-results .teaser-row h2,
#search-results .teaser-row .h-color-1 h2,
#search-results .teaser-ro .h-color-2 h2,
#search-results .teaser-row .h-color-3 h2 {
	border-top: 0;
}
/* Michael Simon B.B. [2015.01.09]: Auch Teaser Meldungen und Veranstaltungen sollten in der Zeile 2+ einen grauen Balken oben kriegen. */
.teaser-row h2,
.teaser-row .latest h3 {
	border-top: 4px solid #bfbfbf;
	padding: 15px 0;
	font-size: 160%;
	text-transform: uppercase;
}
.teaser-row .orgainfo h2 {
	border-top: none;
}
.teaser-row a + h2,
.teaser-row .img-placeholder + h2,
.teaser-row .image-wrapper + h2,
.teaser-row .ct-video + h2 {
	border: none;
	padding-top: 0;
	padding-bottom: 5px;
	font-size: 180%;
	font-weight: normal;
	text-transform: none;
}

/* Michael Simon B.B. [2015.03.05]: Damit das Vorschaubild auch richtig platziert wird und ueber embedded Videos gelegt wird. */
.fluid-width-video-wrapper .video-splashscreen {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	cursor: pointer;
}

.fluid-width-video-wrapper .video-splashscreen img {
	width: 100%;
	height: 100%;
}
.video-splashscreen img.yt-play {
	height: auto;
	width: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}
.js-off .video-splashscreen {
	cursor: default;
}
.js-off .video-splashscreen .yt-play {
	display: none;
}
/* [2015.03.15] Ende */

/* Michael Simon C.C. [2015.06.01]: Weisse Hintergruende bei den Ueberschriften in Teasern. */
.teaser-row .h-color-1 h2.mod,
.teaser-row .h-color-2 h2.mod,
.teaser-row .h-color-3 h2.mod {
	border-top: 4px solid #bfbfbf;
	padding: 15px 0;
	margin-left: 0px;
	margin-right: -10px;
	margin-left: -10px;
	margin-top: -10px;
	background: transparent;
	font-size: 160%;
	text-transform: uppercase;
}
.teaser-row .h-color-1 h2,
.teaser-row .h-color-2 h2,
.teaser-row .h-color-3 h2 {
	border-top: 4px solid #bfbfbf;
	padding: 15px 0;
	margin-left: -10px;
	margin-right: -10px;
	margin-top: -10px;
	background: #fff;
	font-size: 160%;
	text-transform: uppercase;
}
/* Michael Simon [2019.08.19]: Korrektur, damit Überschriften in großen Teasern nicht links heraus laufen. */
.teaser-row .grid-3.h-color-1 h2,
.teaser-row .grid-3.h-color-2 h2,
.teaser-row .grid-3.h-color-3 h2 {
	margin-left: 17px;
}
.teaser-row .grid-3.h-color-1:first-child h2,
.teaser-row .grid-3.h-color-2:first-child h2,
.teaser-row .grid-3.h-color-3:first-child h2 {
	margin-left: -10px;
}
/* Michael Simon [2019.08.19]: Korrektur, damit Bilder in großen Teasern nicht links heraus laufen. */
.teaser-row .grid-3.h-color-1 .teaser-inner img,
.teaser-row .grid-3.h-color-2 .teaser-inner img,
.teaser-row .grid-3.h-color-3 .teaser-inner img {
	margin-left: 27px;
}
.teaser-row .grid-3.h-color-1:first-child .teaser-inner img,
.teaser-row .grid-3.h-color-2:first-child .teaser-inner img,
.teaser-row .grid-3.h-color-3:first-child .teaser-inner img {
	margin-left: 0;
}

.teaser-row a + h2 {
	margin-left: 0;
	margin-right: 0;
}
.teaser-row h2.mod {
	font-size: 180%;
	font-weight: normal;
	text-transform: none;
}
#main .teaser-row .teaser.h-color-1 a + h2,
#main .teaser-row .teaser.h-color-2 a + h2,
#main .teaser-row .teaser.h-color-3 a + h2,
#main .teaser-row .teaser a + h2,
#main .teaser-row .teaser.h-color-1 .staffmember-image-wrapper + h2,
#main .teaser-row .teaser.h-color-2 .staffmember-image-wrapper + h2,
#main .teaser-row .teaser.h-color-3 .staffmember-image-wrapper + h2,
#main .teaser-row .teaser .staffmember-image-wrapper + h2,
#main .teaser-row .img-placeholder + h2,
#main .teaser-row .image-wrapper + h2,
#main .teaser-row .teaser.ct-video + h2 {
	border: none;
	background: none;
	padding-top: 7px;
	padding-bottom: 5px;
	margin-bottom: 0;
	margin-left: 0;
	margin-top: 0;
	margin-right: 0;
	font-size: 180%;
	font-weight: normal;
	text-transform: none;
}
#main .teaser-row .teaser.grid-2 h2.mod,
#main .teaser-row .teaser.grid-3 h2.mod {
	padding-top: 0;
}
#main .teaser-row .teaser.grid-2.h-color-1 h2.mod,
#main .teaser-row .teaser.grid-3.h-color-1 h2.mod,
#main .teaser-row .teaser.grid-2.h-color-2 h2.mod,
#main .teaser-row .teaser.grid-3.h-color-2 h2.mod,
#main .teaser-row .teaser.grid-2.h-color-3 h2.mod,
#main .teaser-row .teaser.grid-3.h-color-3 h2.mod {
	/* padding-top: 0;*/
 	margin-top: -3px;
	display:table-cell;
}
#main .teaser-row .teaser.grid-2.h-color-1 .ts-box h2.mod,
#main .teaser-row .teaser.grid-3.h-color-1 .ts-box h2.mod,
#main .teaser-row .teaser.grid-2.h-color-2 .ts-box h2.mod,
#main .teaser-row .teaser.grid-3.h-color-2 .ts-box h2.mod,
#main .teaser-row .teaser.grid-2.h-color-3 .ts-box h2.mod,
#main .teaser-row .teaser.grid-3.h-color-3 .ts-box h2.mod {
	display: block;
	margin-top: -10px;
}
#main .teaser-row .teaser.grid-2.h-color-1 .teaser-inner + a,
#main .teaser-row .teaser.grid-3.h-color-1 .teaser-inner + a,
#main .teaser-row .teaser.grid-2.h-color-2 .teaser-inner + a,
#main .teaser-row .teaser.grid-3.h-color-2 .teaser-inner + a,
#main .teaser-row .teaser.grid-2.h-color-3 .teaser-inner + a,
#main .teaser-row .teaser.grid-3.h-color-3 .teaser-inner + a {
	float: left;
}
#main .teaser-row .teaser.grid-2.h-color-1 p,
#main .teaser-row .teaser.grid-3.h-color-1 p,
#main .teaser-row .teaser.grid-2.h-color-2 p,
#main .teaser-row .teaser.grid-3.h-color-2 p,
#main .teaser-row .teaser.grid-2.h-color-3 p,
#main .teaser-row .teaser.grid-3.h-color-3 p {
	/* padding-top: 0;*/
	margin-top: -3px;
}
#wrapper #main .teaser-row .teaser.grid-2.h-color-1 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser.grid-3.h-color-1 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser.grid-2.h-color-2 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser.grid-3.h-color-2 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser.grid-2.h-color-3 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser.grid-3.h-color-3 .ts-box h2.mod {
	padding-top: 0;
	margin-top: -10px;
}
/* Michael Simon [2016.09.08]: Farbige Teaserlisten mit normaler Ueberschrift waren falsch eingerueckt */
#wrapper #main .teaser-row .teaser-list.grid-2.h-color-1 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser-list.grid-3.h-color-1 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser-list.grid-2.h-color-2 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser-list.grid-3.h-color-2 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser-list.grid-2.h-color-3 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser-list.grid-3.h-color-3 .ts-box h2.mod {
	padding: 10px 0 0 10px;
}
/* Michael Simon [2016.09.08]: Farbige Teaserlisten mit normaler Ueberschrift waren falsch eingerueckt */
#wrapper #main .teaser-row .teaser-list.grid-2.h-color-2 .ts-box h2.mod,
#wrapper #main .teaser-row .teaser-list.grid-3.h-color-2 .ts-box h2.mod {
	color: #fff;
}
#main .teaser-row .teaser.h-color-2 .staffmember-image-wrapper + h2.mod a,
#main .teaser-row .teaser.h-color-2 .staffmember-image-wrapper + h2.mod,
#main .teaser-row .teaser.h-color-2 a + h2.mod a,
#main .teaser-row .teaser.h-color-2 a + h2.mod {
	color: #fff;
}
.teaser-row .img-placeholder + h2 {
	padding-top: 10px;
}
.teaser-row .image-wrapper + h2 {
	padding-top: 5px;
}

.teaser-row:first-child h2,
.teaser-row.mod h2,
.teaser-row:first-child .col-1 .teaser:first-child h2,
.teaser-row:first-child .col-2 .teaser:first-child h2/*,
	.teaser-row .teaser-group .teaser h2,
	.teaser-row .teaser-wrapper .teaser h2,
	.teaser-row .teaser.h-color-1 h2,
	.teaser-row .teaser.h-color-2 h2,
	.teaser-row .teaser.h-color-3 h2*/ {
	border-top: none;
	padding-top: 0;
}

#main .teaser-row .teaser-list.h-color-1 h2,
#main .teaser-row .teaser-list.h-color-2 h2,
#main .teaser-row .teaser-list.h-color-3 h2,
#main .teaser-row .teaser.h-color-1 h2,
#main .teaser-row .teaser.h-color-2 h2,
#main .teaser-row .teaser.h-color-3 h2 {
	margin-bottom: 9px;
}
#main .teaser-row .teaser-list.h-color-1 .ts-box h2,
#main .teaser-row .teaser-list.h-color-2 .ts-box h2,
#main .teaser-row .teaser-list.h-color-3 .ts-box h2,
#main .teaser-row .teaser.h-color-1 .ts-box h2,
#main .teaser-row .teaser.h-color-2 .ts-box h2,
#main .teaser-row .teaser.h-color-3 .ts-box h2 {
	margin-bottom: 12px;
}
.teaser-row .teaser.grid-2 h2.mod,
.teaser-row .teaser.grid-3 h2.mod {
	padding-top: 0;
}
.teaser-row .teaser h2.mod {
	border-top: none;
	padding: 10px 0;
	text-transform: none;
	font-size: 180%;
	font-weight: normal;
}
.teaser-row .teaser-list .teaser h2 {
	border-top: 1px solid #bfbfbf;
	padding: 15px 0;
	font-size: 160%;
	font-weight: bold;
	text-transform: uppercase;
}
.teaser-row .teaser-list .teaser h2.mod {
	border-top: none;
	padding-top: 0;
	background: none;
	margin: 0;
	padding-bottom: 13px;
	text-transform: none;
	font-weight: normal;
	font-size: 180%;
}
.teaser-row h2 a {
	color: #000;
}
.teaser-row h2 a span {
	display: block;
}
.teaser-row img {
	margin: 0 0 2px 0;
}
.teaser-row .staffmember-image-wrapper {
	position: relative;
	margin: 0 0 2px 0;
	line-height: 0px;
}
.teaser-row .staffmember-image-wrapper img {
	margin: 0;
}
.teaser-row .grid-2 img {
	margin-right: 15px;
	float: left;
}
.teaser-row .grid-2 .staffmember-image-wrapper img {
	margin-right: 0;
	float: none;
}
.teaser-row .grid-2 .staffmember-image-wrapper {
	margin-right: 15px;
	float: left;
}
.teaser-row .teaser-list.grid-2 img {
	margin-right: 0;
	float: left;
}
.teaser-row .grid-3 img {
	float: left;
	max-width: 47.6em;
	margin-right: 10px;
	margin-top: 0;
}
.teaser-row .grid-3 .staffmember-image-wrapper img {
	float: none;
	margin-right: 0;
}
.teaser-row .grid-3 .staffmember-image-wrapper {
	float: left;
	margin-right: 10px;
	margin-top: 0;
}
.teaser-row .teaser.mod img  {
	border-top: 4px solid #bfbfbf;
	padding-top: 18px;
}
.teaser-row .teaser span.img-placeholder {
	display: block;
	border-top: 4px solid #bfbfbf;
}
.teaser-row.first-child .teaser span.img-placeholder {
	border-top: none;
}

.teaser-row.mod .teaser.mod img,
.teaser-row:first-child .teaser.mod img,
.teaser-row .teaser-group .teaser img,
.teaser-row .teaser-wrapper .teaser img {
	border-top: none;
	padding-top: 0;
}
.single-page .teaser-row:first-child .teaser.mod img {
	border-top: none;
	padding-top: 0;
}
#main .teaser-row p {
	padding: 0;
}
#main .teaser-row p + p {
	padding-top: 8px;
}
/* Michael Simon B.B. [2014.10.07]: Damit die mehr-Buttons nicht am Text kleben.
#home #main .teaser-row p + p {
	padding-top: 0;
}
*/
.teaser-row .more {
	font-style: normal;
}
.teaser-row .more a {
	background: url(../img/icons/icon-sprites.png) 0 0 no-repeat;
	padding-left: 20px;
	font-size: 100%;
}
.teaser-row .more.external {
	display: block;
	padding-top: 5px;
}
.teaser-row .more.external a {
	background-position: 0 -400px;
}
.teaser-row .links {

	margin-top: 5px;
}
.teaser-row .teaser .col-1 .links,
.teaser-row .teaser .col-2 .links {
	margin-top: -7px;
}
.teaser-row .grid-3 .links {
	max-width: 28.7em;
	width: 100%;
	margin-top: 15px;
}
.teaser-row .links ul {
	border-top: 1px dotted #797979;
}
.teaser-row .col-2 .links ul {
	margin-top: 5px;
}
.teaser-row .links li {
	border-bottom: 1px dotted #797979;
	padding: 1px 0 1px 0;
	font-size: 140%;
	list-style: none;
}
.teaser-row .links li a {
	display: block;
	background: url(../img/icons/icon-sprites.png) 5px 8px no-repeat;
	padding: 8px 0 8px 26px;
}
.teaser-row .links li a:hover,
.teaser-row .links li a:active,
.teaser-row .links li a:focus {
	background-color: #E5EEF5;
	text-decoration: none;
}
.teaser-row .links-big {
	width: 100%;
	overflow: hidden;
}
.teaser-row .links-big ul {
	float: left;
	border: none;
	width: 46.5%;
	margin: 0 0 0 30px;
}
.teaser-row .links-big ul:first-child {
	margin-left: 0;
}
.teaser-row .links-big li:first-child {
	border-top: 1px dotted #797979;
}
.teaser-row .links-big li a {
	font-weight: bold;
}
.teaser-row .links-big li span {
	display: block;
	padding: 3px 0 0 0;
	font-weight: normal;
	color: #000;
	font-size: 92%;
}
/*  Video Teaser */
.teaser-row .teaser-video span.video-opener {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -37px 0 0 -37px;
	height: 73px;
	width: 74px;
	display: block;
	text-indent: -4000px;
	background: url(../img/bgs/video-opener.png) no-repeat 0 0;
}
.teaser-row .teaser-video.mod span.video-opener {

}
.teaser-row .teaser-video .image-wrapper {
	position: relative;
}
.image-wrapper a.video-box {
	display: block;
	position: relative !important;
}




/* Teaser Linklist */
.teaser-link-list {
	float: left;

	padding: 0 0 10px 0;
}
.teaser-link-list h3 {
	border-top: 4px solid #BFBFBF;
	font-size: 160%;
	font-weight: bold;
	padding: 15px 0 20px;
	text-transform: uppercase;
}
.teaser-link-list h3.mod {
	font-size: 180%;
	font-weight: normal;
	text-transform: none;
}
.teaser-link-list h3 a {
	color: #333;
}
.teaser-link-list ul {
	float: left;
	width: 46%;
	margin-right: 4%;
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
	padding: 0 0 1px 0;
}
.teaser-link-list ul li {
	list-style: none;
	padding: 2px 0 1px 0;
	background: url(../img/bgs/border-50.png) repeat-x 0 0;
}
.teaser-link-list ul li a {
	display: block;
	padding: 7px 0 7px 26px;
	list-style: none;
	font-size: 140%;
	background: url(../img/icons/icon-sprites.png) no-repeat 5px 8px;
}
.teaser-link-list ul li a:hover,
.teaser-link-list ul li a:active,
.teaser-link-list ul li a:focus {
	background-color: #EAF1F7;
	text-decoration: none;
}


/* TG-Mod (Teaser-Group-Modification) - Grey teasers in Search-Results */
.teaser-row .tg-mod .teaser .teaser-inner {
	background: #f2f2f2;
}
#main .teaser-row .tg-mod .teaser .teaser-inner p {
	padding-bottom: 10px;
}
.teaser-row .tg-mod .teaser img {
	margin: 0;
}
.teaser-row .tg-mod .teaser:first-child {
	margin-left: 0;
}
.teaser-row .tg-mod .teaser h2 {
	margin: 0 0 6px 0;
	padding: 10px 10px 0 10px;
	font-size: 180%;
	text-transform: none;
	font-weight: normal;
}
#main .teaser-row .tg-mod .teaser p {
	padding: 0 10px;
}
#main .teaser-row .tg-mod .teaser p em.email {
	display: block;
	margin: 8px 0 0 0;
}
#main .teaser-row .tg-mod .teaser p em.email a {
	background-position: 0 -4600px;
	padding: 4px 0 3px 28px;
}

/* Michael Simon C.C. [2015.11.24]: Korrektur nur fuer die RWTH Startseite, damit das Design auch bei 3 Teasern nicht zusammenbricht. */
#home.col.startpage #main .teaser-row .latest.events {
	width: 22.858%;
	float: left;
}
#home.col.startpage #main .teaser-row .latest.news {
	width: 25.613%;
}

/* Facts-Teaser */
.teaser.facts {

	text-align: center;
}
.teaser-row .fact-box {
	text-align: center;
}
#wrapper-2 #wrapper #main .teaser.facts h2 {
	border: none;
	display: inline-block;
}
#main .teaser-row .fact-box p {
	padding: 0 30px
}
#main .teaser-row .fact-box p em {
	display: block;
}
#main .teaser-row .fact-box p:first-child {
	display: inline-block;
	background: #00529f url(../img/bgs/bg-facts.png) 0 0 repeat;
	margin: 0 0 15px 0;
	border-radius: 50%;
	padding: 0 0;
	color: #fff;
	width: 11em;
	height: 11em;
}
/* Michael Simon [2016.11.08]: Schriftgroesse ohne JS angepasst, sodass Zahlen mit 5 Ziffern und Punkt gut rein passen; ein Ausreisser mit 20.000.000 */
.teaser-row .fact-box p:first-child strong {
	display: block;
	padding: 36% 0 0 0;
	font-size: 300%; /* 100px */
	font-weight: normal;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1em;
}

/* Highlight Teaser
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
#main .teaser-row .teaser .teaser-inner {
	/* overflow: hidden; Experimentell: Dies scheint keinen Zweck zu haben und stört an anderer Stelle! Wird vorerst entfernt. */
}
#main .teaser-row .teaser .teaser-inner::after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	clear: both;
}

#main .teaser-row .teaser .teaser-inner,
#main .teaser-row .latest .teaser-inner,
#main .teaser-row .orgainfo .teaser-inner,
#main .teaser-row .list-teaser-group .teaser-inner {
	background-image: url(../img/bgs/line-color-teaser.png);
	background-position: 0 0;
	background-repeat: repeat-y;
	padding: 0 0 0 27px
}
#main .teaser-row .teaser-special .teaser-inner {
	padding: 10px 0 10px 27px
}

#main .teaser-row .teaser:first-child .teaser-inner,
#main .teaser-row .teaser-group .teaser.first .teaser-inner,
#main .teaser-row .teaser-group .latest.first .teaser-inner,
#main .teaser-row .list-teaser-group:first-child .teaser-inner,
#main .teaser-row.t-col-4 .latest.first .teaser-inner,
#main .teaser-row .latest:first-child .teaser-inner {
	background-image: none;
	padding-left: 0;
}
#main .teaser-row .teaser-list.h-color-1 .teaser-inner,
#main .teaser-row .teaser-list.h-color-2 .teaser-inner,
#main .teaser-row .teaser-list.h-color-3 .teaser-inner {
	padding-bottom: 0;
}
#main .teaser-row .teaser-list.h-color-1 h2,
#main .teaser-row .teaser-list.h-color-2 h2,
#main .teaser-row .teaser-list.h-color-3 h2 {

}
#main .teaser-row .teaser.h-color-1 .teaser-inner,
#main .teaser-row .teaser.h-color-2 .teaser-inner,
#main .teaser-row .teaser.h-color-3 .teaser-inner {
	padding: 10px 10px 10px 37px;
}
#main .teaser-row .teaser.h-color-1:first-child .teaser-inner,
#main .teaser-row .teaser.h-color-2:first-child .teaser-inner,
#main .teaser-row .teaser.h-color-3:first-child .teaser-inner {
	padding: 10px 10px 10px 10px;
}
#main .teaser-row .teaser.h-color-1.teaser-list .teaser-inner,
#main .teaser-row .teaser.h-color-2.teaser-list .teaser-inner,
#main .teaser-row .teaser.h-color-3.teaser-list .teaser-inner {
	padding: 0 10px 0 37px;
}
#main .teaser-row.t-col-3 .teaser.h-color-1:first-child .teaser-inner,
#main .teaser-row.t-col-3 .teaser.h-color-2:first-child .teaser-inner,
#main .teaser-row.t-col-3 .teaser.h-color-3:first-child .teaser-inner {
	/* padding: 10px 10px 10px 10px; Michael Simon [2015.08.10]: Wirkt so, als ob die 10px top hier Zusatzlinien machen. */
}


.startpage #main .teaser-row .teaser.h-color-1 .teaser-inner,
.startpage #main .teaser-row .teaser.h-color-2 .teaser-inner,
.startpage #main .teaser-row .teaser.h-color-3 .teaser-inner,
.col.startpage #main .teaser-row .teaser.h-color-1 .teaser-inner,
.col.startpage #main .teaser-row .teaser.h-color-2 .teaser-inner,
.col.startpage #main .teaser-row .teaser.h-color-3 .teaser-inner {
	padding: 10px;
	margin-left: 27px;
	background-image: none;
}
#wrapper #main .teaser-row .teaser.grid-3.h-color-1 .teaser-inner,
#wrapper #main .teaser-row .teaser.grid-3.h-color-2 .teaser-inner,
#wrapper #main .teaser-row .teaser.grid-3.h-color-3 .teaser-inner,
#wrapper #main .teaser-row .teaser.grid-2.h-color-1:first-child .teaser-inner,
#wrapper #main .teaser-row .teaser.grid-2.h-color-2:first-child .teaser-inner,
#wrapper #main .teaser-row .teaser.grid-2.h-color-3:first-child .teaser-inner {
	padding-left: 10px;
}
#main .teaser-row .teaser .teaser-inner img {
	max-width: 100%;
	width: 100%;
	height: auto;
}
#main .teaser-row .teaser.grid-2 .teaser-inner img,
#main .teaser-row .teaser.grid-3 .teaser-inner img {
	max-width: 100%;
	width: 224px; /* Michael Simon A.A. [2014.08.06]: Als wir Teaserbilder groesser ausgeliefert haben wurde das Bild in dem Teaser auch immer groesser, da es durch CSS keine Groesse bekommen hat. */
	margin-bottom: 0;
}
#main .teaser-row .teaser.grid-3 .teaser-inner img {
	width: 476px; /* Michael Simon A.A. [2014.10.06]: Die Bilder der extra breiten Teaser waren zu klein. */
}
#main .teaser-row .teaser.grid-3.teaser-special .teaser-inner img {
	width: 350px; /* Michael Simon A.A. [2014.08.19]: Dadurch, dass wir oben die Breite fest setzen (statt wie vorher auto), müssen wir den Sonderfall des Extra Breiten Teasers hier abfangen. */
}
#main .teaser-row .teaser.grid-2 .teaser-inner .staffmember-image-wrapper {
	position: relative;
	max-width: 100%;
	width: 224px;
	margin: 0 15px 0 0;
	line-height: 0px;
}
#main .teaser-row .teaser.teaser-list.grid-2 .teaser-inner .staffmember-image-wrapper {
	margin: 0;
}
#main .teaser-row .teaser.grid-3 .teaser-inner .staffmember-image-wrapper {
	width: 476px;
}
#main .teaser-row .teaser.grid-3.teaser-special .teaser-inner .staffmember-image-wrapper {
	width: 350px;
}
#main .teaser-row .teaser.grid-2 .teaser-inner .staffmember-image-wrapper img,
#main .teaser-row .teaser.grid-3 .teaser-inner .staffmember-image-wrapper img,
#main .teaser-row .teaser.grid-3.teaser-special .teaser-inner .staffmember-image-wrapper img {
	width: 100%;
	margin: 0;
}

/* highlight yellow */
#main .teaser-row .h-color-1 .teaser-inner{
	background-color: #FFCC00;
}
#main .teaser-row .h-color-1 *,
#main .teaser-row .h-color-1 a + h2 {
	color: #000;
}
#main .teaser-row .h-color-1 .copyright,
#main .teaser-row .h-color-1 .hidden-copyright,
#main .teaser-row .h-color-2 .copyright,
#main .teaser-row .h-color-2 .hidden-copyright,
#main .teaser-row .h-color-3 .copyright,
#main .teaser-row .h-color-3 .hidden-copyright,
#main .teaser-row .h-color-1 .copyright a,
#main .teaser-row .h-color-1 .hidden-copyright a,
#main .teaser-row .h-color-2 .copyright a,
#main .teaser-row .h-color-2 .hidden-copyright a,
#main .teaser-row .h-color-3 .copyright a,
#main .teaser-row .h-color-3 .hidden-copyright a {
	color: #fff;
}
#main .teaser-row .h-color-1 .links ul,
#main .teaser-row .h-color-1 .links li {
	border-color: #000;
}
#wrapper #main .teaser-row .teaser-list.h-color-1 h2.mod,
#wrapper #main .teaser-row .teaser-list.h-color-2 h2.mod,
#wrapper #main .teaser-row .teaser-list.h-color-3 h2.mod {
	border-top: none;
	padding-top: 8px;

}
#main .teaser-row .h-color-1 .more a {
	background: url(../img/icons/icon-sprites.png) 0 -800px no-repeat;
}
#main .teaser-row .teaser-list.h-color-1 a {
	background: url(../img/icons/icon-sprites.png) 5px -791px no-repeat;
}
#main .teaser-row .teaser-list.h-color-1 a:hover,
#main .teaser-row .teaser-list.h-color-1 a:active,
#main .teaser-row .teaser-list.h-color-1 a:focus {
	background-color: #e8ba00;
	text-decoration: none;
}
/* highlight blue */
#main .teaser-row .h-color-2 .teaser-inner {
	background-color: #00539F;
}
/* Michael Simon C.C. [2015.06.01]: Weisse Hintergruende bei Teaserueberschriften. */
#main .teaser-row .h-color-2 *,
#main .teaser-row .h-color-2 a + h2,
#main .teaser-row .h-color-2 a + h2 a,
#main .teaser-row .h-color-2 .staffmember-image-wrapper + h2,
#main .teaser-row .h-color-2 .staffmember-image-wrapper + h2 a,
#main .teaser-row .teaser-list.h-color-2 h2.mod,
#main .teaser-row .teaser-list.h-color-2 h2.mod a,
#main .teaser-row .teaser.h-color-2 h2.mod,
#main .teaser-row .teaser.h-color-2 h2.mod a {
	color: #fff;
}

#main .teaser-row .grid-2.h-color-2 a + h2,
#main .teaser-row .grid-2.h-color-2 a + h2 a,
#main .teaser-row .grid-2.h-color-2 .staffmember-image-wrapper + h2,
#main .teaser-row .grid-2.h-color-2 .staffmember-image-wrapper + h2 a,
#main .teaser-row .grid-2.teaser-list.h-color-2 h2.mod,
#main .teaser-row .grid-2.teaser-list.h-color-2 h2.mod a,
#main .teaser-row .grid-3.h-color-2 a + h2,
#main .teaser-row .grid-3.h-color-2 a + h2 a,
#main .teaser-row .grid-3.h-color-2 .staffmember-image-wrapper + h2,
#main .teaser-row .grid-3.h-color-2 .staffmember-image-wrapper + h2 a,
#main .teaser-row .grid-3.teaser-list.h-color-2 h2.mod,
#main .teaser-row .grid-3.teaser-list.h-color-2 h2.mod a{
	color: #000;
}
#main .teaser-row .teaser-list.h-color-2 h2,
#main .teaser-row .teaser.h-color-2 h2,
#main .teaser-row .teaser-list.h-color-2 h2 a,
#main .teaser-row .teaser.h-color-2 h2 a {
	color: #000;
}
#main .teaser-row .h-color-2 .links ul,
#main .teaser-row .h-color-2 .links li {
	border-color: #80A9CF;
}
#main .teaser-row .teaser-list.h-color-2 h2.mod {
	border-top: none;
}
#main .teaser-row .h-color-2 .more a {
	background: url(../img/icons/icon-sprites.png) 0 -999px no-repeat;
}
#main .teaser-row .teaser-list.h-color-2 a {
	background: url(../img/icons/icon-sprites-3.png) 5px -1191px no-repeat;
}
#main .teaser-row .teaser-list .copyright.symbol a,
#main .teaser-row .teaser-list.h-color-1 .copyright.symbol a,
#main .teaser-row .teaser-list.h-color-2 .copyright.symbol a,
#main .teaser-row .teaser-list.h-color-3 .copyright.symbol a,
#main .teaser-row .teaser-list .hidden-copyright a,
#main .teaser-row .teaser-list.h-color-1 .hidden-copyright a,
#main .teaser-row .teaser-list.h-color-2 .hidden-copyright a,
#main .teaser-row .teaser-list.h-color-3 .hidden-copyright a {
	background: none;
	color: #FFF;
}
#main .teaser-row .teaser-list.h-color-2 a:hover,
#main .teaser-row .teaser-list.h-color-2 a:active,
#main .teaser-row .teaser-list.h-color-2 a:focus {
	background-color: #0D5DA4;
	text-decoration: none;
}
#main .teaser-row .teaser-list .copyright.symbol a:hover,
#main .teaser-row .teaser-list .copyright.symbol a:active,
#main .teaser-row .teaser-list .copyright.symbol a:focus,
#main .teaser-row .teaser-list.h-color-1 .copyright.symbol a:hover,
#main .teaser-row .teaser-list.h-color-1 .copyright.symbol a:active,
#main .teaser-row .teaser-list.h-color-1 .copyright.symbol a:focus,
#main .teaser-row .teaser-list.h-color-2 .copyright.symbol a:hover,
#main .teaser-row .teaser-list.h-color-2 .copyright.symbol a:active,
#main .teaser-row .teaser-list.h-color-2 .copyright.symbol a:focus,
#main .teaser-row .teaser-list.h-color-3 .copyright.symbol a:hover,
#main .teaser-row .teaser-list.h-color-3 .copyright.symbol a:active,
#main .teaser-row .teaser-list.h-color-3 .copyright.symbol a:focus,
#main .teaser-row .teaser-list .hidden-copyright a:hover,
#main .teaser-row .teaser-list .hidden-copyright a:active,
#main .teaser-row .teaser-list .hidden-copyright a:focus,
#main .teaser-row .teaser-list.h-color-1 .hidden-copyright a:hover,
#main .teaser-row .teaser-list.h-color-1 .hidden-copyright a:active,
#main .teaser-row .teaser-list.h-color-1 .hidden-copyright a:focus,
#main .teaser-row .teaser-list.h-color-2 .hidden-copyright a:hover,
#main .teaser-row .teaser-list.h-color-2 .hidden-copyright a:active,
#main .teaser-row .teaser-list.h-color-2 .hidden-copyright a:focus,
#main .teaser-row .teaser-list.h-color-3 .hidden-copyright a:hover,
#main .teaser-row .teaser-list.h-color-3 .hidden-copyright a:active,
#main .teaser-row .teaser-list.h-color-3 .hidden-copyright a:focus {
	background-color: transparent;
	text-decoration: underline;
}
#main .teaser-row .teaser-list.h-color-1 .teaser-inner .ts-box,
#main .teaser-row .teaser-list.h-color-2 .teaser-inner .ts-box,
#main .teaser-row .teaser-list.h-color-3 .teaser-inner .ts-box {
	padding-top: 10px;
}
/* highlight grey */
#main .teaser-row .h-color-3 .teaser-inner {
	background-color: #EDEDED;
}
#main .teaser-row .h-color-3 *,
#main .teaser-row .h-color-3 a + h2,
#main .teaser-row .h-color-3 a + h2 a,
#main .teaser-row .h-color-3 .staffmember-image-wrapper + h2,
#main .teaser-row .h-color-3 .staffmember-image-wrapper + h2 a {
	color: #000;
}
#main .teaser-row .h-color-3 .copyright,
#main .teaser-row .h-color-3 .hidden-copyright {
	color: #fff;
}
#main .teaser-row .h-color-3 .more a {
	background: url(../img/icons/icon-sprites.png) 0 0 no-repeat;
	color: #00529F;
}
#main .teaser-row .teaser-list.h-color-3 h2.mod {
	border-top: none;
}
#main .teaser-row .teaser-list.h-color-3 a {
	background: url(../img/icons/icon-sprites.png) 5px -791px no-repeat;
}
#main .teaser-row .teaser-list.h-color-3 a:hover,
#main .teaser-row .teaser-list.h-color-3 a:active,
#main .teaser-row .teaser-list.h-color-3 a:focus {
	background-color: #dcdcdc;
	text-decoration: none;
}
/* highlight zurücknehmen */
#main .teaser-row .teaser-list.h-color-1,
#main .teaser-row .teaser-list.h-color-2,
#main .teaser-row .teaser-list.h-color-3 {
	background-color: #fff;
}

/* Teaser Special = grey Background (Exzellenzinitiative) */
.teaser-row .teaser-special {
	background: #f2f2f2;
}
#main .teaser-row .teaser-special .ts-box {
	border: 1px dotted #797979;
	margin: 0 10px 0 10px;
	overflow: hidden;
	padding: 0 20px 25px;
}
.teaser-row .teaser-special h2 {
	border: none;
	padding-top: 15px;
	text-align: center;
	font-size: 240%;
	font-family: Georgia, "Times New Roman", Times, serif;
	font-weight: normal;
	text-transform: none;
}
.teaser-row .teaser-special img {
	width: 35em;
}
.teaser-row .grid-3 .links {
	float: left;
}
/* FOOTER
############################ */
#pre-footer-logos {
	margin-top: 100px;
	margin-bottom: -100px;
	padding: 10px 0;
	width: 100%;
	text-align: center;
}
#pre-footer-logos img {
	margin: 4px;
}
#site-info {
	background: #333;
	margin-top: 100px;
	color: #fff;
	padding: 0 20px 36px;
}
#site-info.after-logos {
	margin-top: 0;
}
#site-info a {
	color: #ccc;
	font-size: 140%;
	padding-left: 22px;
	background: url(../img/icons/icon-sprites-1.png) 0 0 no-repeat;
}
/* Michael Simon [2012.04.12]: Externe Links im Footer durch URLWeiterleitung */
#site-info a.external {
	background: url(../img/icons/icon-sprites.png) 0 -11400px no-repeat;
}
/* Michael Simon [2016.05.12]: Intranet Links im Footer durch URLWeiterleitung */
#site-info a.intranet {
	background: url(../img/icons/icon-sprites.png) 0 -25200px no-repeat;
}
#site-info div div {
	width: 22%;
	float: left;
	margin: 20px 3% 0 0;
	overflow: hidden;
}
.js-off #site-info div div {
	margin-right: 15px;
}
#site-info div div.last-child {
	float: right;
	margin-right: 0;
	margin-bottom: 30px;
}
#site-info div div:last-child {
	float: right;
	margin-right: 0;
	margin-bottom: 30px;
}
#site-info div div ul {
	list-style: none;
}
#site-info div div ul li {
	margin: 0 0 10px 0;
	padding: 0 0 8px 0;
	background: url(../img/bgs/border-25.png) repeat-x 0 100%;
}
/* Michael Simon A.A. [2014.07.25]: YouTube Social Media Icon fuer Footer */
/* Michael Simon C.C. [2015.11.10]: Vimeo   Social Media Icon fuer Footer */
#site-info div div ul li.twitter,
#site-info div div ul li.facebook,
#site-info div div ul li.googleplus,
#site-info div div ul li.linkedin,
#site-info div div ul li.xing,
#site-info div div ul li.instagram,
#site-info div div ul li.youtube,
#site-info div div ul li.vimeo,
#site-info div div ul li.researchgate,
#site-info div div ul li.soundcloud,
#site-info div div ul li.external-large,
#site-info div div ul li.internal-large,
#site-info div div ul li.moresocial {
	margin: 0 0 17px 0;
	padding: 0 0 8px 0;
	background: none;
}
#site-info div div ul li.facebook a {
	padding: 8px 0 7px 40px;
	background: url(../img/icons/icon-sprites.png) 0 -6200px no-repeat;
}
#site-info div div ul li.twitter a {
	padding: 8px 0 7px 40px;
	background: url(../img/icons/icon-sprites.png) 0 -6400px no-repeat;
}
#site-info div div ul li.xing a {
	padding: 8px 0 7px 40px;
	background: url(../img/icons/icon-sprites.png) 0 -6600px no-repeat;
}
#site-info div div ul li.googleplus a {
	padding: 8px 0 7px 40px;
	background: url(../img/icons/icon-sprites.png) 0 -12400px no-repeat;
}
#site-info div div ul li.linkedin a {
	padding: 8px 0 7px 40px;
	background: url(../img/icons/icon-sprites.png) 0 -12200px no-repeat;
}
/* Michael Simon [2017.07.24]: Instagram Social Media Icon fuer Footer */
#site-info div div ul li.instagram a {
	padding: 8px 0 7px 40px;
	background: url(/custom/rwth/img/icons/icon-sprites.png) 0 -14000px no-repeat;
}
/* Michael Simon A.A. [2014.07.25]: YouTube Social Media Icon und More Icon fuer Footer */
#site-info div div ul li.youtube a {
	padding: 8px 0 7px 40px;
	background: url(/custom/rwth/img/icons/icon-sprites.png) 0 -13400px no-repeat;
}
/* Michael Simon C.C. [2015.11.10]: Vimeo Social Media Icon und More Icon fuer Footer */
#site-info div div ul li.vimeo a {
	padding: 8px 0 7px 40px;
	background: url(/custom/rwth/img/icons/icon-sprites.png) 0 -13800px no-repeat;
}
#site-info div div ul li.researchgate a {
	padding: 8px 0 7px 40px;
	background: url(/custom/rwth/img/icons/icon-sprites.png) 0 -14600px no-repeat;
}
#site-info div div ul li.soundcloud a {
	padding: 8px 0 7px 40px;
	background: url(/custom/rwth/img/icons/icon-sprites.png) 0 -14800px no-repeat;
}
#site-info div div ul li.external-large a {
	padding: 8px 0 7px 40px;
	background: url(/custom/rwth/img/icons/icon-sprites.png) 0 -15000px no-repeat;
}
#site-info div div ul li.internal-large a {
	padding: 8px 0 7px 40px;
	background: url(/custom/rwth/img/icons/icon-sprites.png) 0 -15200px no-repeat;
}
#site-info div div ul li.moresocial a {
	padding: 8px 0 7px 40px;
	background: url(/custom/rwth/img/icons/icon-sprites.png) 0 -13600px no-repeat;
}
#site-info div div ul.facilities li {
	margin: 0 0 10px 0;
	padding: 0;
	background: none;
}
#site-info div div ul.facilities li a {
	padding: 0;
	background: none;
}
#site-info div div ul.facilities li img {
	display: block;
	width: 100%;
	max-width: 224px; /* Michael Simon B.B. [2015.02.27]: Damit das Bild nicht groesser wird als es ist und dadurch unscharf wird. */
	height: auto;
}
#site-info div div h3 {
	margin: 0 0 18px 0;
	font-size: 160%;
	text-transform: uppercase;
}
/* PAGES
############################ */
/* Sitemap */
.sitemap {
	width: auto;
	margin: 0;
}
.accordion-box-headline,
.accordion-box-box {
	margin: 0 0 30px 0;
	padding: 10px 15px;
	border:  4px solid #BFBFBF;
}
.accordion-box-headline a{
	text-decoration: none;
}
.sitemap h2 {
	padding: 0 0 2px 15px;
	font-size: 160%;
	color: #292929;
	text-transform: uppercase;
	font-weight: bold
}
.sitemap h2 a {
	color: #292929;
}
.js-on .sitemap h2 {
	cursor: pointer;
	outline: none;
	background: url(../img/icons/icon-sprites.png) no-repeat 0 -5595px;
}
.js-on .sitemap h2.js-selected {
	cursor: pointer;
	outline: none;
	background: url(../img/icons/icon-sprites.png) no-repeat 0 -5793px;
}
.js-on .sitemap h2.ui-disabled {
	cursor: default;
}
.js-on .sitemap h2.a11y-focus-key {
	text-decoration: underline;
}
#main .sitemap ul {
	background: url(../img/bgs/border-50.png) repeat-x scroll 0 100% transparent;
	margin-bottom: 20px;
	margin-top: 15px;
	padding-left: 0;
	padding-bottom: 10px;
}
/* Michael Simon [2017.04.10]: Auch wenn Helvetica auf vielen PCs nicht angezeigt wird, die Reihenfolge wie bei allen anderen angepasst. */
#main .sitemap ul li {
	list-style: none;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 100%;
	list-style: none;
	list-style-image: none;
	background-position: 0 100%;
	background: url(../img/bgs/border-50.png) repeat-x scroll 0 100% transparent;

	padding: 12px 0;
}
#main .sitemap ul li:last-child,
#main .sitemap ul li.last-child {
	background: none;
	padding: 12px 0 0;
}
#main .sitemap ul li a {
	font-size: 160%;
	display: block;
	font-weight: bold;
	padding: 1px 0 0 22px;
	background: url(../img/icons/icon-sprites.png) 0 0 no-repeat;
}
#main .sitemap ul li ul li a {
	font-size: 140%;
	display: block;
	font-weight: normal;
}
#main .sitemap ul li ul li ul li a {
	font-size: 120%;
}
#main .sitemap ul ul {
	padding: 10px 0 0 20px;
	background-position: 0 0;
	margin: 10px 0 0 0;
}
#main .sitemap ul ul ul {
	padding: 10px 0 0 20px;
	background-position: 0 0;
	margin: 10px 0 0 0;
}
#main .sitemap ul ul ul li {
	background: none;
	padding-top: 3px;
	padding-bottom: 3px;
}
#main .sitemap ul ul ul li:last-child,
#main .sitemap ul ul ul li.last-child {
	padding: 3px 0 0;
}
#main .sitemap ul ul ul ul {
	padding: 0 0 0 20px;
	background: none 0 0 no-repeat;
	margin: 0;
}

/* Search Results */
/* Search Results */
#main h2.result {
	margin-bottom: 25px;
	border-top: 4px solid #bfbfbf;
	padding-top: 15px;
	font-size: 160%;
	text-transform: uppercase;
}
.pagination {
	position: relative;
	clear: both;
	float: left;
	width: 100%;
	margin: 0 0 20px 0;
}
.pagination ul {
	text-align: center;
}
.pagination li {
	display: inline-block;
	text-align: center;
	list-style: none;
	font-size: 140%;
}
*+html .pagination li {
	display: inline;
}
.pagination li a,
.pagination li strong {
	display: block;
	padding: 10px 5px;
	font-weight: bold;
	width: 5em;
}
.pagination li strong {
	background: #00549F;
	color: #fff;
}
.pagination li a:hover,
.pagination li a:active,
.pagination li a:focus {
	background-color: #ebebeb;
	text-decoration: none;
}
.pagination .previousfive a,
.pagination .nextfive a {
	background-color: #404040;
	color: #FFFFFF;
	height: 1.1em;
}
.pagination .previousfive a:hover,
.pagination .previousfive a:active,
.pagination .previousfive a:focus,
.pagination .nextfive a:hover,
.pagination .nextfive a:active,
.pagination .nextfive a:focus {
	background-color: #00549F;
}
.pagination .previous,
.pagination .first,
.pagination .previousfive,
.pagination .previousfiveplaceholder,
.pagination .leftplaceholder {
	float: left;
	margin-right: 0.5em;
}
.pagination .previousfiveplaceholder,
.pagination .nextfiveplaceholder,
.pagination .centerplaceholder {

}
.pagination .leftplaceholder,
.pagination .rightplaceholder {


	display: block;
}
.pagination .next,
.pagination .last,
.pagination .nextfive,
.pagination .nextfiveplaceholder,
.pagination .rightplaceholder {
	float: right;
	margin-left: 0.5em;
}
.pagination .previous a,
.pagination .next a,
.pagination .previous span,
.pagination .next span,
.pagination .first a,
.pagination .last a,
.pagination .first span,
.pagination .last span {
	overflow: hidden;
	display: block;
	width: 2.5em;
	height: 2.5em;
	background: #404040 url(../img/icons/icon-sprites.png) 55% -10195px no-repeat;
	padding: 0;
	text-indent: -999em;
}
.pagination .previous a:hover,
.pagination .next a:hover,
.pagination .previous a:active,
.pagination .next a:active,
.pagination .previous a:focus,
.pagination .next a:focus,
.pagination .first a:hover,
.pagination .last a:hover,
.pagination .first a:active,
.pagination .last a:active,
.pagination .first a:focus,
.pagination .last a:focus {
	background-color: #00549F;
}
.pagination .previous span,
.pagination .next span,
.pagination .first span,
.pagination .last span {
	background-color: #bfbfbf;
}
.pagination .next a,
.pagination .next span {
	overflow: hidden;
	background-position: 45% -10395px;
}
.pagination .first a,
.pagination .first span {
	overflow: hidden;
	background-position: 55% -11595px;
}
.pagination .last a,
.pagination .last span {
	overflow: hidden;
	background-position: 45% -11795px;
}
/* Michael Simon A.A. [2014.08.07]: Damit die Links zum Umblaettern (in Listen und Suchen) ab einer gewissen Breite kleiner werden, damit alles in einer Zeile bleibt. */
@media only screen and (max-width: 1036px){
	.pagination li {
		font-size: 110%;
	}
	
	.pagination .previous a,
	.pagination .next a,
	.pagination .previous span,
	.pagination .next span,
	.pagination .first a,
	.pagination .last a,
	.pagination .first span,
	.pagination .last span {
		width: 2.7em;
		height: 2.9em;
	}
	
	.pagination .previous a,
	.pagination .previous span {
		overflow: hidden;
		background-position: 55% -10196px;
	}
	.pagination .next a,
	.pagination .next span {
		overflow: hidden;
		background-position: 45% -10396px;
	}
	.pagination .first a,
	.pagination .first span {
		overflow: hidden;
		background-position: 50% -11596px;
	}
	.pagination .last a,
	.pagination .last span {
		overflow: hidden;
		background-position: 55% -11796px;
	}
}
/* Countdown Modul */
#main .countdown-wrapper {
	clear: left;
	float: left;
	border: 4px solid #BFBFBF;
	padding: 1.25em 2.5em;
	width: 22.3em;
	margin: 5px 20px 5px 0;
	box-sizing: border-box;
	text-align: center;
}
#main .countdown-wrapper.dir-2 {
	clear: right;
	float: right;
	margin: 5px 0 5px 20px;
}
#main .countdown-wrapper p {
	padding: 0;
}
#main .countdown-number {
	background: #00529f url(../img/bgs/bg-facts.png) 0 0 repeat;
	width: 16em;
	height: 16em;
	border-radius: 50%;
	display: inline-block;
	margin-bottom: 1.25em;
}
#main .countdown-number p {
	color: white;
	font-size: 490%;
	line-height: 325%;
}
/* Events */
#events .event-search,
#wrapper .new-search {
	position: relative;
	background: url(../img/bgs/bg-event-search.jpg) 0 0 repeat-x;
	margin: 0 0 40px 0;
	border: 1px solid #cdcdcd;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	border-radius: 8px;
	z-index: 2;
}
#events .event-search:hover,
#wrapper .new-search:hover {
	border: 1px solid #7FA9CF;
}
#events .event-search:focus,
#wrapper .new-search:focus,
#events .event-search:active,
#wrapper .new-search:active {
	border: 1px solid #00549F;
}
#events .event-search input,
#wrapper .new-search input {
	background: url(../img/icons/icon-sprites.png) 15px -1985px no-repeat;
	max-width: 35em;
	width: 85%;
	border: none;
	padding: 14px 10px 14px 55px;
	font-size: 180%;
	font-style: italic;
}
#events .event-search input[type=text]::-ms-clear,
#wrapper .new-search input[type=text]::-ms-clear {
	display: none;
}
#events .event-search input:hover,
#wrapper .new-search input:hover,
#events .event-search input:focus,
#wrapper .new-search input:focus,
#events .event-search input:active,
#wrapper .new-search input:active {
	border: none;
}
#events .event-search input.button,
#wrapper .new-search input.button {
	position: absolute;
	top: 0;
	right: 0;
	height: 50px;
	width: 30px;
	background: url(../img/icons/icon-sprites.png) -30px -5382px no-repeat;
	padding: 0;
	cursor: pointer;
	text-indent: -9999em;
}
.event-text-box,
.content-text-box {
	border-top: 4px solid #bfbfbf;
	padding-top: 13px;
}
/* listing (for Events and Search Results) */
.listing {
	clear: both;
}
.listing h3 {
	background: url(../img/icons/icon-sprites.png) 0 -5595px no-repeat;
	padding: 0 0 0 16px;
	font-size: 160%;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;
}
.listing h3.js-selected {
	background-position: 0 -5794px;
}
.listing-item {
	overflow: hidden;
	border: 4px solid #e5e5e5;
	margin: 0 0 30px 0;
	padding: 15px 10px;
}
.listing > ul {
	background: url(../img/bgs/dotted-line-persona.png) 0 0 repeat-x;
	margin: 25px 0 14px 0;
	padding: 1px 0 0 0;
}
.listing > ul > li {
	background: url(../img/bgs/dotted-line-persona.png) 0 100% repeat-x;
	/* overflow: hidden; */
	padding: 10px;
	list-style: none;
	font-size: 100%;
}
.listing > ul > li::after {
	content: "";
	display: block;
	clear: both;
	width: 0px;
	height: 0px;
}
.listing > ul > li ul {
	list-style: disc none outside;
	margin: 0 0 12px 18px;
}
/* Michael Simon A.A. [2014.08.07]: Damit bei der Suche die Auflistung besser zusammenrutscht beim Responsive-Design. */
#search-results .listing li {
	position: relative;
}
.listing > ul > li.odd {
	background-color: #f5f5f5;
}
.listing > ul > li:nth-child(odd) {
	background-color: #f5f5f5;
}
.listing > ul > li:nth-child(2n+1):hover,
.listing > ul > li:hover {
	background-color: #EAF1F7;
}
.listing > ul > li.listheadline,
.listing > ul > li.listheadline:hover {
	background-color: #595959;
	color: #FFFFFF;
	font-weight: bold;
}
.listing > ul > li span.highlight {
	background: #FFF2BF;
}
.listing > ul > li span.date-time {
	float: left;
	font-size: 140%;
}
/* Michael Simon A.A. [2014.08.07]: Damit bei der Suche die Auflistung besser zusammenrutscht beim Responsive-Design. */
.listing .mod li span.date-time {
	width: auto;
	font-size: 120%;
	position: absolute;
	top: 6px;
	right: 6px;
}
.listing li span.date-time span {
	float: none;
	display: block;
	font-size: 100%;
}
.listing li .date-time {
	width: 7.15em; /* 100px */
}
.listing li .date-time span:first-child {
	font-weight: bold;
}
.listing .mod li .date-time span:first-child {
	font-weight: normal;
}
.listing li h2 {
	float: left;
	width: 35%; /* 260px */
	font-size: 150%;
	font-weight: normal;
}
.listing .mod li h2 {
	width: 100%;
	margin-bottom: 5px;
	word-wrap: anywhere;
}
.listing li h2 a {
	display: block;
	background: url(../img/icons/icon-sprites.png) 0 1px no-repeat;
	padding-left: 22px;
}
.listing li .location {
	float: left;
	width: 25%; /* 185px */
	font-size: 140%;
}
.listing li .location .staffmember-image-wrapper {
	position: relative;
	line-height: 0;
}
/* Michael Simon B.B. [2015.03.09]: Um Piktogramme in Auflistungen/Tabellen anzuzeigen. */
.pictograph {
	background-image:	url(../img/icons/pictograph.png);
	display:			inline-block;
	height:				23px;
	width:				23px;
}
.listing li .type {
	float: left;
	width: 25%; /* 135px */
	font-size: 140%;
}
#main .listing li p {
	margin: 0 0 10px 0;
	font-size: 140%;
}
#main .listing li .location p,
#main .dynamicTable td p { /* Michael Simon [2012.04.11]: Zur Korrektur von dynamischen Listen und Tabellen */
	margin: 0;
	padding: 0;
	font-size: 100%;
	float: none;
}
#main .text .aw_ce_wrapper > .dynamicTable.invisible {
	margin-top: 16px;
}
#main .text table.dynamicTable.invisible thead {
	display: none;
}
#main .text table.dynamicTable.invisible,
#main .text table.dynamicTable.invisible td {
	border: 0px hidden transparent;
}
#main .text table.dynamicTable.invisible tr:hover td,
#main .text table.dynamicTable.invisible tr:nth-child(2n) td {
	background-color: transparent;
}
#main .text table.dynamicTable.invisible td {
	padding: 0 0 12px;
}

.listing li .listing-breadcrumb {
	clear: both;
	overflow: hidden;
	margin: 0 0 10px 0;
	border: 1px solid #e5e5e5;
}
.listing li .listing-breadcrumb li {
	float: left;
	background: none;
	border: none;
	padding: 5px;
	font-size: 120%;
}
.listing li .rwth-listing-breadcrumb li {
	float: left;
	background: none;
	border: none;
	padding: 0;
	font-size: 120%;
}
.listing li .listing-breadcrumb li a,
.listing li .listing-breadcrumb li strong {
	background: url(../img/icons/icon-sprites.png) 0 -4197px no-repeat;
	padding-left: 10px;
}
.listing li .listing-breadcrumb li:first-child a,
.listing li .listing-breadcrumb li:first-child strong {
	background: none;
	padding-left: 0;
}
.listing li .listing-breadcrumb li strong {
	font-weight: normal;
}
#main .listing p {
	float: left;
	padding: 3px 0 0 0;
	font-size: 120%;
}
#main .listing p.all {
	float: right;
	padding: 0;
	font-size: 120%;
}
.listing p.all a {
	display: block;
	min-width: 12em;
	background: #e1e1e1 url(../img/icons/icon-sprites.png) 94% -2993px no-repeat;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 6px 25px 6px 10px;
	font-weight: bold;
	color: #000;
	text-transform: uppercase;
}
.listing p.all a:hover,
.listing p.all a:active,
.listing p.all a:focus {
	text-decoration: none;
	color: #fff;
}
/* Listing Bildergalerien */
#gallery-overview .listing ul {
	background: url(../img/bgs/border-gallery.gif) 50% 0 no-repeat;
	overflow: hidden;
}
#gallery-overview .listing li {
	float: left;
	width: 33em;
	min-height: 65px;
	padding-left: 5px;
}

#gallery-overview .listing li:hover,
#gallery-overview .listing li:focus
#gallery-overview .listing li:active,
#gallery-overview .listing li.odd:hover,
#gallery-overview .listing li.odd:focus
#gallery-overview .listing li.odd:active{
	background-color: #EAF1F7;
}
#gallery-overview .listing li.odd {
	width: 31.3em;
	margin-right: 27px;
}
#gallery-overview .listing li img {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
}
#gallery-overview .listing li h2 {
	float: none;
	font-size: 150%;
	font-weight: normal;
	width: auto;
	padding-left: 7.2em;
}
#gallery-overview #main .listing ul p {
	position: static;
	margin: 0;
	padding: 0 0 0 10.75em;
	float: none;
}
#gallery-overview #main .listing p.all {
	margin: 0;
	position: static;
}
#gallery-overview #main .listing p {
	position: relative;
	top: 3px;
	right: 20px;
	float: right;
	font-size: 120%;
	padding: 3px 0 0;
	margin: 3px 0 0 0;
}
/* Listing Overview allgemein */
#overview .listing li h2 {
	float: left;
	max-width: 26em; /* 185px */
}
#overview .listing li h2 a {
	font-weight: bold;
}
#overview .listing li span {
	float: left;
	width: 6em; /* 185px */
	font-size: 140%;
}
#overview .listing p.short-description {
	float: none;
	padding-top: 10px;
}
#overview .listing ul {
	margin-top: 10px;
}
/*
Contact Person Profile
****************************** */
.contact-person .vcard .staffmember-image-wrapper {
	position: relative;
	float: left;
	margin: 0 20px 35px 0;
	line-height: 0px;
}
.contact-person .vcard img {
	padding: 1px;
	border: 1px solid #BFBFBF;
	max-height: 288px;
	max-width: 350px;
}
.contact-person .vcard .n {
	margin-bottom: 47px;
}
.contact-person .vcard .n .title,
.contact-person .vcard .n .honorific-prefix {
	font-size: 240%;
	font-weight: normal;
}
.contact-person .vcard .n span {
	display: inline;
}
.contact-person #main .vcard .n p {
	display: block;
	font-weight: normal;
	white-space: normal;
	line-height: 1em;
	font-size: 400%;
	padding:  0;
	margin: 0;
}
.contact-person #main .vcard .n p.title {
	font-size: 160%;
	font-style: italic;
	padding-top: 4px;
}
/* Michael Simon [2012.03.21]: Fix, damit auf Mitarbeiterseiten nicht bei der Adresse zwischen jeder Zeile 20px Abstand sind */
.contact-person #main .vcard .adr {
	padding-bottom: 20px;
}
.contact-person #main .vcard .adr p {
	padding-bottom: 0;
}
.contact-person #main .vcard .org {
	padding-top: 30px;
	font-size: 200%;
	margin-bottom: 27px;
}
.contact-person #main .vcard .org span em {
	display: block;
	background: none;
}
.contact-person #main .vcard .adr span {
	font-size: 140%;
	display: block;
	line-height: 1.5em;
}
.contact-person #main .vcard .adr p span {
	font-size: 100%;
	display: inline;
}
.contact-person .contact-box {
	clear: both;
	width: auto;
	overflow: hidden;
	border:  4px solid #BFBFBF;
	margin-bottom: 20px;
}
.contact-person .contact-box dl {
	overflow: hidden;
}
#main .vcard .contact-box {
	width: 100%;
	clear: right;
	display: block;
	text-align: center;
	box-sizing: border-box;
}
.contact-person #main .vcard .contact-box .www {
	background: url(/custom/rwth/img/icons/icon-sprites.png) no-repeat scroll 0 -11992px transparent;
	float: none;
	padding-left: 31px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-right: 20px;
	margin: 0;
	text-align: left;
	line-height: 100%;
	display: inline-block;
	font-size: 140%;
}
/* Michael Simon [2017.06.13]: Orgainfoboxen tapbar und vcard */
.vcard .tel .tap-tel {
	color: #000;
}
.contact-person #main .vcard .contact-box .tel {
	padding-top: 15px;
	padding-bottom: 20px;
}
/* Michael Simon [2018.12.06]: Neuausrichtung der Telefonnummer auf Mitarbeiterseiten, damit sie Response immer mittig ist. */
.contact-person #main .vcard .contact-box .tel dt {
	float: none;
	height: 37px;
	margin: 0;
	padding: 0;
	text-indent: -9999em;
	background: url(../img/icons/icon-sprites-1.png) no-repeat scroll 100% -5000px transparent;
	width: 3.8em;
	display: inline-block;
	vertical-align: middle;
}
.contact-person #main .vcard .contact-box .tel dd {
	font-size: 240%;
	font-weight: bold;
	padding: 2px 0 0 0;
	color: #333;
}
.contact-person #main .vcard .contact-box .fax {
	background: none;
	float: none;
	padding-left: 31px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-right: 20px;
	margin: 0;
	text-align: left;
	line-height: 100%;
	display: inline-block;
	font-size: 140%;
	background: url(../img/icons/icon-sprites.png) no-repeat 0 -10792px;
}
.contact-person #main .vcard .contact-box .fax dt {
	float: left;
	height: 23px;
	margin: 0;
	padding: 0;
	text-indent: -9999em;
	width: 15em;
	background: url(../img/icons/icon-sprites-1.png) no-repeat scroll 113% -4800px transparent;
}
.contact-person #main .vcard .contact-box .fax dd {
	font-size: 140%;
	font-weight: normal;
	color: #333;
}
.contact-person #main .vcard .contact-box .email {
	background: url(../img/icons/icon-sprites.png) no-repeat 0 -4592px;
	float: none;
	padding-left: 31px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-right: 20px;
	margin: 0;
	text-align: left;
	line-height: 100%;
	display: inline-block;
	font-size: 140%;

}
.contact-person #main .vcard .contact-box .email dt {
	float: left;
	height: 23px;
	margin: 0 8px 0 0;
	padding: 0;
	text-indent: -9999em;
	width: 23px;
	background: url(../img/icons/icon-sprites.png) no-repeat scroll 0 -4600px transparent;
}
.contact-person #main .vcard .contact-box .email dd {
	font-size: 140%;
	font-weight: normal;
	color: #333;
	width: auto;
	padding-top: 2px;
}
/* Michael Simon [2018.12.06]: Neuausrichtung der Telefonnummer auf Mitarbeiterseiten, damit sie Response immer mittig ist. */
.contact-person #main .vcard .contact-box .tel dd {
	float: none;
	display: inline-block;
	vertical-align: middle;
	line-height: 1.3em;
	padding-right: 0;
	width: auto;
}
.contact-person #main .vcard .note h3 {
	display: block;
	height: auto;
	width: auto;
	position: static;
	padding-top: 10px;
	font-size: 200%;
}
.contact-person #main .vcard .note dl dt {
	width: 16em;
}
.contact-person #main .vcard .note .dl-group {
	margin-bottom: 15px;
	overflow: hidden;
}
.contact-person #main .vcard dl {
	overflow: hidden;
}
/* Forms */
#wrapper fieldset {
	border: none;
}
#wrapper label {
	display: block;
	font-size: 120%;
	font-weight: bold;
	margin-bottom: 2px;
}
#wrapper input,
#wrapper textarea {
	padding: 6px 4px;
	font-family: arial, helvetica, sans-serif;
	font-size: 130%;
	border: 1px solid #CDCDCD;
}
#wrapper input:focus,
#wrapper textarea:focus,
#wrapper input:active,
#wrapper textarea:active {
	border: 1px solid #00549F;
}
#wrapper input:hover,
#wrapper textarea:hover {
	border: 1px solid #7FA9CF;
}
#wrapper .form-row {
	margin-bottom: 11px;
}
#wrapper input.submit {
	float: right;
	overflow:  visible;
	width: auto;
	padding-right: 60px;
	text-transform: uppercase;
	color: #fff;
	font-size: 120%;
	font-weight: bold;
	background: #000 url(../img/icons/icon-sprites.png) 93% -2791px no-repeat;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: none;
	cursor: default;
}
#wrapper input.submit:hover {
	background-color: #00549F;
	border: none;
}
/* FORMS
-------------------- */
.form-wrapper {
	background: #f2f2f2;
	clear: both;
	position: relative;
}
#wrapper .form-wrapper .error h4,
#wrapper .form-wrapper .error {
	color: #c00 !important;
}
#wrapper .form-wrapper fieldset {
	border: none;
	margin: 0;
	border-bottom: 1px solid #fff;
	padding: 28px;
}
#wrapper .form-wrapper fieldset fieldset {
	border: none;
	padding: 0;
}
.form-wrapper fieldset h2 {
	margin: 0 0 1px 0;
	padding: 5px 0 5px 0;
}
.form-wrapper p.required {
	text-align: right;
}
.form-wrapper p.required span,
.form-wrapper dfn {
	font-weight: bold;
}
#main .form-wrapper p.required {
	position: absolute;
	right: 0;
	width: 11.4em; /* 160px */
}
#main .form-wrapper p.error {
	position: relative;
	float: right;
	clear: none;
	width: 11.4em; /* 160px */
	padding: 10px 0 0;
}
#main .form-wrapper p.required {
	padding: 28px 28px 0 0;
	text-align: left;
	font-style: italic;
	min-height: 4em;
}
#main .form-wrapper p.error span {
	display: block;
	vertical-align: middle;
	color: #c00 !important;
}
#main .form-wrapper .error input,
#main .form-wrapper .error textarea {
	border-color: #c00;
}
.form-wrapper div {
	position: relative;
	clear: both;
	float: left;
/*	width: 100% !important; Michael Simon A.A. [2014.07.30]: Mit diesem Zusatz sind keine Formularfelder mehr nebeneinander. Getestet in Feedbackformularen und dynamischen Formularen. Darum diese Zeile in die tablet.css gepackt. Ab da sollen die alle untereinander und 100% breit sein. */
	padding: 10px 0 15px 0;
}
.form-wrapper div div {
	width: auto;
	border-bottom: none;
	margin: 0 0 10px 0;
	padding: 0;
}
.form-wrapper div div.form-item {
	margin: 0;
	clear: none;
}
.form-wrapper label {
	position: relative;
	display: block;
	font-size: 130%;
	cursor: pointer;
}
.form-wrapper  fieldset .multiple-labels fieldset input,
.form-wrapper input,
.form-wrapper textarea,
.form-wrapper div.mod input.mod {
	display: block;
	background: #fff;
	width: 36.3em; /* 472px */
	margin: 3px 0 0 0;
	border: 1px solid #c2c2c2;
	padding: 3px 3px 4px 3px;
}
.form-item-hidden,
.form-wrapper input[type=hidden] {
	display: none;
}
.form-wrapper .multiple-labels fieldset input.a11y-focus,
.form-wrapper .multiple-labels fieldset input:focus,
.form-wrapper input:focus,
.form-wrapper textarea:focus,
.form-wrapper input.a11y-focus,
.form-wrapper textarea.a11y-focus {
	border: 1px solid #000;
	background: #fff;
}
.form-wrapper input[type=radio].a11y-focus,
.form-wrapper input[type=checkbox].a11y-focus {
	background: transparent;
	border: none;
}
.form-wrapper input[type=radio],
.form-wrapper input[type=checkbox] {
	background: transparent;
	border: 0 !important;
}
.form-wrapper textarea {
	height: 150px;
	width: 36.3em; /* 472px */
}
.form-wrapper select {
	display: block;
	width: 310px;
	margin: 5px 0 0 0;
	padding: 6px 4px;
}
.form-wrapper select.a11y-hidden {
	height: 0%;
	visibility: visible;
}
.form-wrapper div span {
	display: block;
	padding: 5px 0 0 0;
	color: #555;
	font-size: 110%;
}
.form-wrapper h2 {
	padding: 0 0 5px 0;
	color: #333;
	font-size: 130%;
	font-weight: bold;
}
.form-wrapper div.multiple-labels label span {
	color: #555;
	font-size: 110%;
	font-weight: normal;
}
.form-wrapper div label span {
	display: inline;
}
.form-wrapper div label.m span {
	clear: both;
	padding: 0 0 5px 0;
}

/* multiple inputs in one row */
.form-wrapper div div.form-item-s,
.form-wrapper div div.form-item-m,
.form-wrapper div div.form-item-l {
	float: left;
	margin: 0;
	clear: none;
	width: 12.4em;
}
.form-wrapper div div.form-item-m {
	width: 25.2em;
	padding: 0;
}
.form-wrapper div div.form-item-l {
	width: 37.4em;
}
.form-wrapper div div.form-item-s input {
	width: 7.7em; /* 100px */
}
.form-wrapper div div.form-item-m input {
	width: 17.2em; /* 224px */
}
.form-wrapper div div.form-item-l input,
.form-wrapper div div.form-item-l select {
	width: 26.9em; /* 350px */
}

/* checkboxes & radiobuttons */
.form-wrapper fieldset fieldset {
	float: none; /* ie 6 */
	margin: 0;
	padding: 0;
}
.form-wrapper fieldset fieldset .form-item {
	float: none;
	display: block;
	clear: both;
}
.form-wrapper fieldset fieldset input {
	width: auto;
	display: inline;
	background: transparent;
	border: none;
}
#main .form-wrapper fieldset fieldset legend {
	text-indent: -9999px;
	font-size: 0;
}
#wrapper .form-wrapper  fieldset fieldset div.form-item label {
	display: inline;
}
#wrapper .form-wrapper  fieldset fieldset div.form-item label[for="privacy"]:hover {
	text-decoration: none;
}

/* mod for short elements */
.form-wrapper fieldset fieldset.mod .form-item {
	float: left;
	clear: none;
	margin-right: 10px;
	width: auto;
}
.form-wrapper fieldset fieldset.mod .form-item label {
	display: inline;
}
/* Submit */
.form-wrapper div.button-wrapper {
	position: relative;
	border: none;
	padding: 10px 0 5px 0;
}
.form-wrapper fieldset div.button-wrapper em {
	float: right;
	margin-top: -24px;
	font-size: 140%;
}
#wrapper .form-wrapper div.button-wrapper input { /* button */
	display: block;
	background: #e1e1e1 url(../img/icons/icon-sprites.png) 94% -2993px no-repeat;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	border: none;
	padding: 4px 25px 4px 10px !important;
	font-weight: bold;
	text-align: left;
	color: #000;
	width: 200px;
	float: left;
	cursor: pointer;
	margin-right: 1em; /* Michael Simon A.A. [2014.07.30]: Damit die beiden Buttons im Formular wenigstens einen kleinen Abstand zueinander haben. */
}
#wrapper .form-wrapper div.button-wrapper input[type="submit"] { /* button */
	background: #333 url(../img/icons/icon-sprites.png) 94% -2793px no-repeat;
	color: #fff;
	float: right;
	clear: right;
}
#wrapper .form-wrapper div.button-wrapper input[type="submit"]:hover { /* button */
	background: #00529F url(../img/icons/icon-sprites.png) 94% -2793px no-repeat;
}
#wrapper .form-wrapper div.button-wrapper input:hover,
#wrapper .form-wrapper div.button-wrapper input:active,
#wrapper .form-wrapper div.button-wrapper input:focus {
	text-decoration: none;
	color: #fff;
	background: #00549f url(../img/icons/icon-sprites.png) 94% -2793px no-repeat;
}
.new-search .button {  /* button outside button-wrapper */
	padding-top: 3px;
	padding-bottom: 4px;
}
.form-wrapper div.button-wrapper input:hover,
.form-wrapper div.button-wrapper input:active,
.form-wrapper div.button-wrapper input:focus {
	background: #333;
	color: #fff;
	border: 1px solid #111;
	border-right: 1px solid #555;
	border-bottom: 1px solid #555;
}
/*
FAQ
************************ */
/* FAQ Michael Simon [18.01.2012]: Damit FAQ-Boxen nicht unterhalb der Navi angeordnet werden. */

.article-accordion {
	display:block;
	float: right;
	clear: right;
	width: 100%;
	margin-bottom: 25px;
}
.article-accordion .listing-box {
	border: 4px solid #bfbfbf;
	padding:  0 10px 10px 10px;
}
.article-accordion .listing-box h2 {
	color: #000000;
	font-size: 160%;
	font-weight: bold;
	margin-bottom: 8px;
	margin-top: 0;
	padding-top: 20px;
	text-align: center;
	text-transform: uppercase;
}
.article-accordion .listing-item {
	border: none;
	margin: 0;
	padding: 2px 0 1px 0;
	background: url(../img/bgs/border-50.png) repeat-x 0 0;
}
.article-accordion .listing-wrapper {
	border: none;
	padding: 0 0 1px 0;
}
.article-accordion .listing-item:last-child .listing-wrapper {
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
	padding-bottom: 2px;
}
.article-accordion .listing-item h3 {
	color: #262626;
	font-size: 140%;
	font-weight: bold;
	padding: 10px 0 10px 25px;
	background: url(../img/icons/icon-sprites.png) 8px -5586px no-repeat;
}
.article-accordion .listing-item h3:hover,
.article-accordion .listing-item h3:focus,
.article-accordion .listing-item h3:active {
	outline: none;
	background-color: #EAF1F7;
	cursor: pointer;
}
.article-accordion h3.js-selected {
	background-position: 8px -5786px;
}
#main .article-accordion .listing-wrapper ul {
	margin: 0 0 0 27px;
	padding: 0 0 10px 0;
	background: none;
	list-style: none;
}
#main .article-accordion .listing-wrapper p {
	margin: 0 0 0 27px;
}

#main .article-accordion .listing-wrapper ol,
#main .article-accordion .listing-wrapper ul {
	margin: 0 0 0 27px;
}
#main .article-accordion .listing-wrapper ul li {
	list-style: none outside url(../img/icons/list-image.gif);
	background: none;
	margin: 0 0 8px 17px;
	padding: 0;
}

/* SPECIAL MODULES
############################ */
/* Dom Tabs in Teaser-Row */
.teaser-row .text-box {
	border-top: 4px solid #bfbfbf;
	padding-top: 15px;
}
.teaser-row .text-box ol,
.event-text-box ol,
.content-text-box ol {
	overflow: hidden;
	background: url(../img/bgs/line-ccc.png) 0 100% repeat-x;
	padding: 0 0 0 20px;
}
.teaser-row .text-box ol li,
.event-text-box ol li,
.content-text-box ol li {
	float: left;
	margin: 0 0 0 10px;
	list-style: none;
	font-size: 150%;
	font-weight: bold;
	text-transform: uppercase;
}
.teaser-row .text-box ol li a,
.event-text-box ol li a,
.content-text-box ol li a {
	display: block;
	border: 1px solid #ccc;
	border-bottom: 1px solid transparent;
	padding: 12px 20px;
	color: #666;
	text-decoration: none;
	cursor: pointer;
}
.teaser-row .text-box ol li a:hover,
.teaser-row .text-box ol li a:active,
.teaser-row .text-box ol li a:focus,
.event-text-box ol li a:hover,
.event-text-box ol li a:active,
.event-text-box ol li a:focus,
.content-text-box ol li a:hover,
.content-text-box ol li a:active,
.content-text-box ol li a:focus {
	color: #000;
	background-color: #f2f2f2;
	border-bottom: 1px solid #ccc;
}
.teaser-row .text-box ol li a.js-selected,
.event-text-box ol li a.js-selected,
.content-text-box ol li a.js-selected {
	border-bottom: 1px solid #fff;
	color: #000;
	background-color: #fff;
}
.teaser-row .section-group,
.event-text-box .section-group,
.content-text-box .section-group {
	overflow: hidden;
}
.teaser-row .section-wrapper,
.event-text-box .section-wrapper,
.content-text-box .section-wrapper {
	clear: both;
}
.teaser-row .section,
.event-text-box .section,
.content-text-box .section {
	padding: 24px 0 0 0;
	display: table;
	width: 100%;
	word-spacing: -1em;
}
.teaser-row .section *,
.event-text-box .section *,
.content-text-box .section * {
	word-spacing: 0;
}
.teaser-row .section-teaser {
	display: table-cell;
	vertical-align: top;
	min-width: 35em;
	padding-right: 20px;
}
.teaser-row .section-teaser img {
	width: 100%;
	height: auto;
}
.teaser-row .section-teaser h2 {
	clear: both;
	overflow: hidden;
	border: none;
	padding: 10px 0;
	font-size: 200%;
	font-weight: normal;
	line-height: 1.2em;
	text-transform: none;
}
.teaser-row .section-teaser h2 span.date {
	display: block;
	font-size: 60%; /* 12px */
	line-height: 1.3em;
	padding-bottom: 5px;
}
.teaser-row .section-latest {
	display: table-cell;
	vertical-align: top;
	width: 100%;
}
.teaser-row .section-latest li {
	background: url(../img/bgs/dotted-line-persona.png) 0 0 repeat-x;
	overflow: hidden;
	padding: 12px 0;
	list-style: none;
	font-size: 100%;
}
.teaser-row .section-latest li:first-child {
	background: none;
	padding-top: 3px;
}
.teaser-row .section-latest li h2 {
	border: none;
	padding: 0;
	font-weight: normal;
	font-size: 140%;
	text-transform: none;
}
.teaser-row .section-latest li h2 a {
	display: block;
	background: url(../img/icons/icon-sprites.png) 0 3px no-repeat;
	padding-left: 20px;
	line-height: 1.5em;
	font-size: 100%;
	color: #00529F;
}
.teaser-row .section-latest span.date {
	display: block;
	font-size: 120%; /* 12px */
	line-height: 1.3em;
	padding-bottom: 5px;
}
.teaser-row .section-latest li em {
	font-size: 120%;
	text-transform: uppercase;
	font-style: normal;
	font-weight: bold;
}
.teaser-row .section-latest li em a {
	display: block;
	background: #e1e1e1 url(../img/icons/icon-sprites.png) 94% -2993px no-repeat;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 6px 25px 6px 10px;
	font-weight: bold;
	color: #000;
}
.teaser-row .section-latest li em a:hover,
.teaser-row .section-latest li em a:active,
.teaser-row .section-latest li em a:focus {
	text-decoration: none;
	color: #fff;
	background:#00549F url(../img/icons/icon-sprites.png) 94% -2793px no-repeat;
}

/* Carousel in Stage on Homepage */
#stage .teaser-wrapper {
	position: relative;
	max-width: 128em;
}
#stage .teaser-wrapper .rack-design {
	width: 10000%;
	max-width: 100%;
}
.staffmember-image-wrapper {
	position: relative;
	line-height: 0;
}
.text .teaser-wrapper .rack-design .teaser span.copyright,
.staffmember-image-wrapper .copyright,
#stage .copyright {
	position: absolute;
	top: 0;
	right: 0;
	padding: 4px;
	font-size: 110%;
	background: rgba(0,0,0,0.5);
	width: auto;
	min-height: 0;
}
.text .teaser-wrapper .rack-design .teaser span.copyright,
.staffmember-image-wrapper .copyright {
	color: #fff;
	left: auto;
	top: auto;
	right: 2px;
	bottom: 2px;
	line-height: 100%;
	display: inline;
}
.text .teaser-wrapper .rack-design .teaser span.copyright.no-border,
.staffmember-image-wrapper .copyright.no-border {
	right: 0;
	bottom: 0;
}
#main .staffmember-image-wrapper span.copyright.symbol {
	font-size: 120%;
	padding: 3px 4px;
	cursor: help;
}
.text .teaser-wrapper .rack-design .teaser span.hidden-copyright,
.staffmember-image-wrapper .hidden-copyright {
	display: none;
	position: absolute;
	color: #fff;
	background: rgba(0,0,0,0.7);
	padding: 4px 8px;
	line-height: 110%;
	right: 2px;
	bottom: -3px;
	transform: translateY(100%);
	font-size: 110%;
	z-index: 1;
	left: auto;
	top: auto;
	width: auto;
	min-height: 0;
}
.text .teaser-wrapper .rack-design .teaser .copyright.no-border + span.hidden-copyright,
.staffmember-image-wrapper .copyright.no-border + .hidden-copyright {
	right: 0;
	bottom: -5px;
}
.text .teaser-wrapper .rack-design .teaser .copyright:focus + span.hidden-copyright,
.text .teaser-wrapper .rack-design .teaser .copyright:hover + span.hidden-copyright,
.staffmember-image-wrapper .copyright:focus + .hidden-copyright,
.staffmember-image-wrapper .copyright:hover + .hidden-copyright {
	display: block;
}
.staffmember-image-wrapper .hidden-copyright::before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-bottom: 5px solid rgba(0,0,0,0.7);
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	right: 3px;
	top: -5px;
	position: absolute;
}
.js-off #stage .teaser-wrapper {
	max-height: none;
}
.js-on #stage .rack {
	overflow: hidden;
}
#stage .prev span,
#stage .next span {
	display: none;
	position: absolute;
	z-index: 1;
	top: 20em;
	left: 0;
	width: 10.4em;
	height: 10.4em;
	background: #000 url(../img/icons/teaser-wrapper-sprite.png) 43% 18px no-repeat;
	background-color: rgba(0, 0, 0, 0.8);
	cursor: pointer;
}
#stage .prev span:hover,
#stage .next span:hover {
	background-color: #00539F;
}
#stage .next span {
	background-position: 55% -185px;
	left: auto;
	right: 0;
}
.js-on #stage .prev span,
.js-on #stage .next span {
	display: block;
}
#stage .teaser-wrapper .teaser {
	position: relative;
	float: left;
	overflow: hidden;
	min-width: 100%;
	width: 128em;
}
.js-off #stage .teaser-wrapper .teaser {
	margin-bottom: 10px;
	width: 100%; /* Michael Simon A.A. [2014.08.08]: In der JS-losen Variante sollen die Bilder mit der Bildschirmbreite mitskalieren und nicht die ganze Seite aufblaehen. */
}
#stage .teaser-wrapper .teaser * {
	color: #fff;
/*	background: #00549F; Michael Simon A.A. [2014.08.06]: Das hier faerbt zu viel Blau. */
	border: 0;
/* Michael Simon A.A. [2014.10.06]: Es wurde zu viel vom Bild verdeckt.
	float: none;
	width: auto;
*/
}
#stage .teaser-wrapper .teaser img {
	width: 100%;
}
#stage .teaser-wrapper .teaser.hide-text .teaser-box {
	display: none;
}
#stage .teaser-box {
	position: relative;
	max-width: 90em;
	margin: 0 auto;
}
@media only screen and (max-width: 1130px){
	#stage .teaser-box {
		max-width: 80em;
	}
}
#stage .teaser-box-2 {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 50px;
	background: #00549F;
	background-color: rgba(0, 84, 159, 1); /* Michael Simon A.A. [2014.08.06]: Transparenz wegen neuer Buehnenlogik entfernt. */
	padding: 25px;
}
#stage .teaser-wrapper video,
#stage .teaser-wrapper img {
	display: block;
	height: auto;
	width: 100%;
}
#stage .teaser-wrapper .stage-video-wrapper {
	width: 100%;
	padding-top: 39.0625%;
	height: 0;
	position: relative;
}
#stage .teaser-wrapper .stage-video-wrapper .play-button {
	background: url(/custom/rwth/img/bgs/video-opener.png) no-repeat 0 -73px;
	text-indent: -9999px;
	width: 75px;
	height: 74px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%) scale(1.05);
	z-index: 1;
	cursor: pointer;
}
#stage .teaser-wrapper .hide-text .stage-video-wrapper  .play-button {
	display: none;
}
#stage .teaser-wrapper .stage-video-wrapper video {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: black;
}
#stage .teaser-wrapper h2 {
	float: left;
	width: 37%; /* Michael Simon A.A. [2014.10.06]: Es wurde zu viel vom Bild verdeckt. */
	word-wrap: break-word;
	padding-right: 3%;
	font-size: 350%; /* Michael Simon A.A. [2014.10.06]: Es wurde zu viel vom Bild verdeckt. */
	font-weight: normal;
}
#stage .teaser-wrapper h2 {
	padding-bottom: 2px;
}
#stage .teaser-wrapper h2 a:hover,
#stage .teaser-wrapper h2 a:active,
#stage .teaser-wrapper h2 a:focus {
	text-decoration: none;
	background: url(../img/bgs/border-hl.png) 0 100% repeat-x;
}
#stage .teaser-wrapper p {
	float: left;
	width: 60%; /* Michael Simon A.A. [2014.10.06]: Es wurde zu viel vom Bild verdeckt. */
	font-size: 140%;
	line-height: 1.4em;
	word-wrap: break-word;
}
#stage .teaser-wrapper p .more {
	display: block;
	margin-top: 20px;
	font-style: normal;
}
#stage .teaser-wrapper p .more a {
	display: inline-block;
	min-width: 60px;
	text-align: left;
	background: #000 url(../img/icons/icon-sprites.png) 83% -2790px no-repeat;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 6px 20px 6px 10px;
	text-transform: uppercase;
	font-size: 86%; /* 12px */
	font-weight: bold;
}
#stage .teaser-wrapper p .more a:hover,
#stage .teaser-wrapper p .more a:active,
#stage .teaser-wrapper p .more a:focus {
	background: #fff url(../img/icons/icon-sprites.png) 83% -2990px no-repeat;
	color: #000;
	text-decoration: none;
}
/* Carousel in Teaser-Rows */
.teaser-row .teaser-wrapper {
	position: relative;
	overflow: hidden;
}
.teaser-row .prev span,
.teaser-row .next span {
	display: none;
	position: absolute;
	z-index: 1;
	top: 11em;
	left: 0;
	width: 3.5em;
	height: 3.5em;
	background: #000 url(../img/icons/icon-sprites-1.png) 50% -4195px no-repeat;
	cursor: pointer;
	opacity: 0.3;
	filter: alpha(opacity=30);
}
.teaser-row .next span {
	background-position: 50% -4395px;
	left: auto;
	right: 1px;
}
.teaser-row .prev span.show,
.teaser-row .next span.show {
	opacity: 1;
	filter: alpha(opacity=100);
}
.teaser-row .prev span.show:hover,
.teaser-row .next span.show:hover {
	background-color: #00539f;
}
.teaser-row .prev span.hidden,
.teaser-row .next span.hidden {
	opacity: 0;
	filter: alpha(opacity=0);
}
.js-on .teaser-row .prev span,
.js-on .teaser-row .next span {
	display: block;
}
/* Michael Simon [2012.02.27]: History Carousel in Contentpages */
.text .tw-history .teaser h2 {
	font-size: 140%;
	font-weight: normal;
	padding-bottom: 5px;
	padding-top: 5px;
}
.text .tw-history .teaser h2 a {
	background: url(../img/icons/icon-sprites.png) no-repeat scroll 0 0 transparent;
	color: #00549F;
	display: block;
	padding-left: 22px;
}
.text .tw-history .rack-design .teaser a:focus img {
	border-color: #00549F;
}
.text .tw-history .rack-design .teaser a img {
	border: 4px solid #FFFFFF;
	height: 9.7em;
}
.text .tw-history {
	/*max-width: 64.2em;*/
	display: block;
}
.tw-history .rack-design {
	min-height: 17em; /* Michael Simon A.A. [2014.08.06]: Vierzeilige Elemente sind aus der Zeitleiste heraus gelaufen. Drum nun min-height und nicht mehr height. */
}
.text .tw-history .rack-design .teaser.year {
	display: block;
	position: relative;
	float: left;
	background: url(../img/bgs/dotted-line-vertical.png) 50% 0 repeat-y;
	width: 2em;
	min-height: 17em;
	margin-bottom: 50px;
}
.js-on .text .tw-history .rack-design .teaser.year {
	margin-bottom: 0;
}
.text .tw-history .rack-design .year h3 {
	bottom: -25px;
	left: 0;
	position: absolute;
	padding: 0;
	font-size: 117%;
	font-weight: bold;
}
.js-off .text .teaser-wrapper .rack-design .teaser {
	margin-right: 12px !important;
	margin-bottom: 30px !important;
	float: left !important;
}
.js-off .text .tw-history .teaser.year {
	margin-right: 12px !important;
	clear: left;
	float: left !important;
}

/* History Carousel in Teaser-Row */
.teaser-row .tw-history {
	/* width: 100%; */
}
.tw-history .rack {
	background: #f2f2f2;
	padding: 30px 0 0 0;
	/* width: 100%; */
}
/*.nav-col .tw-history .rack,*/
.single-page .tw-history .rack {
	max-width: 72.9em;
}
.tw-history .rack-design {
	border-bottom: 37px solid #fff;
	width: 1000% !important;
}
.js-off .tw-history .rack-design {
	width: 100%;
	padding-bottom: 30px;
}
#main .teaser-row .tw-history .teaser,
.col #main .teaser-row .tw-history .rack-design .teaser:first-child {
	width: 14em !important;
	min-width: 0 !important;
	float: left;
	min-width: 0;
}
.col #main .teaser-row .tw-history .rack-design .teaser,
#main .teaser-row .tw-history .teaser:first-child {
	width: 14em !important;
	min-width: 0 !important;
}

#main .teaser-row .tw-history .teaser h2 {
	font-size: 140%;
	font-weight: normal;
	padding-top: 15px;
}
#main .teaser-row .tw-history .teaser h2 a {
	display: block;
	background: url(../img/icons/icon-sprites.png) 0 0 no-repeat;
	padding-left: 22px;
	color: #00549f;
}
#main .teaser-row .tw-history .teaser img {
	width: auto;

	border: 4px solid #fff !important;
}
#main .teaser-row .tw-history .teaser a.image:hover img,
#main .teaser-row .tw-history .teaser a.image:focus img {
	border: 4px solid #00529F !important;
}
.tw-history .teaser a:hover img,
.tw-history .teaser a:active img,
.tw-history .teaser a:focus img {
	border-color: #00549f !important;
}
#main .teaser-row .tw-history .teaser.year,
.col #main .teaser-row .tw-history .rack-design .teaser.year {
	display: block;
	position: relative;
	float: left;
	background: url(../img/bgs/dotted-line-vertical.png) 50% 0 repeat-y;
	width: 2em !important;
	min-width: 0 !important;;
	min-height: 17em;
	margin-right: 0;
	margin-bottom: 50px;
}
.js-off #main .teaser-row .tw-history .teaser.year,
.js-off .col #main .teaser-row .tw-history .rack-design .teaser.year {
	clear: both;
	margin-left: 0;
}
.js-on #main .teaser-row .tw-history .teaser.year,
.js-on .col #main .teaser-row .tw-history .rack-design .teaser.year {
	margin-bottom: 0;
}
.teaser-row .tw-history .year h3 {
	position: absolute;
	bottom: -2.5em;
	left: 0;
}

/* Carousel on Article */
.text .teaser-wrapper {
	margin: 0 0 40px 0;
	border: 4px solid #bfbfbf;
	padding: 2.5em 4em 2.5em 4em;
}
.js-off .text .teaser-wrapper {
	overflow: hidden;
}
.text .teaser-wrapper h5 {
	margin: 0 0 15px 0;
	font-size: 160%;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
}
.text .teaser-wrapper h5 a {
	color: #333;
}
.text .teaser-wrapper .rack-design .teaser {
	position: relative;
	float: left;
	width: 14em;
	margin-right: 25px;
}
.js-off .text .teaser-wrapper .rack-design .teaser {
	float: none;
	display: inline-block;
	margin-right: 15px;
	margin-bottom: 10px;
}
.text .teaser-wrapper .rack-design .teaser.last-teaser {
	margin-right: 0;
}
a.image { /* Selector from active web */
	display: block;
	width: 14em;
	text-align: center;
	height: 10.5em;
	background-color: #E1E1E1;
}
.text .teaser-wrapper .rack-design .teaser img {
	height: 10.5em;
}
.text .teaser-wrapper .rack-design .teaser a:hover img,
.text .teaser-wrapper .rack-design .teaser a:active img,
.text .teaser-wrapper .rack-design .teaser a:focus img {
	border-color: #00549F;
}
.text .teaser-wrapper .rack-design .teaser span {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	background: url(../img/icons/zoom.png) 50% 50% no-repeat;
	width: 14em;
	min-height: 10em;
}
.text .teaser-wrapper {
	position: relative;
}
.text .prev span,
.text .next span {
	display: none;
	position: absolute;
	z-index: 1;
	top: 40%;
	left: -0.4em;
	width: 3.5em;
	height: 3.5em;
	background: #000 url(../img/icons/icon-sprites-1.png) 50% -4195px no-repeat;
	cursor: pointer;
}
.text .prev.show span:hover,
.text .next.show span:hover {
	background-color: rgb(0, 84, 159);
}
.text .next span {
	background-position: 50% -4395px;
	left: auto;
	right: -0.4em;
}
.text .next span.show:hover,
.text .prev span.show:hover {
	background-color: #00539f;
}
.js-on .text .prev span,
.js-on .text .next span {
	display: block;
}

/* gallery and Lightbox */
.gallery {
	clear: both;
	padding-bottom: 45px;
}
.gallery-standalone .gallery-teaser-wrapper,
.gallery-standalone .teaser-wrapper,
.gallery-standalone .gallery-stage {
	border: none;
}
.pseudo-gallery,
.gallery-standalone {
	margin-bottom: 20px;
}
.gallery-stage {
	border-top: 4px solid #bfbfbf;
	border-left: 4px solid #bfbfbf;
	border-right: 4px solid #bfbfbf;
}
.gallery-teaser-wrapper,
.showbox .teaser-wrapper {
	border-bottom: 4px solid #bfbfbf;
	border-left: 4px solid #bfbfbf;
	border-right: 4px solid #bfbfbf;
}
.showbox .gallery-teaser-wrapper,
.showbox .teaser-wrapper {
	border: none;
}
.js-on .gallery-stage {
	display: block;
}
.js-on .gallery-stage-box {
	padding: 10px;
	position: relative;
}

/* Carousel in gallery */
.gallery-teaser-wrapper {
	margin: 0 0 0 0;
	background: url(../img/icons/arrow-gallery.png) 50% 0 no-repeat;
	padding: 25px 40px 25px 40px;
	position: relative;
}
.showbox .teaser-wrapper {
	padding: 25px 40px 25px 40px;
	position: relative;
}
.gallery-teaser-wrapper h5,
.showbox .teaser-wrapper h5 {
	margin: 0 0 10px 0;
	font-size: 160%;
	font-weight: bold;
	text-transform: uppercase;
	text-transform: uppercase;
	text-align: center;
}
.gallery-teaser-wrapper .rack-design .teaser,
.showbox .teaser-wrapper .rack-design .teaser {
	position: relative;
	float: left;
	width: 14.8em;
	margin-right: 16px;
}
.js-off .gallery-teaser-wrapper .rack-design .teaser,
.js-off .teaser-wrapper .rack-design .teaser {
	margin-right: 12px;
}
.gallery-teaser-wrapper .rack-design .teaser a,
.showbox .teaser-wrapper .rack-design .teaser a {
	display: block;
	float: left;
	width: 14em;
	border: 4px solid #fff;
	background: #e1e1e1;
}
.gallery-teaser-wrapper .rack-design .teaser a:hover,
.gallery-teaser-wrapper .rack-design .teaser a:focus,
.showbox .teaser-wrapper .rack-design .teaser a:hover,
.showbox .teaser-wrapper .rack-design .teaser a:focus {
	float: left;
	width: 14em;
	border: 4px solid #00549F;
}
.gallery-teaser-wrapper .teaser .copyright,
.showbox .teaser-wrapper .teaser .copyright,
.text .pseudo-gallery .rack-design .teaser span.copyright,
.gallery-teaser-wrapper .teaser .teaser-description,
.showbox .teaser-wrapper .teaser .teaser-description {
	display: none;
}
.gallery-teaser-wrapper .rack-design .teaser.last-teaser,
.showbox .teaser-wrapper .rack-design .teaser.last-teaser {
	margin-right: 0;
}
.gallery-teaser-wrapper .rack-design .teaser img,
.showbox .teaser-wrapper .rack-design .teaser img {
	display: block;
	width: auto;
	height: 10.5em;
	margin: 0 auto;
}
.pseudo-gallery-teaser-wrapper .rack-design .teaser img {
	height: auto;
	/*	width: 100%; Michael Simon [rwth]: Damit in der Bilderauswahl die Hochkantbilder richtig angezeigt werden */

}
div.pseudo-gallery,
div.gallery {
	display:block;
	float:left;
	clear: right;
	width: 100%;
}

/* ddvwinhe 2016-09-15 Graduiertengallerie zu breit - umbruch ab Bild 200 - */
div.pseudo-gallery .rack-design {
	width: 1500% !important
}

.gallery-teaser-wrapper .prev span,
.gallery-teaser-wrapper .next span,
.showbox .teaser-wrapper .prev span,
.showbox .teaser-wrapper .next span {
	display: none;
	position: absolute;
	z-index: 1;
	top: 40%;
	left: -0.4em;
	width: 3.5em;
	height: 3.5em;
	background: #000 url(../img/icons/icon-sprites-1.png) 50% -4194px no-repeat;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 1);
}
.gallery-teaser-wrapper .rack-design,
.showbox .teaser-wrapper .rack-design {
	overflow: hidden;
	width: 10000% !important;
}
/* Michael Simon B.B. [2014.10.14]: Ohne JS liefen die Bilder der Bildergalerie rechts raus. Responsive Design kaputt. */
.js-off .gallery-teaser-wrapper .rack-design,
.js-off .showbox .teaser-wrapper .rack-design {
	width: auto !important;
}
.gallery-teaser-wrapper .prev span:hover,
.gallery-teaser-wrapper .next span:hover,
.showbox .teaser-wrapper .prev span:hover,
.showbox .teaser-wrapper .next span:hover {
	background-color: rgba(0, 84, 159, 1);
}
.gallery-teaser-wrapper .next span,
.showbox .teaser-wrapper .next span {
	background-position: 50% -4394px;
	left: auto;
	right: -0.4em;
}
.js-on .gallery-teaser-wrapper .prev span,
.js-on .gallery-teaser-wrapper .next span,
.js-on .showbox .teaser-wrapper .prev span,
.js-on .showbox .teaser-wrapper .next span {
	display: block;
}
.gallery .multimedia-box,
.showbox-light .multimedia-box {
	position: relative;
	margin: 0 auto;
	/*height: 47.3em;*/
}
.gallery .multimedia-box {
	width: auto;
	height: 46.6em;
}
.gallery .multimedia-box.higher {
	height: 46.6em;
}

.gallery .multimedia-box-wrapper {
	background: #E1E1E1;
}
.gallery .multimedia-box img {
	display: block;
	width: auto;
	height: auto;
	margin: 0 auto;
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.js-off .gallery .multimedia-box img {
	height: 46.5em;
}
.gallery-standalone .multimedia-box img {
	/*max-width: 72.8em; Michael Simon [2013.05.23]: Damit auch im IE8- die Bilder richtig skaliert werden */

}
.gallery .multimedia-box .copyright,
.showbox .multimedia-box .copyright {
	position: absolute;
	right: 0;
	bottom: 0;
	color: #FFF;
	font-size: 110%;
	padding: 4px 4px;
	background: url(../img/bgs/copyright-gallery.png) repeat 0 0;
}
.gallery .multimedia-box .copyright a,
.showbox .multimedia-box .copyright a {
	color: #FFF;
}
.feedback-input-border {
	display: none;
}

.gallery .multimedia-description {
	padding-top: 18px;
}
.gallery .multimedia-description-box {
	overflow: hidden;
	min-height: 9em;
}
#main .gallery ul.links,
.showbox ul.links {
	float: right;
	width: 23.6em;
	background: none;
	margin: 0 0 0 1em;
}
#main .gallery ul.links li,
.showbox ul.links li {
	display: block;
	list-style: none;
	list-style-image: none;
	background: none;
	margin: 0 0 1px 0;
	padding: 0;
}
#main .gallery ul.links li a,
.showbox ul.links li a {
	display: block;
	padding: 4px 5px 4px 25px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	display: block;
}
#main .gallery ul.links li a.download,
.showbox ul.links li a.download {
	background: #e1e1e1 url(../img/icons/icon-sprites-3.png) 5px -2394px no-repeat;
}
#main .gallery ul.links li a.download:hover,
#main .gallery ul.links li a.download:focus,
#main .gallery ul.links li a.download:active,
.showbox ul.links li a.download:hover,
.showbox ul.links li a.download:focus,
.showbox ul.links li a.download:active {
	text-decoration: none;
	color: #fff;
	background: #00549F url(../img/icons/icon-sprites-3.png) 5px -1594px no-repeat;
}
#main .gallery ul.links li a.download:hover em,
#main .gallery ul.links li a.download:focus em,
#main .gallery ul.links li a.download:active em,
.showbox ul.links li a.download:hover em,
.showbox ul.links li a.download:focus em,
.showbox ul.links li a.download:active em {
	text-decoration: none;
	color: #fff;
}
.gallery .multimedia-description-box h2 {
	font-size: 200%;
	font-weight: normal;
	padding: 0;
	margin: 0 0 5px 0;
}
#main .text .gallery .multimedia-description ul {
	padding-left: 0;
}

/* standalone Gallery */
.gallery-standalone {
	clear: none;
	border: none;
	padding: 0;
}
.gallery-standalone .gallery-stage-box {
	padding: 0;
}
.gallery-stage .prev span,
.gallery-stage .next span,
.showbox-box .next span,
.showbox-box .prev span {
	display: none;
	position: absolute;
	z-index: 1;
	top: 0;
	margin-top: 20.1em; /* hälfte von 46.6em box höhe - hälfte von 6.4em elementhöhe */
	left: 10px;
	width: 6.4em;
	height: 6.4em;
	background: #000 url(../img/icons/teaser-wrapper-small-sprite.png) no-repeat scroll 50% 10px;
	cursor: pointer;
	text-indent: -4000px;
}

.gallery-stage .prev span,
.gallery-stage .next span {
	/* margin-top: -8.8em; */
}

.showbox-box .next span,
.showbox-box .prev span {
	left: 0;
	top: 50%;
	margin-top: -3.2em;
}
.gallery-stage .prev span.show:hover,
.gallery-stage .next span.show:hover,
.showbox-box .prev span:hover,
.showbox-box .next span:hover {
	background-color: rgba(0, 84, 159, 1);
}
.showbox-box .prev.ui-disabled span:hover,
.showbox-box .next.ui-disabled span:hover {
	background-color: #000;
}
.gallery-stage .next span,
.showbox-box .next span {
	background-position: 50% -187px;
	left: auto;
	right: 11px;
}
.showbox-box .next span {
	right: 0;
}
.gallery-standalone .gallery-stage .prev span,
.gallery-standalone .gallery-stage .next span {
	left: 0;
}
.gallery-standalone .gallery-stage .next span {
	left: auto;
	right: 0;
}
.js-on .gallery-stage .prev span,
.js-on .gallery-stage .next span,
.js-on .showbox-box .prev span,
.js-on .showbox-box .next span {
	display: block;
}
.opacity .gallery-stage .prev.ui-disabled span,
.opacity .gallery-stage .next.ui-disabled span,
.opacity .showbox-box .prev.ui-disabled span,
.opacity .showbox-box .next.ui-disabled span {
	opacity: 0.4;
}
.gallery-standalone .gallery-teaser-wrapper {
	padding: 12px 0 0 0;
	background: url(../img/bgs/arrow-standalone-gallery.png) 50% 0 no-repeat;
}
.gallery-standalone .gallery-teaser-wrapper-box {
	border: 4px solid #bfbfbf;
	border-top: 0;
	padding: 25px 40px;
}
.gallery-standalone .gallery-teaser-wrapper .prev span,
.gallery-standalone .gallery-teaser-wrapper .next span {
	display: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	margin-top: -1.2em;
	left: 0;
	width: 3.5em;
	height: 3.5em;
	background: #000 url(../img/icons/icon-sprites-1.png) 50% -4194px no-repeat;
	cursor: pointer;
}
.gallery-standalone .gallery-teaser-wrapper .prev.show span:hover,
.gallery-standalone .gallery-teaser-wrapper .next.show span:hover {
	background-color: rgba(0, 84, 159, 1);
}
.js-on .gallery-standalone .gallery-teaser-wrapper .prev span,
.js-on .gallery-standalone .gallery-teaser-wrapper .next span {
	display: block;
}
.gallery-standalone .gallery-teaser-wrapper .next span {
	background-position: 50% -4394px;
	left: auto;
	right: 0;
}

/* Michael Simon [2017.05.30]: testimonialgallery */
#main .testimonialgallery .multimedia-box-wrapper {
	position: relative;
	height: 46.6em;
}

#main .testimonialgallery .testimonial-left {
	bottom: 0;
	left: 6.4em;
	overflow: hidden;
	position: absolute;
	right: 50%;
	top: 0;
	background-color: black;
}
#main .testimonialgallery .testimonial-left img {
	position: absolute;
	left: 50%;
	top: 0;
	height: 100%;
	transform: translate(-50%, 0);
	max-width: none;
}

#main .testimonialgallery .testimonial-right {
	bottom: 0;
	box-sizing: border-box;
	left: 50%;
	overflow: auto;
	position: absolute;
	right: 6.4em;
	top: 0;	
}
#main .testimonialgallery .testimonial-right .multimedia-description {
	padding: 0;
}
#main .testimonialgallery .testimonial-right blockquote {
	background-image: none;
	margin: 10px;
	padding-left: 10px;
	width: auto;
}
/*  *************************************++
Faculties */
#faculty-stage {
	margin: 0 auto;
	padding: 20px;
	max-width: 98em;

}
#faculty-stage #stage {
	display: table-cell;
	margin: 0;
	margin-bottom: 10px;
	width: 100%;
	position: relative;
	max-width: 0;
}
#faculty-stage #stage .teaser-wrapper {
	margin-right: 28px;
}
#faculty-stage #stage .teaser-wrapper .teaser {
	max-width: 72.8em;
	min-width: 100%;
	/*width: 100%;*/
}
.js-on #faculty-stage #stage .rack {
	height: 41em;

}
#faculty-stage #stage .prev span,
#faculty-stage #stage .next span {
	display: none;
	position: absolute;
	z-index: 1;
	top: 16em;
	left: 0;
	width: 64px;
	height: 64px;
	background: #000 url(../img/icons/teaser-wrapper-small-sprite.png) 50% 10px no-repeat;
	background-color: rgba(0, 0, 0, 0.8);
	cursor: pointer;
}
#faculty-stage #stage .prev span:hover,
#faculty-stage #stage .next span:hover {
	background-color: #00539F;
}
#faculty-stage #stage .next span {
	background-position: 50% -188px;
	left: auto;
	right: 0;
}
.js-on #faculty-stage #stage .prev span,
.js-on #faculty-stage #stage .next span {
	display: block;
}
.js-on #faculty-stage #stage .prev span.show,
.js-on #faculty-stage #stage .next span.show {
	display: block;
}
#faculty-stage #stage .teaser-box-2 {
	background: #00549F;
	background-color: rgba(0, 84, 159, 1); /* Michael Simon A.A. [2014.08.06]: Transparenz wegen neuer Buehnenlogik entfernt. */
	bottom: 0;
	left: 0;
	padding: 25px;
	position: absolute;
	max-width: 55.2em
}
#faculty-stage #stage .teaser-wrapper h2 {
	font-size: 350%;
	width: auto;
	float: none;
}

#faculty-stage #stage .teaser-wrapper h2 span {
	display: inline;
}
#faculty-stage #stage .teaser-wrapper h2 .more {
	display: inline-block;
	margin-top: 0;
	position: relative;
	top: -10px;
	font-style: normal;
}
#faculty-stage #stage .teaser-wrapper h2 .more a {
	display: inline-block;
	line-height: 1.5em;
	min-width: 60px;
	background: #000 url(../img/icons/icon-sprites.png) 83% -2790px no-repeat;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 6px 20px 6px 10px;
	text-transform: uppercase;
	font-size: 30%; /* 12px */
	font-weight: bold;
}
#faculty-stage #stage .teaser-wrapper h2 .more a:hover,
#faculty-stage #stage .teaser-wrapper h2 .more a:active,
#faculty-stage #stage .teaser-wrapper h2 .more a:focus {
	background: #fff url(../img/icons/icon-sprites.png) 83% -2990px no-repeat;
	color: #000;
	text-decoration: none;
	border: none;
}
#quick-access {
	display: table-cell;
	vertical-align: top;
	margin: 0;
	min-width: 22.4em;
	position: relative;
	max-width: none;
	background: #FFFFD9;
	min-height: 41em;
}
#quick-access h6,
#quick-access h2 {
	padding: 1.11em 0.56em;
	background: #FFEF58;
	font-size: 180%;
	color: #292929;
	text-align: center;
}
#quick-access ul {
	background: url(../img/bgs/border-50.png) repeat-x 0 0;
	margin: 1.39em 0.56em 0.83em 0.56em;
	padding: 1px 0 0 0;
}
#quick-access ul li {
	padding: 1px 0 2px 0;
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
	list-style: none;
}
#quick-access ul li a {
	font-size: 140%;
	color: #000;
	display: block;
	padding: 0.67em 0 0.67em 27px;
	background: url(../img/icons/icon-sprites.png) no-repeat 5px -788px;
}
#quick-access ul li a:hover,
#quick-access ul li a:focus,
#quick-access ul li a:active {
	background-color: #E5E1BE;
	text-decoration: none;
}
/* ************************
Studiengangsbeschreibung */
.text-media-block {
	padding-left: 380px;
	padding-bottom: 20px;
}
.text-media-block:last-child {
	background: url(../img/bgs/border-50.png) repeat-x 0 100%;
}
.text-media-block .photo,
.text-media-block .ct-video {
	margin-left: -380px;
	float: left;
}
.text-media-block .ct-video  {
	width: 350px;
}
.text-media-block .ct-video .media-player {
	width: 100%;
}
.text-media-block .ct-video .media-state {
	left: 37%;
}
.text-media-block .text-block video {
	display: block;
	width: 350px;
	height: 200px;
}
.text-media-block .more {
	font-style: normal;
	background: none;
}
.text-media-block .more a {
	background: url(../img/icons/icon-sprites.png) 0 0 no-repeat;
	padding-left: 20px;
}
/* showbox */
.mask {
	background: rgb(0,0,0);
}
.showbox {
	height: 100%;
	left: 0;
	overflow-x: auto;
	overflow-y: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 101;
}

html.overlay-visible,
body.overlay-visible {
	/*overflow: hidden;
	height: 100%;*/
}

.showbox--single-image {

}
.showbox .showbox-box {
	max-width: 880px;
	width: 80%;
	margin: 0 auto 9em;
	padding-top: 20px;

	/*height: 535px;*/
	padding-bottom: 19.5em;
}
.showbox-light .showbox-box {
	position: absolute;
	top: 20px;
	bottom: 20px;
	left: 0;
	right: 0;
	height: auto;
	width: auto;
	padding: 0;
	margin: 0 auto;
}
.showbox .content-box {
	margin: 0 auto;
	max-width: 980px !important;
	width: 100% !important;
	height: auto !important;
	border: 4px solid #e5e5e5;
	position: relative;
	background: #e1e1e1;
	opacity: 1 !important;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing:border-box;
}
.shwobox .multimedia-box {
	position: relative;
}

.showbox--single-image .showbox-box {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	bottom: 0;
	top: 0;
	height: 100%;
	margin: 0;
	max-width: 100%;
	padding: 20px;
	position: relative;
	width: 100%;
}

.showbox--gallery .showbox-box {
	-webkit-transition: padding 400ms cubic-bezier(0.470, 0.000, 0.745, 0.715);
	-moz-transition: padding 400ms cubic-bezier(0.470, 0.000, 0.745, 0.715);
	-o-transition: padding 400ms cubic-bezier(0.470, 0.000, 0.745, 0.715);
	transition: padding 400ms cubic-bezier(0.470, 0.000, 0.745, 0.715);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	height: 100%;
	left: 50%;
	margin: 0;
	min-height: 170px;
	padding: 20px 0 180px;
	position: absolute;
	top: 0;
	width: 100%;
}

.showbox--gallery.showbox--scroller-expanded .showbox-box {
	min-height: 320px;
	padding-bottom: 320px;
}


.showbox--single-image .content-box {
	position: relative;

	background-color: transparent;
	border: none;
	display: block;
	margin: 0;


	text-align: center;
	width: 100% !important;
	height: 100% !important;
	max-width: 100% !important;
	max-height: 100% !important;
}

.showbox--gallery .showbox__wrapper {
	height: 100%;
}

.showbox--gallery .content-box {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 100% !important;
	text-align: center;
	vertical-align: bottom;
	width: auto !important;

}

.showbox--single-image .multimedia-box {
	margin: 0;
	text-align: center;
}

.showbox .multimedia-box img {
	display: block;
	margin: 0 auto;
	max-width: 100%;
	/*width: 100% !important;*/
	/*height: auto !important;*/
	max-height: 100%;
	position: relative;
	/*left:0 !important;*/
}

.showbox .showbox__image {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 4px solid #e5e5e5;
	position: relative;
	display: inline;
	margin: 0;
	max-height: 100%;
	max-width: 100%;
}

.showbox--gallery .multimedia-box {
	display: inline;
	text-align: center;
	vertical-align: bottom;
	/*-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	position: relative;*/
	/*top: 50%;*/
}

.showbox--gallery .multimedia-box img {
	display: inline;
	width: auto;
	height: auto;

}

.showbox--gallery .multimedia-description {
	height: 45px;
}

.showbox--gallery .multimedia-description-box {
	overflow: hidden;
}

.showbox--gallery .multimedia-description-box .title {
	line-height: 1.1em;
	height: 2.2em;
	margin-right: 10px;
	overflow: hidden;
	text-overflow: ellipsis;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.showbox .close-button {
	position: absolute;
	right: -4px;
	top: -4px;
	text-indent: -9999px;
	width: 36px;
	height: 36px;
	background: url(../img/icons/icon-sprites-3.png) no-repeat scroll 0 -4000px transparent;
	cursor: pointer;
	z-index:1;
	text-indent: -9999px;
}
.showbox .close-button:hover {
	background-position: 0 -4200px;
}

.showbox--single-image .close-button {
	top: auto;
	right: auto;
	margin-left: -36px;

}
.showbox .scroller-holder {
	background-color:#2e2e2e;
	position: fixed;
	left: 0;
	width: 100%;
	bottom: 0;
	overflow: visible; /* there is an invisible click area above the button, so we need the overflow */
	z-index: 2;
}
.showbox .scroller-holder .toggle {
	font-size: 120%;
	text-transform: uppercase;
	color: #fff;
	background-color: #000;
	display: block;
	text-align: center;
	padding: 15px 0;
	cursor: pointer;
	font-weight: bold;
}

.showbox .scroller-holder .toggle:after {
	bottom: 100%;
	content: '';
	display: block;
	height: 4em;
	left: 0;
	position: absolute;
	width: 100%;
}

.showbox .gallery-teaser-wrapper,
.showbox .teaser-wrapper {
	margin: 0 auto;
	max-width: 900px;
	background: none;
	padding-top: 10px;
	padding-bottom: 10px;
}
.showbox .multimedia-description {
	overflow: hidden;
	padding: 25px 10px 10px;
	background: #fff;
}
.showbox .multimedia-description h2 {
	float: left;
	max-width: 225px;
	font-size: 200%;
	font-weight: normal;
}
.showbox .multimedia-description p {
	overflow: hidden;
	font-size: 140%;

	line-height: 1em;
	height: 3em;
	overflow: hidden;
	text-overflow: ellipsis;

	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.showbox .multimedia-description .links {
	float: right;
	margin-top: 0;
}
.showbox .multimedia-description .links a.download {
	font-size: 140%;
}
.showbox .gallery-teaser-wrapper h5 {
	display: none;
}


/* ************************
Small Window */
.smaller-than-800 #nav-global {
	margin-top: 0;
}
/* tables */
/* Michael Simon A.A. [2014.07.18]: Unsere alten CSS-Selectoren für Tablesorter griffen nicht mehr und mussten aktualisiert werden. */
table thead tr .header,
table thead tr .tablesorter-header {
background-image: url(/custom/rwth/img/tablesorter/bg.gif) !important;
	background-repeat: no-repeat !important;
	background-position: center right !important;
	cursor: pointer !important;
}
table thead tr .headerSortUp,
table thead tr .tablesorter-headerAsc {
background-image: url(/custom/rwth/img/tablesorter/asc.gif) !important;
}
table thead tr .headerSortDown,
table thead tr .tablesorter-headerDesc {
background-image: url(/custom/rwth/img/tablesorter/desc.gif) !important;
} 

/* RWTH TEST! BILD-TEXT-MODULE MIT FARBIGEN HINTERGRÜNDEN */
div.text-image-wrapper {
	width: 100%;
	clear: both;
	padding: 20px 20px 1px;
	box-sizing: border-box;
	margin: 0 0 20px;
}
div.text-image-wrapper::after {
	content: "";
	display: block;
	clear: both;
}
div.text-image-wrapper.grey {
	background-color: #EDEDED;
}
div.text-image-wrapper.yellow {
	background-color: #FFE57F;
}
div.text-image-wrapper.blue {
	background-color: #00549F;
}
div.text-image-wrapper.blue .aw_ce_wrapper > ul li,
div.text-image-wrapper.blue .aw_ce_wrapper > ol li,
div.text-image-wrapper.blue .aw_ce_wrapper > h2,
div.text-image-wrapper.blue .aw_ce_wrapper > blockquote p,
div.text-image-wrapper.blue .aw_ce_wrapper > blockquote a,
div.text-image-wrapper.blue .aw_ce_wrapper > p,
div.text-image-wrapper.blue .aw_ce_wrapper > p a,
div.text-image-wrapper.blue > ul li,
div.text-image-wrapper.blue > ol li,
div.text-image-wrapper.blue > h2,
div.text-image-wrapper.blue > blockquote p,
div.text-image-wrapper.blue > blockquote a,
div.text-image-wrapper.blue > p,
div.text-image-wrapper.blue > p a {
	color: white !important;
}
#main .text div.text-image-wrapper.blue > ul li {
	list-style-image: url(../img/icons/list-image-white.gif);
}
div.text-image-wrapper.grey + div.text-image-wrapper.grey,
div.text-image-wrapper.yellow + div.text-image-wrapper.yellow,
div.text-image-wrapper.blue + div.text-image-wrapper.blue {
	margin-top: -20px;
	padding-top: 0;
}

/* Podcast-Player-CSS */
.podcast-disclaimer {
	border: 1px solid #333;
	padding: 10px;
	margin-bottom: 20px;
}

.podcast-disclaimer::after {
	content: "";
	clear: both;
	display: block;
}

.podcast-disclaimer button {
	float: right;
	background-color: #00549F;
	color: #FFF;
	border: 0;
	padding: 3px 6px;
	cursor: pointer;
}
.podcast-disclaimer button:hover {
	background-color: #2985DB;
}

.podcast-disclaimer.hidden,
.podcast-player-container.hidden {
	display: none;
}

.podcast-player-container .wpb_raw_html {
    max-height: 145px; /* This is a fix for iPhone/Safari gap */
}
.podcast-player-container .podcast-iframe.anchor-fm .wpb_raw_html {
	max-height: 98px; /* This is a fix for iPhone/Safari gap */
}
 
.podcast-player-container .wpb_wrapper {
  line-height: 0;
}
 
.podcast-player-container .podcast-iframe {
	width: 100%;
}

.podcast-player-container .podcast-iframe.anchor-fm {
	height: 98px;
	float: left;
	border-radius: 5px 5px 0 0;
}
 
.styles__episodeFeed___3mOKz {
	position: relative;
	background-color: #E6E6E6;
	padding: 10px;
	margin-bottom: 20px;
	display: none;
	color: #333;
	clear: both;
	border-radius: 0 0 5px 5px;
}
 
.styles__episodeFeedItem___1U6E2 {
	color: #333;
	background: #fff;
	border-radius: 5px;
	padding: 14px 12px;
	overflow: hidden;
	position: relative;
	margin-bottom: 10px;
}
.styles__episodeFeedItem___1U6E2:last-child {
	margin-bottom: 0;
}
 
.styles__episodeFeedItem___1U6E2 a {
  color: inherit;
  text-decoration: none;
  text-align: left;
}
 
.styles__episodeFeedItem___1U6E2 .styles__episodeImage___tMifW {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  float: left;
  margin: 0 6px 0 0;
  position: relative;
  width: 47px;
  height: 47px;
}
 
.styles__circle___1g-9u {
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.styles__white___372tQ {
  background: #fff;
}
 
.styles__episodeFeedItem___1U6E2 .styles__episodeImage___tMifW img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  vertical-align: middle;
}
 
.styles__playButton___1Ivi4 {
  cursor: pointer;
  text-align: center;
  padding: 0;
}
 
.styles__playButton___1uaGA {
  z-index: 0;
}
 
.styles__playButton___1Ivi4 svg {
  overflow: visible;
}
 
.styles__episodeFeedItem___1U6E2 a {
  color: inherit;
  text-decoration: none;
}
 
.styles__episodeFeedItem___1U6E2 .styles__episodeHeading___29q7v {
	font-weight: normal;
	font-size: 14px;
	color: #292f36;
	margin-bottom: 4px;
	max-width: calc(100% - 150px);
}
 
.styles__episodeFeedItem___1U6E2 .styles__episodeDescription___C3oZg {
  padding-right: 2px;
  text-align: left;
  max-width: calc(100% - 100px);
}
 
.styles__episodeFeedItem___1U6E2 .styles__episodeDescription___C3oZg, .styles__episodeFeedItem___1U6E2 p {
  color: rgba(41,47,54,.7);
  font-size: 8px;
  line-height: 9px;
}

#main .styles__episodeFeedItem___1U6E2 p {
	padding-bottom: 0;
}

.styles__episodeFeedItem___1U6E2 .styles__episodeCreated___1zP5p, .styles__episodeFeedItem___1U6E2 .styles__episodeDuration___2I0Qb {
  margin-top: 8px;
  font-size: 11px;
  line-height: 13px;
  color: #c9cbcd;
}
 
.styles__episodeFeedItem___1U6E2 .styles__episodeDuration___2I0Qb {
	position: absolute;
	right: 7px;
	bottom: 5px;
}
 
.styles__episodeFeedItem___1U6E2 .styles__episodeCreated___1zP5p, .styles__episodeFeedItem___1U6E2 .styles__episodeDuration___2I0Qb {
  margin-top: 8px;
  font-size: 11px;
  line-height: 13px;
  color: #777;
}
 
.styles__episodeFeedItem___1U6E2 .styles__episodeCreated___1zP5p {
	position: absolute;
	top: 0;
	right: 7px;
}
 
.styles__episodeFeed___3mOKz {
  display: block;
}