// Utilities
@import "../../variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";


.events-box {
    font-size: .95em;
    text-align: left;
    // max-width: 290px;
    padding: 20px;
    margin: 15px auto;
    border: 1px solid $color1;
    border-radius: 20px;
    overflow: hidden;
    .events-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        height: 100%;
    }
    .box-title {
        min-height: 2.4em;
    }
    .events-img {
        @include img-container(110px, 'cover');
        margin: 10px 0 0;
        @include media-breakpoint-up(lg) { 
            @include img-container(110px, 'cover');
        }
        @include media-breakpoint-up(xl) { 
            @include img-container(70%, 'cover');
        }
        &.contain {
            @include img-container(110px, 'contain');
            @include media-breakpoint-up(lg) { 
                @include img-container(110px, 'contain');
            }
            @include media-breakpoint-up(xl) { 
                @include img-container(70%, 'contain');
            }
        }
        img {
        }
    }
    .events-sum {
        // font-size: 1.1em;
        line-height: 1.6;
        display: inline-flex;
        flex-direction: row;
        flex-grow: 1;
        margin-top: 10px;
        .newsletter-sum {
            font-size: .9em;
            // padding: 8px 0;
            // border-top: 1px solid rgba($color2,.3);
            // border-bottom: 1px solid $color1;
            margin: 0 0 10px;
            ul {
                margin-bottom: 0;
            }
        }
    }
    .events-date {
        color: $color2;
        min-height: 1.5em;
        margin-top: 10px;
    }
    &.style2 {
        .box-title {
            color: $color1;
            font-weight: 400;
            text-align: center;
            min-height: 1.2em;
        }
        .anchor-style1 {
            font-size: 1.4em;
            text-align: center;
            margin: 0;
            border: 0;
        }
        .events-img {
            margin-bottom: 10px;
        }
    }
    &.event-slider {
        .events-sum {
            flex-direction: column;
        }
        .slick-slider {
            height: calc(100% - 30px);
            .slick-list {
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .slick-dots {
                // bottom: 5px;
                li {
                    margin: 0;
                    transform: scale(0.8);
                }
            }
            .slick-arrow {
                width: 20px;
                height: 20px;
                &:before {
                    // opacity: 1;
                }
            }
            .slick-prev {
                left: -20px;
            }
            .slick-next {
                right: -20px;
            }
        }
    }
}

.news-box {
    .events-sum {
        border-bottom: 1px solid $color1;
    }
}

//For slider
.box-slider {
    .slick-slider {
        padding: 0 20px;
        .slick-prev {
            left: 0;
        }
        .slick-next {
            right: 0;
        }
    }
    .slick-track {
        display: flex;
        align-items: stretch;
        .slick-slide {
            display: inline-flex;
            float: none;
            height: auto;
            padding: 10px;
            & > div {
                height: 100%;
                width: 100%;
            }
            .events-box {
                height: 100%;
                // min-height: 500px;
                margin: auto;
                .box-title {
                    min-height: 3.8em;
                }
            }
        }
    }
}