// Utilities
@import "./variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

html {
  overflow-x: hidden;
}

body {
  font-size: 16px;
  line-height: 1.5;
  background: #fff;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  image-orientation: from-image;
}

.header-container {
  max-width: calc(#{$max_width_0} + 210px);
  margin: 0 auto;
}

.footer-container {
  max-width: calc(#{$max_width_0} - 0px);
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.container {
  max-width: calc(#{$max_width_0} + 30px);
}

.container-2 {
  width: 100%;
  max-width: calc(#{$max_width_0} + 60px);
  padding: 50px 0;
  margin: 0 auto;
  // background: #fff;
}

.fullscreen-width {
  position: relative;
}

.horizontal-sep {
  height: 1px;
  width: 100%;
  margin: 40px 0;
  background: $color1;
}

.hidden {
  display: none;
}

.bg-darkblue {
  color: #fff;
  background-color: $color1;

  a,
  .box-title {
    color: #fff;
  }
}

.bg-grey {
  background-color: rgba($color2, .15);
}

.rounded {
  overflow: hidden;
}

.table {
  border-bottom: 1px solid rgba($color2, 0.15);
}

.table-lg {

  th,
  td {
    padding: 5px;
  }
}

.exam-form {
  .input-group-prepend {
    flex-basis: 200px;

    .input-group-text {
      color: white;
      width: 100%;
      background-color: $color1;
    }
  }
}

.scrollTop {
  color: #fff;
  font-size: 1.4em;
  position: fixed;
  right: 15px;
  bottom: 70px;
  width: 35px;
  height: 35px;
  // border-radius: 50%;
  background-color: $color1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 990;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  line-height: 1.2;

  a[href] {
    color: inherit;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid transparent;

    &:hover {
      text-shadow: 1px 1px rgba($color1, .5);
      text-decoration: none;
      border-bottom: 1px solid $color1;
    }
  }
}

h1 {
  font-size: 2.8em;
}

h2 {
  font-size: 2.2em;
}

h3 {
  font-size: 1.8em;
}

h4 {
  font-size: 1.6em;
}

h5 {
  font-size: 1.4em;
}

h6 {
  font-size: 1.2em;
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}

ol,
ul {
  margin-left: 1em;
}

.heading {
  color: $color1;
  font-weight: 200;
  text-align: left;
  margin-bottom: 15px;
  border-bottom: 1px solid $color1;

  .sub {
    color: $color2;
  }

  a {
    font-weight: 500;
    text-decoration: underline;
  }
}

iframe {
  max-width: 100%;
}

.box-title {
  color: $color2;
  font-weight: 600;

  a[href] {
    color: $color1;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      text-shadow: none;
      border-bottom-color: transparent;
    }
  }
}

.sub {
  font-size: .7em;
  margin: 0px 4px;
}

.link {
  color: $color1;

  &:hover {
    text-decoration: underline;
  }
}

.anchor-style1 {
  font-size: 1.2em;
  display: block;
  width: 100%;
  padding: 5px 10px;
  margin: 10px 0;
  border-bottom: 1px solid $color1;
}

.btn {
  text-transform: capitalize;
}

.btn-primary,
a.btn-primary {
  color: #fff;
  text-decoration: none !important;
  border: 1px solid $color1;
  background: $color1;

  &:hover {
    color: $color1;
    background: transparent;
  }
}

.btn-link {
  color: $color1;
}

.thead-color1 {
  color: #fff;
  background-color: $color1;
}

.after-comma:not(:last-of-type) {
  &::after {
    content: ', ',
  }
}

#wrapper {

  label,
  input,
  textarea {
    font-size: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  }
}

// Header & Topbar
#header {
  position: absolute;
  z-index: 100;
  width: 100%;

  // overflow-x: hidden;
  .header-container {
    position: relative;

    &::before {
      // content: '';
      position: absolute;
      z-index: -1;
      top: -166px;
      left: 0;
      width: 580px;
      height: 150px;
      border-bottom-right-radius: 600px 22px;
      box-shadow: 0 150px 0 0 rgba(white, .7);
      background-color: transparent;
    }

    &::after {
      // content: '';
      position: absolute;
      z-index: -1;
      left: 580px;
      top: 88px;
      width: 1000px;
      height: 160px;
      border-top-left-radius: 650px 24px;
      box-shadow: 0 -120px 0 0 rgba(white, .7);
      background-color: transparent;
    }
  }
}

#topbar {
  color: #f2f2f2;
  height: 40px;
  background: #333;

  .topbar-menu-container {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: $max_width_0;
    margin: 0 auto;

    .topbar-menu {
      display: block;
      float: right;
      list-style-type: none;
      padding: 6px 0;
      margin: 0;

      .menu-item {
        display: inline-block;
        padding: 0 0px 0 20px;

        a {
          display: inline-block;
          border-radius: 5px;
          overflow: hidden;

          img {
            height: 26px;
            opacity: .8;
            transform: scale(0.9);

            &:hover {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

#navigator {
  left: 0;
  right: 0;
  max-width: $max_width_0;
  margin: 0 auto;

  h2 {
    display: block;
    display: inline-block;
    position: static;
    color: #fff;
    bottom: auto;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 12px 0px 12px 0px;
    // margin-left: 250px;
    vertical-align: baseline;
  }

  p {
    display: inline-block;
    font-size: 10px;
    // vertical-align: middle;
  }

  .separator {
    display: inline-block;
    width: 1px;
    height: 14px;
    margin: 0 20px;
    background-color: #fff;
    vertical-align: text-bottom;
  }
}

#branding {
  border-top: 0;
  background: transparent;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100vw;
    top: 0;
    right: 100%;
    background: $header_bg;
  }

  img {
    height: 100%;
  }

  #branding-box {
    max-width: $max_width_0;
  }

  #branding-inner {
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: 30px 20px;
    // border-right: 1px solid transparent;
    border-bottom-right-radius: 20px;
    border-bottom-right-radius: 60px 24px;
    background: $header_bg;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      right: -100vw;
      top: 91px;
      width: 100vw;
      height: 160px;
      border-top-left-radius: 60px 24px;
      box-shadow: 0 -120px 0 0 rgba(white, .7);
      background-color: transparent;
    }
  }

  .vertical-separator {
    display: block;
    height: $header_logo_height;
    width: 2px;
    margin: 0 15px;
    background-color: $color1;
  }

  #branding-logo {
    // position: relative;
    // top: -56px;
    display: block;
    height: $header_logo_height;
    max-width: 350px;

    // padding: 0 20px;
    // border-width: 1px 1px;
    // border-style: solid;
    // border-color: #ccc;
    // background-color: #fff;
    img {
      height: $header_logo_height;
    }
  }

  .ime-logo-container {

    // margin-right: 20px;
    .ime-logo {

      // display: block;
      // float: left;
      img {
        height: $header_logo_height;
        max-width: 350px;
        // margin-top: -38px;
      }
    }
  }

  .header-right {
    text-align: right;
    flex-grow: 1;

    #nav-meta {
      position: static;

    }

    .header-right-menu {
      display: block;
      float: right;
      list-style-type: none;
      padding: 6px 0;
      margin: 0;

      .menu-item {
        display: inline-block;
        padding: 0 0px 0 20px;

        a {
          display: inline-block;
          border-radius: 5px;
          overflow: hidden;

          img {
            height: 26px;
            opacity: .8;
            transform: scale(0.9);

            &:hover {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }
  }

}

// Header Navigator
#mobile-nav-global {
  display: none;
}

#nav-global,
#mobile-nav-global {
  flex-grow: 1;
  padding: 10px 15px;
  // background: $header_bg;
  background: transparent;
  position: relative;

  h2 {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 100%;
    background: $header_bg;
  }

  .nav-toggle {
    display: none;
    color: #fff;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    height: 30px;
    background-color: $color2;
    transition: all .3s ease-in;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 18px;
    right: 18px;

    &:hover {
      background-color: $color1;
    }

    .fa-times {
      display: none;
    }
  }

  .ime-logo-container {
    position: relative;
    height: 0px;
    width: 100%;
    max-width: $max_width_0;
    margin: 0 auto;

    .ime-logo {

      // display: block;
      // float: left;
      img {
        width: 250px;
        // margin-top: -38px;
      }
    }
  }

  .nav-global-main {
    color: #fff;

    .dropdown-menu {
      text-align: center;
      width: max-content;
      width: fit-content;
      border-radius: 0;
      // padding: 0;
      left: -50%;
      right: -50%;
      padding: 0;
      margin: auto;
      transform: translate(-50px, 30px) !important;

      @include media-breakpoint-up(lg) {
        text-align: left;
        // padding: 10px 0;
        left: 0;
        margin: 0;
        transform: translate(-20px, 30px) !important;
      }

      .dropdown-item {
        display: block;
        width: auto;
        padding: 0.5rem 10px;
        margin: 0 !important;

        a {
          font-size: .9em;
          width: 100%;
        }
      }
    }

    ol.toc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: $max_width_0;
      padding: 4px 5px;
      padding: 4px 0;
      margin: 0;
      // border-top: 5px solid rgba($color1,.7);
      background: #333;
      background-color: $color2;

      li {
        display: inline-block;
        padding: 0px 0px;
        margin: 0 10px 0px 10px;

        a {
          display: inline-block;
          color: #fff;
          // font-size: 1.1em;
          font-weight: 500;
          text-decoration: none;

          &.dropdown-toggle {
            padding: 0 0 0 5px;
          }
        }
      }

      &.style2 {
        justify-content: flex-end;
        min-width: 500px;
        padding: 0;
        border: 0;
        background-color: transparent;

        li {
          margin: 0 8px;

          a {
            color: #111;
            font-family: 'Roboto', serif;

            // font-weight: 600;
            // text-transform: uppercase;
            &:hover {
              text-shadow: 1px 1px rgba($color2, .4);
            }
          }
        }
      }

      &.style3 {
        min-width: 170px;
        max-width: 180px;
        padding: 0;
        position: relative;
        z-index: 1;

        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          top: -10px;
          left: -15px;
          bottom: -10px;
          width: 100vw;
          height: auto;
          background-color: $color1;
        }
      }
    }
  }

  #nav-icons {
    float: right;
    // margin-left: 50px;
    margin: 0 !important;

    .header-right-menu {
      display: block;
      float: right;
      list-style-type: none;
      padding: 0;
      margin: 0;

      .menu-item {
        display: inline-block;
        padding: 0 5px 0 5px;
        margin: 0;

        a {
          display: inline-block;
          display: table-cell;

          img {
            height: $header_icon_height;
            width: $header_icon_height;
            border-radius: 5px;
            overflow: hidden;
            opacity: .8;
            transform: scale(0.9);

            &:hover {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

//Side Navigation
.side-nav {
  .heading {
    color: $color2;
    border-bottom: 0;
  }

  .nav {
    .nav-item {
      font-size: 1.1em;
      list-style: disc;
      text-indent: -10px;
    }
  }
}

// Home Slider
.header-banner {
  position: relative;
  overflow: hidden;

  .slider-overlay {
    position: absolute;
    z-index: 98;
    bottom: 0px;
    right: 0px;
    width: 28%;
    height: 28%;
    max-width: 340px;
    max-height: 158px;
    min-width: 200px;
    min-height: 100px;

    .slider-overlay-wrap {
      height: 100%;
      padding: 30px 0px 20px 30px;
      // border-top-left-radius: 45% 50%;
      // border-bottom-left-radius: 45% 50%;
      border-top-left-radius: 120px 72px;
      background: rgba(white, .5);

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        right: 100%;
        top: -35px;
        width: 100vw;
        height: 100%;
        border-bottom-right-radius: 120px 60px;
        box-shadow: 0 100px 0 0 rgba(white, .5);
        background-color: transparent;
      }

      img {
        height: 100%;
        max-height: 100px;
      }
    }
  }

  .wrapper {
    .video {
      position: relative;
      z-index: 1;
      top: 0;
      width: 100%;
      height: 0;
      padding-bottom: 42.18%;
      overflow: hidden;
    }
    .image {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 300px;
      overflow: hidden;

      .overlay {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.dark {
          background-color: rgba(black, .5);
        }
      }

      img {
        position: absolute;
        top: -999px;
        right: -999px;
        bottom: -999px;
        left: -999px;
        margin: auto;
        min-width: 100%;
        max-width: 110%;
      }
    }
  }

  .home & .wrapper .image {
    padding-bottom: 50%;
  }
}

// Left Sidebar
.left-nav {
  #nav-box {
    .sidebar-nav {
      margin: 0 0 3rem;
      border-top: 4px solid #000;
      border-bottom: 1px solid #111;

      .menu-item-strong {
        &>a {
          color: #333;
          font-size: 180%;
          font-weight: bold;
          text-transform: uppercase;
          padding: 13px 10px;
          border-bottom: 1px dotted #555;
        }
      }

      &>li {
        background: none;

        ul {
          margin-left: 20px;

          li {
            min-height: 3rem;
          }
        }
      }

      li:last-child {
        background: none;
      }
    }
  }
}

// Right Sidebar
.right-nav {
  &#nav {
    width: 40%;
    padding-left: 30px;
    padding-right: 30px;
  }

  #nav-box {
    .sidebar-nav {
      margin: 0 0 50px;

      // border-top: 4px solid #000;
      // border-bottom: 1px solid #111;
      // background: #f2f2f2;
      .menu-item-strong {
        &>h5 {
          color: #eee;
          font-size: 1.5em;
          font-weight: bold;
          text-transform: uppercase;
          padding: 13px 10px;
          margin: 0;
          background: $color1;
        }

        &>a {
          color: #333;
          font-size: 120%;
          font-weight: bold;
          text-transform: uppercase;
          padding: 13px 10px;
          border-bottom: 1px dotted #555;
        }
      }

      &>li {
        background: none;

        ul {
          // border-top: 1px solid #fff;
          // border: 1px solid #ccc;
          border-top: 0;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;

          li {
            min-height: 2.4rem;
            background: none;

            // border-bottom: 1px solid #ddd;
            a {
              // color: $color1;
              text-decoration: underline;
              padding: 10px 10px;
              padding-left: 0;
              transition: all .2s ease-in;
              background: transparent;

              &:hover {
                padding-left: 10px;
                background: $color2;
              }
            }
          }
        }
      }

      li:last-child {
        background: none;
      }
    }
  }
}

// Main Content
.home {
  width: 100%;
  padding: 0px 0;
  margin: 0 auto;
  overflow-x: hidden;

  #faculty-stage {
    display: flex;
    min-height: 300px;
    padding: 0 20px 20px;

    #stage {
      max-width: 100%;

      .teaser-wrapper {
        margin: 0;
      }
    }
  }

  #news {
    .news-article {
      margin: 20px 0;
      border: 1px solid #ccc;
      border-radius: 5px;
      // background: #f2f2f2;
      overflow: hidden;

      .article-img {
        img {
          width: 100%;
        }
      }

      .article-content {
        padding: 20px;

        .title {
          color: $color1;
          text-decoration: underline;
        }

        .article-text {}
      }
    }
  }

  #intro {}
}

.main-content {
  text-align: justify;
  width: 100%;

  p {
    padding: 0;
    margin-top: 0;
  }

  .sidebar-right & {
    padding-left: 10px;
    // padding-right: 15px;
    background: none;
  }
}

// Footer
#site-footer {
  color: inherit;
  line-height: 1.4;
  padding-bottom: 0;
  margin-top: 0;
  background: none;
  background: $color2;

  a {
    color: #fff;
    text-decoration: underline;
  }

  #before-site-info {
    color: #eee;
    font-size: 1em;
    padding: 30px 0;
    background: $color1;
    position: relative;

    &>div.footer-container {
      max-width: calc(#{$max_width_0} - 50px);
      transform: translateX(-85px);
      transform: translateX(-25px);
    }

    .footer-logo-wrap {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 150px;
      width: 200px;
      margin: auto 0;

      .footer-logo {
        height: 100%;
        padding: 20px 10px 20px 50px;
        ;
        border-top-left-radius: 50% 50%;
        border-bottom-left-radius: 50% 50%;
        background: rgba(white, 1);

        img {
          max-height: 100px;
          max-width: 100%;
        }
      }
    }

    h5 {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  #site-info {
    color: #333;
    // max-width: calc(#{$max_width_0} + 60px);
    padding: 30px 0px 30px;
    margin: 0;
    background-color: white;

    .ime-logo {
      width: 135px;
      background-color: #fff;

      img {
        width: 100%;
      }
    }

    div {
      div {
        float: none;
        width: 100%;
        margin: 0;
      }

      h3 {
        font-size: 2em;
        font-weight: 600;
        text-transform: none;
      }

      ul {
        li {
          padding: 0;
          margin: 0;
          background: none;
        }
      }

      a {
        color: #333;
        font-size: 1.4em;
        text-decoration: none;
        padding-left: 0;
        background: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .footer-menu {
    display: block;
    // float: right;
    list-style-type: none;
    padding: 6px 0;
    margin: 0;

    .menu-item {
      display: inline-block;
      padding: 0 10px 0 0px !important;

      a {
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;

        img {
          height: 34px;
          opacity: 1;
          transform: scale(0.9);

          &:hover {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }
}

// General styles for components
.news1-list {
  // font-size: .9em;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: calc(100% - 50px);
  overflow: hidden;
  // border-top: 1px solid rgba($color2,.3);
  position: relative;

  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15%;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 255));
  }

  li {
    padding: 8px 0;
    border-top: 1px solid rgba($color2, .3);
  }
}

#home-news {
  .main-row {
    .events-box {
      max-width: 100%;
    }

    @media screen and (min-width: 1300px) {
      &>div[class^=col-]:first-child {
        flex-basis: 60%;
        max-width: 60%;

        div[class^=col-]:first-child {
          flex-basis: 66.66%;
          max-width: 66.66%;
        }

        div[class^=col-]:last-child {
          flex-basis: 33.33%;
          max-width: 33.33%;
        }
      }

      &>div[class^=col-]:last-child {
        flex-basis: 40%;
        max-width: 40%;

        div[class^=col-] {
          flex-basis: 50%;
          max-width: 50%;
        }
      }

      .news-slider {
        .slider {
          .slider-content {
            .inner {
              padding: 0 50px;

              .title {
                font-size: 2.8em;
              }

              .article-text {
                font-size: 1.2em;
              }
            }
          }
        }
      }
    }
  }
}

.slick-slider {
  .slick-arrow {
    width: 30px;
    height: 30px;
    z-index: 1;

    &:before {
      color: $color1;
      font-size: 20px;
      opacity: 0;
      transition: all .2s ease-in;
    }
  }

  .slick-prev {
    left: 5px;
  }

  .slick-next {
    right: 5px;
  }

  &:hover {
    .slick-arrow:before {
      opacity: 1;
    }
  }

  .slick-dots {
    left: 0;
    right: 0;

    li {
      margin: 0 2px;

      button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid $color1;
        background: rgba($color1, 0.15);
        overflow: hidden;

        &:before {
          display: none;
        }
      }

      &.slick-active,
      &:hover {
        button {
          background: $color1;
        }
      }
    }
  }
}

.partner-logo {
  @include img-container(64%, contain);
}

.box-img {
  border: 1px solid rgba($color1, .7);
}

.box-img-slider {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid $color2;
  border-radius: 10px;
}

.latest-projects {
  margin: 20px 0;
}

.pdf-preview {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  border: 1px solid rgba($color2, .4);
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  .react-pdf__Page__svg {
    width: auto !important;
    height: auto !important;
  }

  svg, canvas {
    width: 100%!important;
    height: auto!important;
  }

  .preview-btn {
    // color: #fff;
    // font-size: 1.2rem;
    display: block;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    position: absolute;
    z-index: 1;
    top: -9px;
    right: -9px;
    bottom: -9px;
    left: -9px;
    width: max-content;
    height: 2.4em;
    // width: 100%;
    // height: 100%;
    margin: auto;
    opacity: 0;
    transition: all .3s ease-in;

    &:hover {
      opacity: 1;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: -199px;
      right: -199px;
      bottom: -199px;
      left: -199px;
      background-color: rgba(#000, .4);
    }
  }
}

.gallery-row {
  display: flex;

  img {
    max-height: 200px;
  }
}

.uni-logo-box,
.network-box {
  &.events-box {
    width: 100%;
    padding: 0;
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    background-color: #ddd;

    .box-title {
      text-align: center;
      min-height: 3.6em;
      margin: 0px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .events-img {
      @include img-container(70%, contain);
      background-color: white;

      img {
        max-width: 90%;
        max-height: 80%;
      }
    }

    .anchor-style1,
    .events-date {
      display: none;
    }

    &:hover {
      .events-sum {
        top: 0;
      }
    }
  }
}

.network-box.events-box {
  .events-img {
    img {
      max-width: 90%;
      max-height: 40%;
    }
  }
}

.uni-person {
  &.events-box {
    // padding: 10px 0 0px;
    border-color: #ddd;
    background-color: #ddd;

    .box-title {
      text-align: center;
      // margin: 0;
    }

    .events-img {
      @include img-container(240px, cover);
      width: 200px;
      margin: 0 auto;

      img {
        max-width: 120%;
        max-height: 110%;
      }
    }

    .anchor-style1,
    .events-date {
      display: none;
    }
  }
}

.team-img {
  border-radius: 0px;
  border: 1px solid #999;
  overflow: hidden;
}

.organization-img {
  @include img-container(70%, contain);
  border-radius: 10px;
  border: 1px solid #999;
}

.research-img {
  @include img-container(70%, contain);
}

.preise-img {
  @include img-container(70%, contain);
  border-radius: 10px;
  border: 1px solid #999;

  img {
    min-width: 70%;
    max-width: 90%;
    min-height: 90%;
  }
}

.b-pagi {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 20px 0;
  border-radius: .25rem;

  .page-link {
    color: $color1;

    &:focus {
      box-shadow: none;
    }
  }

  .page-item {
    padding: 2px 0;

    &.active {
      .page-link {
        border-color: $color1;
        background-color: $color1;
      }
    }
  }

  .break-me {
    a {
      line-height: 1.25;
      display: block;
      padding: .5rem .75rem;
    }
  }
}

.instagram-wrap {
  margin: 10px 0;
  border: 1px solid #434648;
  border-radius: 5px;

  &>* {
    width: 200px !important;
    min-width: 190px !important;
    height: 300px !important;
    margin: 10px auto !important;
    padding: 0 !important;
    border: 0 !important;
    /* overflow-y: scroll!important; */
  }
}

// Responsiveness
@include media-breakpoint-down(lg) {
  #header {
    .header-container {
      overflow: hidden;
    }
  }

  #branding {

    // width: 100%;
    #branding-inner {
      padding: 10px;
      border-bottom-right-radius: 30px 12px;

      &:after {
        top: 74px;
        border-top-left-radius: 30px 12px;
      }
    }

    .ime-logo-container {
      .ime-logo {
        img {
          height: $header_logo_height - 10px;
        }
      }
    }
  }

  #nav-global {
    padding: 5px 10px;

    .nav-global-main {
      ol.toc.style2 {
        min-width: 350px;

        li {
          font-size: .85em;
          margin: 0 10px;
        }
      }

      ol.toc.style3 {
        min-width: 130px;
        max-width: 140px;

        &:after {
          bottom: -5px;
        }
      }
    }

    #nav-icons {
      .header-right-menu {
        .menu-item {
          a {
            img {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  .home .header-banner {
    .wrapper {
      .image {
        padding-bottom: calc(38vh + 35%);

        img {
          max-width: 200%;
          min-height: 100%;
          max-height: 150%;
        }
      }
    }

    .slider-overlay {
      width: 250px;
      height: 120px;
      max-width: none;
      max-height: none;
      min-width: auto;
      min-height: auto;

      .slider-overlay-wrap {
        padding: 20px 0 20px 20px;
        border-top-left-radius: 85px 50px;

        &::before {
          // display: none;
          top: -25px;
          border-bottom-right-radius: 85px 50px;
          box-shadow: 0 70px 0 0 rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  #site-footer {
    #before-site-info {
      &>div.footer-container {
        width: calc(100% - 100px);
        transform: translateX(-50px);
        padding-left: 20px;
      }
    }
  }

  .slick-slider {
    .slick-arrow {
      &:before {
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #branding {

    // width: 100%;
    #branding-inner {
      padding: 10px;
      border-bottom-right-radius: 30px 12px;

      &:after {
        top: 72px;
        border-top-left-radius: 30px 12px;
      }
    }

    .ime-logo-container {
      .ime-logo {
        img {
          height: $header_logo_height - 30px;
        }
      }
    }
  }

  #nav-global {
    display: none;
  }

  #mobile-nav-global {
    display: flex;
    overflow: hidden;

    .nav-toggle {
      display: block;
    }

    position: absolute;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    left: calc(100% - 50px);
    right: 0;

    .nav-global-main {
      display: none;
      flex-direction: column;
      justify-content: center !important;
      padding-top: 80px;
      background-color: rgba(#fff, 1);
      position: fixed;
      z-index: 9;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      transform: translateX(100%);
      opacity: 0;
      transition: all .3s ease-in;

      ol.toc.style2 {
        padding: 0px 10px 40px;
        min-width: auto;
        flex: 1 0 75%;
        flex-wrap: wrap;
        justify-content: center;
        background-color: #ddd;

        li {
          font-size: 1em;
          margin: .5em 1.2em;

          a {
            text-decoration: underline;
          }
        }
      }

      ol.toc.style3 {
        text-align: center;
        padding: 0px 10px 40px;
        flex: 1 1 25%;
        max-width: none;
        margin: 0 !important;
        background-color: $color1;

        &:after {
          display: none;
        }

        #nav-icons {
          float: none;
          margin: auto !important;
          display: flex;

          .header-right-menu {
            float: none;
            width: 100%;
            display: flex;

            .menu-item {
              padding: 10px;

              a {
                display: inline-block;

                img {
                  height: 30px;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    &.vertical {
      .nav-global-main {
        flex-direction: row;

        ol.toc.style2 {
          flex-direction: column;
        }

        ol.toc.style3 {
          #nav-icons {
            flex-wrap: wrap;

            .header-right-menu {
              flex-direction: column;
            }
          }
        }
      }
    }

    &.active {
      position: fixed;
      left: 0;
      width: 100vw;
      padding: 0;

      .nav-toggle {
        // position: fixed;
        background-color: $color1;

        .fa-bars {
          display: none;
        }

        .fa-times {
          display: inline-block;
        }
      }

      .nav-global-main {
        display: flex;
        transform: translateX(0%);
        opacity: 1;
      }
    }
  }

  .home .header-banner {
    .slider-overlay {
      .slider-overlay-wrap {
        padding: 20px 0px 20px 20px;
        border-top-left-radius: 60px 36px;

        &:before {
          border-bottom-right-radius: 30px 30px;
          box-shadow: 0 80px 0 0 rgba(white, .5);
        }
      }
    }

    // .wrapper {
    //   .image {
    //     padding-bottom: 100%;
    //   }
    // }
  }

  #site-footer {
    #before-site-info {
      &>div.footer-container {}

      .footer-logo-wrap {
        // top: 10px;
        // bottom: auto;
        height: 120px;
        width: 170px;
      }
    }
  }
}